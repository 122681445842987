export default { // 心电向量分析相关

	vector_title: '心电向量分析',
	subtitle: ' |挂号单号： {pid} ',

	unsupportedWebGL: '您的浏览器当前状态不支持WebGL, 请更换浏览器或尝试关闭/启用硬件加速功能后刷新当前页面',

	surface_frontal: '额面(X-Y)',

	surface_transverse_plane: '横面(X-Z)',

	surface_right: '右侧面(Z-Y)'
}
