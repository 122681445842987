export default {
	noise: 'Poor signal',
	unknown: 'Unclassifiable',
	normal: 'Sinus normal',
	A: 'SPC(A)',
	V: 'PVC',
	J: 'PIC',
	VFStart: 'VF Start',
	VFEnd: 'VF End',
	L: 'LBBB',
	R: 'RBBB',
	e: 'Atrial escape',
	E: 'Ventricular escape',
	j: 'Nodal(junctional) premature',
	F: 'Fusion of ventricular and normal beat',
	pacing: 'Paced beat',
	SAR: 'RSA',
	STA: 'IST',
	SBR: 'SSS',
	SP: 'sinus arrest',
	S: 'premature',
	BI: 'AVB',
	BII: 'Ⅱ°AVB',
	TPS: 'pre-excitation syndrome',
	MPS: 'Mahaim type pre-excitation syndrome',
	LGL: 'Normal QRS syndrome'
}
