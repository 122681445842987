export default { // 警报列表相关

	alarmListTitle: '警报消息',
	alarmList: '警报列表',
	alarm_item_content: '{name}{gender}发生{msg}',

	ascending: '按时间升序',
	descending: '按时间降序',

	checkMore: '查看更多',
	readAll: '已读全部',

	voiceSetting: '语音设置',

	alarmSetting: '警报设置',

	male: '先生',
	female: '女士',

	alarmListCount: '警报消息:{counter}条',

	happen: '发生',

	voice_volume: '音量',
	voice_tone: '音调',
	voice_speed: '语速',
	broadCasting_switch: '播报开关',
	small: '小',
	big: '大',
	low: '低',
	high: '高',
	slow: '慢',
	fast: '快',

	level_one: '一级警报',
	level_two: '二级警报',
	level_three: '三级警报',
	level_four: '四级警报',

	notification: '桌面通知',
	floodLight: '全屏泛光',

	alarm_voice_volume: '警报音量',
	alarm_voice_type: '警报声音',

	eventSetting: '异常事件处理',
	level_adjustment: '调整警报级别',

	eventType: '异常事件分类',

	voice: '语音',
	alarmLevel: '警报级别'
}
