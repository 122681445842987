import i18n from '../../index'
export default { // 检测记录相关
	recordTitle: '检测记录',
	pid: '病历号',
	lastMonth: '上个月',
	nextMonth: '下个月',
	today: '今天',
	yesterday: '昨天',

	aWeekAgo: '一周前',

	mon: '一',
	tue: '二',
	wed: '三',
	thur: '四',
	fri: '五',
	sat: '六',
	sun: '日',

	ecgRecordTitle: '心电记录',
	asc: '按时间升序',
	desc: '按时间降序',

	orderNoPlaceholder: '输入挂号单号',

	toReviewer: '浏览全览图',
	analysis: '综合分析',
	checkReport: '预览诊断报告',

	registration: '挂号单',
	startTime: '开始时间',
	entTime: '结束时间',
	duration: '时长',

	monitoringRecord: '监护记录',

	reviewer: '全览图',
	logs: '日志',
	subtitle: p => `| 姓名:${p.named('name')} - 性别:${i18n.t(p.named('gender'))} - 年龄:${p.named('age') || i18n.t('common.secrecy')} - 挂号单: ${p.named('register')}`
}
