export default { // Cluster Monitoring Information

	query: { /** Search Bar */
		// Select Company
		companyTitle: 'Filter by company name', // Search Bar Title Tip
		companyPlaceholder: 'Please fill the company name',

		// Filter Options

		filterTitle: 'Select filter condition',
		filterPlaceholder: 'Please input',

		filterPatientId: 'Patient ID',
		filterName: 'Name',
		filterOrderNo: 'Order No/ Bed No',

		filterKeywordTitle: 'Patient Filter Value',

		reset: 'Reset',

		patientCounts: 'Patient Counts',

		patientsShowTitle: 'Click to show offline patients',
		patientsHiddenTitle: 'Click to hide offline patients',

		showAllPatients: 'Show All',
		showAllCompany: 'All company',

		allCompanyShowTitle: "Click to show all company's patients ",
		onlyCompanyShowTitle: "Click to only show this company's patients ",

		/** Lead */
		lead_single: 'Single Lead',
		lead_seven: '7 Leads',
		lead_eight: '8 Leads',
		lead_twelve: '12 Leads',
		lead_fifteen: '15 Leads',
		lead_eighteen: '18 Leads',
		lead_eighteen_night: '18 Leads(9 electrodes)',
		lead_unknown: 'Unknown',
		register: 'register no'
	},
	patientCard: { /** Patient Card Information */

		orderNo: 'Order No./Bedroom No.',
		name: 'Name',
		gender: 'Gender',
		company: 'Company',
		dept: 'Department',

		offline: 'Equipment Offline',

		msgPanelTitle: 'Physiological and Technical Alarm Information', // Alarm Reminder
		reportTitle: 'Preview Diagnosis Report',
		recordTitle: 'View ECG records',

		hr: 'Heart Rate',
		nibp: 'Blood Pressure',
		bp_s: 'Systolic Blood Pressure',
		bp_d: 'Diastolic Blood Pressure',
		bp_m: 'Average Mean Pressure',
		time: 'Time',

		resp: 'Breath Frequency',
		temp: 'Body Temperature',
		spo: 'Blood Oxygen Saturation',

		bs: 'Blood Sugar',

		male: 'Male',
		female: 'Female',
		secrecy: 'Secrecy',

		reportError: 'Preview Diagnosis Report Failed',

		realEcg: 'Dynamic ECG',

		happen: 'Happened',

		checkMore: 'Click to View',

		/** Cluster Monitoring Holter Electrocardiogram Information */

		incrTitle: 'ECG Gain',
		incrShortcutTitle: 'CTRL+Mouse Scroll Wheel to Adjust the Gain',

		currentTimeTitle: 'Current ECG Data Time',
		/** Equipment */
		wifi: 'Wifi Signal',
		blueTooth: 'BlueTooth Signal',
		mobilePower: 'Mobile Phone Power',
		equipmentPower: 'Equipment Power',
		offlineTip: 'Double click it to private ward page'

	}

}
