export default {
	// 心率变异性分析相关

	time_start: '开始时间',
	time_end: '结束时间',
	time_range: '检测时长',
	time_stage: '时段设置',
	time_to: '至',
	// timeDomain

	timeDomain_averageRR: '平均间期',
	timeDomain_averageHr: '平均心率',
	timeDomain_minHr: '最小心率',
	timeDomain_maxHr: '最大心率',

	timeDomain_tri_index: 'RR三角指数',

	nnTrend_chartTitle: 'NN间期趋势图',

	nnHis_chartTitle: 'NN分布直方图',
	nnHis_yTitle: '心拍数量',

	dfa_chartTitle: '趋势涨落分析',

	freq_xTitle: '频率',
	freq_chartTitle: 'NN频谱图',

	scatter_chartTitle: '单象限NN间期散点图',

	scatter_difference_chartTitle: 'NN间期差值散点图',

	/** timeSetting */
	selectPlaceholder: '请选择',
	time_stage_list: '时段列表',
	time_content: '内容',

	/** freq-domain-table 频域分析*/

	freq_table_title: '频域分析结果',

	freq_table_label_param: '参数',

	freq_table_label_unit: '单位',

	freq_peak: '峰值频率',
	freq_power: '功率',
	freq_totalPower: '总功率',

	/** nonlinear-table 非线性分析 */

	nonlinear_table_title: '非线性分析结果',

	nonlinear_table_lorenz: '洛伦兹散点图',

	nonlinear_table_ap_entropy: '近似熵',

	nonlinear_table_samp_entropy: '样本熵',

	nonlinear_table_dfa: '趋势涨落分析',

	nonlinear_table_short_difference: '短期差异性',
	nonlinear_table_long_difference: '长期差异性',
	nonlinear_table_relative: '关联程度',

	/**
     * time-domain-table 时域分析结果
     */

	time_table_title: '时域分析结果',

	time_setting_title: '时间设置',
	time_setting_subtitle: p => ` |病历号: ${p.named('pid')} `,
	sleep_table_title: '睡眠呼吸暂停综合征分析结果',
	sleep_table_day: '白天',
	sleep_table_night: '夜晚',
	sleep_table_time: '时域指标',
	sleep_table_freq: '频域指标',
	sleep_sdnn_index: 'SDNN指数',
	sleep_table_threshold: '阈值',
	sleep_table_danger: '危险',
	sleep_table_other: '其他参数',
	sleep_table_variation: '睡眠心率变化大于15bpm'
}
