export default { // 血压相关
	nibpReport: '血压诊断报告',
	subtitle: '| 姓名: {name} - 性别: {gender} - 年龄: {age} | 病历号: {pid}',

	name: '姓名',
	gender: '性别',
	age: '年龄',
	department: '机构科室',

	weekAgo: '一周前',
	today: '今天',
	yesterday: '昨天',
	time: '时间',

	setting: '设置',
	createReport: '生成报告',
	viewReport: '预览报告',
	apply: '应用',

	trend: '趋势图',
	rhythm: '昼夜节律图',
	pie: '时段内血压状态饼状统计图',
	bar: '柱状统计图',
	plot: '拟合图',

	hour: '小时',
	pulse: '脉搏',
	normal: '正常',
	abnormal: '不正常',

	// sbp: '收缩压',
	// mbp: '舒张压',
	// hrText: '心率',

	conclusion: '结论',
	saveConclusion: '保存结论',

	allDay: '全天',
	day: '白天',
	night: '夜间',
	morning: '早晨',

	sbp: '收缩压',
	mbp: '舒张压',

	percent: '百分比',
	rely: '依赖关系',
	connectIndex: '相关系数',
	distribute: '分布中心',
	hr: '心率',

	summaryPeriod: 'ABPM总结 | 周期',
	TimeRange: '时间范围',
	sbpUpperLimit: 'SBP/DBP上限',
	sbpWA: 'SBP/DBP加权平均',
	pulseWA: '脉搏加权平均',
	sbpMbpDayNight: '收缩压/舒张压 白天/夜间 指数%',
	successRate: '成功率',
	morningSurge: '早晨 surge',

	gross: '总量',
	tableMor: '早晨',
	tableDay: '白天',
	tableNight: '夜间',

	conclusionPlaceholder: '点击输入结论',

	morningRange: '早晨时间范围',
	nightRange: '夜间时间范围',
	sbpNormalRange: 'SBP正常范围值',
	dbpNormalRange: 'DBP正常范围值',
	hrNormalRange: 'HR正常范围值',

	setHours: '24小时',
	setDay: '白天',
	setNight: '夜间',

	morningStartTime: '早晨开始时间',
	morningEndTime: '早晨结束时间',
	nightStartTime: '夜间开始时间',
	nightEndTime: '夜间结束时间',

	sure: '确定',
	cancel: '取消',
	conclusion_normal: '血压监测时间为{begin} - {end}，检测总时长为{timeLong}小时，平均血压为{avgAllSbp}/{avgAllDbp}。',
	conclusion_day: '白天检测时段为{dayTimeSlot}，检测时长为{dayLong}小时，白天平均血压为{wmsaDaySbp}/{wmsaDayDbp}。',
	conclusion_night: '夜间时段为{nightTimeSlot}，时长为{nightLong}小时，夜间平均血压为{wmsaNightSbp}/{wmsaNightDbp}。'

}
