export default {
	selectByMouse: 'select by drawing',
	detail: 'Larger view',
	normal: 'Normal view',
	zoomIn: 'Zoom in',
	zoomOut: 'Zoom out',
	reset: 'Reset',
	filter: 'Filter',
	dragOn: 'Drag on',
	dragOff: 'Drag off',
	switchLeads: 'Lead switch',
	gain: 'Gain',
	viewAllHeartBeats: 'View all heart beats',
	selectAll: 'Select all',
	dblClickViewDetail: 'Double click to view detail',
	time: 'Time',
	total: 'Total',
	detailTitle: 'Time： {start} - {end} | total: {total}H'
}
