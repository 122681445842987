export default { // 详情图相关
	detailTitle: 'ECG Review Detail',
	subTitle: 'patient id：{pid} - time: {start} - {end} | total: {total} hours',
	changeTimeTip: '输入时间不得小于心电图开始时间或大于心电图结束时间 The input time must be not less than the start time or greater than end time',

	logsError: 'The log have not yet been entered',

	idError: 'onlyId or pid not exist',

	samplePrompt: 'Please enter the time stamp {showTime} corresponding to the ECG sample comments',
	tip: 'Tip',

	sampleExistInfo: 'This diagnostic sample already exists',

	sample_add_succeed: 'Added!',
	sample_add_cancel: 'Cancel',

	diag_code: 'Codes',
	diag_result: 'Results',
	diag_time: 'Time',

	time_gt_error: 'The request time is greater than the end time of the event',
	time_lt_error: 'The request time is less than the end time of the event',

	exceedTimeRange: 'Exceeding the ECG diagnostic time frame',
	click_frequently: 'Do not click it frequently',
	unAnalyzed_Title: '不分析片段管理',
	unAnalyzed_subTitle: '挂号单号：{register}'
}

