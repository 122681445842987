<script>
export default {
	name: 'Teleport',
	props: {
		/* 移动至哪个标签内，最好使用id */
		to: {
			type: HTMLElement || String,
			required: true
		}
	},
	data() {
		return {
			p: null
		}
	},
	mounted() {
		if (typeof this.to === 'string') {
			this.p = document.querySelector(this.to)
			document.querySelector(this.to)
		} else {
			this.p = this.to
		}
		if (!this.p) throw Error('Teleport: to is not a valid element')
		this.p.appendChild(this.$el)
	},
	beforeDestroy() {
		let p = this.p
		if (!p) {
			p = document.querySelector(this.to)
		}
		p && p.contains(this.$el) && p.removeChild(this.$el)
	},

	render() {
		return <div>{this.$scopedSlots.default()}</div>
	}
}
</script>
