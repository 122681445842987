export default {
	batch: 'Batch ward',
	single: 'Single ward',
	logout: 'Sign Out',
	fullScreen: 'full screen',

	logsViewLabel: 'View Logs', // 日志浏览

	logsViewTitle: 'view events and logs of all patients ', // 日志浏览title解释
	measure: 'Measure',
	measureLabel: 'Manual Measure', // 手动测量
	measureTitle: 'Manual Measure',

	reportLabel: 'Preview report', // 预览报告
	reportTitle: 'Preview Report', // 预览报告title解释

	analysisLabel: 'ECG Analysis', // ECG分析
	analysisTitle: 'ECG Analysis',

	recordsLabel: 'ECG Review',
	recordsTitle: 'ECG Review',

	reportListLabel: 'Reports History',
	reportListTitle: 'Reports History',

	remaining: 'Remaining: ',
	remainingTime: 'Remaining time of this login',
	remainingToday: 'Remaining login of today',
	cloud: 'Cloud',
	registPlz: 'Please complete registration to remove the limitations',
	reg: 'Register'
}
