export default {
	lang: 'language',
	ward: 'Wards',
	batch: 'Public Ward',
	single: 'Private Ward',
	yasun: 'Yasun Technologies',
	login: 'Yasun Cloud Health - Login',
	register: 'YASUN - Registration',
	reset: '岩尚科技-重置密码',
	selectLang: '切换语言'
}
