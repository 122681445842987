export default { // 异常事件相关

	N: 'Normal',
	A: 'Atrial premature beat',
	V: 'Premature ventricular contraction',
	J: 'Nodal premature beat',
	L: 'Left bundle branch block beat',
	R: 'Right bundle branch block beat',
	e: 'Atrial escape beat',
	E: 'Ventricular escape beat',
	j: 'Nodal escape beat',
	F: 'Fusion of paced and normal beat',
	'/': 'Paced beat',
	'&': 'Unclassifiable beat',
	'?': 'Poor signal',
	SAR: 'RSA',
	STA: 'IST',
	SBR: 'SSS',
	SP: 'sinus arrest',
	S: 'premature',
	BI: 'AVB',
	BII: 'Ⅱ°AVB',
	TPS: 'pre-excitation syndrome',
	MPS: 'Mahaim type pre-excitation syndrome',
	LGL: 'Normal QRS syndrome',

	'1': 'Lead Off',
	'2': 'TF Card Error',
	'3': 'Equip Connected',
	'4': 'Proactive disconnection',
	'5': 'Out of connection range',
	'6': 'shutdown',
	'7': 'Automatic low battery of Equip',
	'8': 'Automatic low battery of Mobile',
	AFIB: 'Atrial fibrillation',
	AFL: 'atrial flutter',
	VFL: 'ventricular fibrillation',
	X: 'Deleted',
	// Pause:"停搏",
	// MYI:"心肌缺血",
	// MII:"心肌梗死",
	// /**
	//  * 二级警报
	//  */
	// E:"室性逸博",
	// L:"左束支阻滞",
	// R:"右束支阻滞",
	// A:"室上性早搏",
	// V:"室性早搏",
	// J:"交界性早搏鉴",
	VT: 'Supraventricular tachycardia',
	// VER:"室性逸搏心律",
	// JER:"交界性逸搏心律",
	VD: 'Ventricular couplet',
	B: 'Double of Ventricular Premature Beats',
	T: 'Triple of Ventricular Premature Beats',
	VQU: 'Qu law of Ventricular Premature Beats',
	JB: 'Junctional Bigeminy',
	JT: 'Junctional Trigeminy',
	VS: 'Paroxysmal ventricular',
	// Jqu:"四联律交界性早搏", Junctional Quadrigeminy
	// S:"室上性早搏", Supraventricular premature beat
	// SP:"窦性停搏", Sinus arrest
	// /**
	//  * 三级警报
	//  */
	// j:"交界性逸博", Junctional escape beats
	// e:"房性逸博", Atrial escape
	// SBR:"窦性心律过缓",Sinus bradycardia
	// STA:"窦性心动过速",Sinus tachycardia
	// SAR:"窦性心律不齐",Sinus arrhythmia
	SVTA: 'Supraventricular tachycardia',
	AS: 'paroxysmal auricular tachycardia',
	AD: 'Ventricular couplet',
	AB: 'Atrial Bigeminy',
	AT: 'Atrial Trigeminy',
	AQu: 'Atrial Quadrigeminy',
	IVR: 'Idioventricular rhythm',
	NOD: 'Nodal (A-V junctional) rhythm',

	// /**
	//  * 四级警报
	//  */
	// normol:"正常",
	// 脱落相关
	leadOff: ' lead off',
	MYI: 'Myocardial ischemia',
	MII: 'Myocardial infarction',
	VH: 'Ventricular Hypertrophy',
	AH: 'Atrial hypertrophy',
	SAS: 'Sleep apnea syndrome',

	// 设备日志相关

	D_0: '设备开机',
	D_1: '设备自动关机',
	D_2: '设备主动关机',
	D_3_0: '设备TF卡异常',
	D_3_1: '设备TF卡正常',
	D_4: '设备导联脱落',
	D_5_1: 'TF卡异常',
	D_5_2: '创建当前文件失败',
	D_5_3: '打开历史文件失败',
	D_5_4: '关闭当前文件失败',
	D_5_5: '关闭历史文件失败',
	D_5_6: '创建心电文件头时移动文件指针失败',
	D_5_7: '保存心电数据时移动文件指针失败',
	D_5_8: '补发当前文件时移动文件指针失败',
	D_5_9: '补发历史文件时移动文件指针失败',
	D_5_10: '补发当前文件时读心电数据文件失败',
	D_5_11: '补发历史文件时读心电数据文件失败',
	D_5_12: '创建心电文件头时写文件失败',
	D_5_13: '写入当前心电数据时写文件失败',
	D_5_14: '创建心电文件头时同步文件失败',
	D_5_15: '补发当前文件时同步文件失败',
	D_5_16: '文件写入太慢',
	D_5_17: '文件已关闭',
	D_5_18: '读文件列表失败',
	D_5_30: '补发数据位置超出文件长度',
	D_5_31: '分配内存失败或内存溢出',
	D_5_32: '操作系统内核访问出错',
	D_5_33: '向队列放入准备写TF卡的数据时失败（可能由于队列满或其它原因）',
	D_5_34: '从队列提取准备写TF卡的数据时失败',
	D_5_40: '应用程序逻辑错误',
	D_12: '设备低电量自动关机',
	D_13: '设备剩余电量',
	B_6: '蓝牙已链接',
	B_7: '蓝牙自动断开',
	B_8: '蓝牙主动断开',
	A_9: 'App低电量停止监护',
	A_10: '紧急求救',
	A_11_0: '当前记录开始补发段落',
	A_11_1: '当前记录补发段落已完成',
	A_11_2: '当前记录补发丢包数据',
	A_11_3: '手动补发数据',
	A_11_4: '补发中断'

}
