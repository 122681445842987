import i18n from '../../index'
export default { // 诊断报告相关
	pdfTitle: '诊断报告',
	historyTitle: '历史诊断报告列表',
	analysisTitle: '心电综合分析',
	checkItemTitle: '检测项目选择',
	subtitle: p => `| 姓名：${p.named('name')} - 性别：${i18n.t(p.named('gender'))} - 年龄：${p.named('age') || i18n.t('common.secrecy')} - 挂号单：${p.named('register')}`,

	// 历史诊断报告列表相关

	beginDate: '开始日期',
	endDate: '结束日期',
	to: '至',
	openReportTitle: '双击查看诊断报告',

	table_order: '序号',
	table_time_create: '创建时间',
	table_time_begin: '开始时间',
	table_time_end: '结束时间',
	table_time_span: '时长',

	lastWeek: '最近一周',
	lastMonth: '最近一个月',
	lastThreeMonths: '最近三个月',

	time_span_dhms: '{d}天{h}小时{m}分{s}秒',
	time_span_hms: '{h}小时{m}分{s}秒',
	time_span_ms: '{m}分{s}秒',
	time_span_s: '{s}秒'
}
