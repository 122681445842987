export default { // 心电全览图相关
	title: (p) => `姓名：${p.named('name')} - 性别：${p.named('gender')} - 年龄：${p.named('age') || '保密'} | 挂号单：${p.named('register')}`,
	ecgReviewer: '心电全览图',
	now: '当前',
	time: '时间',
	total: '总计',

	viewReport: '预览诊断报告',

	viewTemplate: '查看心电模板',

	viewHrv: '查看心率变异性分析',
	viewLorenz: '查看洛伦兹分析',
	viewFreq: '查看频域/谱分析',

	viewQt: '查看QT分析',

	viewSt: '查看ST分析',

	viewRemix: '查看反混淆分析',

	autoLoading: '自动加载',

	syncTitle: '显示正在手动同步的记录',

	jumpTimePlaceholder: '跳转时间',

	page_main: '首页',
	page_last: '上一页',
	page_next: '下一页',
	page_end: '末页'

}
