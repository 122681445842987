import { getPatientsList } from '@/api/ward'
import i18n from '@/i18n/index'
import Vue from 'vue'

const isOnline = item => (item.online ? item.index - 1000000 : item.index)
const EquipeTypes = {
	1: '单导联设备',
	7: '七导联设备',
	8: '八导联设备',
	9: '十八导联(9电极)设备',
	12: '十二导联设备',
	15: '十五导联设备',
	18: '十八导联设备'
}

const wordsMap = {
	number: 'index',
	name: 'name',
	companyName: 'company',
	deptName: 'dept',
	patientId: 'pid',
	deptNameEn: 'deptEn',
	companyNameEn: 'companyEn',
	onlyId: 'rid',
	age: 'age',
	gender: 'gender',
	equipStatus: 'online',
	equipModel: 'equipModel',
	register: 'register',
	orderProd: 'orderProd',
	wifi: 'wifi',
	mac: 'mac',
	bleSignal: 'ble',
	equipPower: 'ePower',
	phonePower: 'mPower',
	leadCount: 'module',
	tfStatus: 'tfStatus',
	tfSpace: 'tfSpace',
	leadOff: 'leadOff',
	hr: 'hr',
	resp: 'resp',
	temp: 'temp',
	sp: 'sp',
	sbp: 'sbp',
	dbp: 'dbp',
	bs: 'bs',
	msg: 'msg',
	lv: 'lv',
	time: 'time',
	st: 'st',
	bedNumber: 'bed'
}

const notNumber = new Set([
	'name',
	'company',
	'dept',
	'mac',
	'msg',
	'pid',
	'register',
	'orderProd',
	'equipModel',
	'bed'
])

function msgHandler(commit, msg) {
	try {
		const data = JSON.parse(msg)
		if (!data) {
			throw new Error(JSON.stringify(data))
		}
		commit('UPDATE_PATIENT_INFO', data)
	} catch (e) {
		console.log(e)
	}
}

// 取指定位数的数字, 不足的使用0补齐
function fixedDigit(val, digit = 8) {
	const target = '000000'
	const source = String(val)
	const length = source.length
	return (
		target.substring(0, length > digit ? 0 : digit - length) +
		source.substring(digit > length ? 0 : length - digit, length)
	)
}

function onlineStatus(patient) {
	if (patient.online < 0) {
		Vue.set(patient, 'message', {
			error: 1,
			lv: 1,
			text: i18n.t('common.onlineErrorText'),
			time: patient.time || new Date().getTime()
		})
	} else if (patient.message.error) {
		delete patient.message.error
		delete patient.message.time
		Vue.set(patient, 'message', {
			lv: 4,
			text: ''
		})
	}
}

function alarmHandle(patient, code = '') {
	const { message, lv, time } = patient
	if (message) {
		const prev_lv = patient.lv
		if (prev_lv >= lv) {
			if (code) {
				Vue.set(patient, 'message', {
					lv,
					text: code,
					time
				})
				clearTimeout(patient.msgShowing)
			}
		}
		patient.msgShowing = setTimeout(() => {
			Vue.set(patient, 'message', {
				lv: 4,
				text: ''
			})
		}, 2000 + (5 - Number(lv)) * 2500)
	}
}

const ecg = {
	state: () => ({
		dataSet: new Set(),
		patients: {},
		manualSyncLog: [],
		viewAll: (
			JSON.parse(sessionStorage.getItem('viewAll')) || { viewAll: false }
		).viewAll,
		viewAllCompany: (
			JSON.parse(sessionStorage.getItem('viewAllCompany')) || { viewAllCompany: true }
		).viewAllCompany,
		ws: undefined,
		onlineCount: 0,
		allPatientCount: 0,
		gain: 10,
		speed: 25,
		hr: {},
		hrParam: {},
		beginTime: [],
		throttling: {},
		equipCount: {},
		diagStyles: {
			CHF: '#FF64',
			AFIB: '#f552',
			VF: '#F844',
			ABi: '#4884',
			ATr: '#55F4',
			AB: '#F5F4',
			MI: '#46F4',
			PAUSE: '#93F4',
			AFL: '#5484',
			SBR: '#99F4',
			SVTA: '#A4A4',
			VT: '#8DD4'
		},
		wardingPanel: {}
	}),
	mutations: {
		GET_PATIENTS_LIST(state, data) {
			// 获取患者列表
			for (let i = 0, length = data.length; i < length; i++) {
				const item = data[i]
				const { patientId } = item
				if (patientId) {
					const infos = {}
					const keys = Object.keys(item)
					for (let i = 0, length = keys.length; i < length; i++) {
						const key = wordsMap[keys[i]]
						if (key) {
							infos[key] = notNumber.has(key)
								? item[keys[i]]
								: Number(item[keys[i]])
						}
					}
					infos.orderNo = infos.bed || fixedDigit(patientId)
					infos.message = {
						lv: 4,
						text: ''
					}
					// 临时代码！！
					alarmHandle(infos)
					onlineStatus(infos)
					Vue.set(state.patients, patientId, infos)
				}
			}
			sessionStorage.setItem('patients', JSON.stringify(state.patients))
		},
		UPDATE_PATIENT_INFO(state, data) {
			// 更新患者信息

			const { patients } = state
			if (!data || !data.patientId) return
			const { patientId } = data
			if (!Object.hasOwnProperty.call(patients, patientId)) {
				Vue.set(patients, patientId, {})
			}
			const patient = patients[patientId]
			const keys = Object.keys(data)
			for (let i = 0, length = keys.length; i < length; i++) {
				const key = wordsMap[keys[i]]
				if (key) {
					Vue.set(
						patient,
						key,
						notNumber.has(key) ? data[keys[i]] : Number(data[keys[i]])
					)
				}
			}
			Vue.set(patient, 'orderNo', patient.bed || fixedDigit(patientId))

			if (data.code) alarmHandle(patient, data.code)
			onlineStatus(patient)
		},
		TOGGLE_VIEWING(state) {
			state.viewAll = !state.viewAll
			sessionStorage.setItem(
				'viewAll',
				JSON.stringify({ viewAll: state.viewAll })
			)
		},
		TOGGLE_VIEWING_ALL_COMPANY(state) {
			state.viewAllCompany = !state.viewAllCompany
			sessionStorage.setItem(
				'viewAllCompany',
				JSON.stringify({ viewAllCompany: state.viewAllCompany })
			)
		},
		CHANGE_GAIN(state, data) {
			state.gain = data
		},
		CHANGE_SPEED(state, data) {
			state.speed = data
		},
		CHANGE_hrParam(state, data) {
			state.hrParam = data
		},
		CHANGE_beginTime(state, data) {
			state.beginTime = data
		},
		CLOSE_WEBSOCKET(state) {
			if (state.ws) state.ws.close()
		},
		SET_HR_RESP(state, { pid, hr, resp }) {
			if (!pid) return
			Vue.set(state.hr, pid, { hr, resp })
			// Vue.set(state.hr, pid, { hr })
		}
	},
	actions: {
		InitSocket({ state, getters, commit }) {
			// 初始化websocket连接
			if (state.ws) {
				state.ws.close()
				delete state.ws
			}
			const url = `${process.env.VUE_APP_WS_URL}?token=${getters.token}&wsType=1&lang=${i18n.locale}`
			const createWS = () => {
				const ws = (state.ws = new WebSocket(url))
				ws.onopen = () => {
					ws.binaryType = 'arraybuffer'
				}
				ws.addEventListener('message', e => {
					msgHandler(commit, e.data)
				})
				ws.onclose = () => {
					setTimeout(() => {
						createWS()
					}, 5000)
				}
			}
			createWS()
		},
		SetHR({ commit }, params) {
			commit('SET_HR_RESP', params)
		},
		async GetPatientList({ commit, dispatch }) {
			// 获取患者列表
			const { data } = await getPatientsList()
			commit('GET_PATIENTS_LIST', data)
			dispatch('InitSocket')
		},
		SetPatients({ state }, list) {
			// 应用患者列表
			state.patients = list
		},
		ToggleViewingState({ commit }) {
			// 切换集群列表是否显示离线患者
			commit('TOGGLE_VIEWING')
		},
		ToggleViewingAllCompanyState({ commit }) {
			// 切换集群列表是否显示全部机构
			commit('TOGGLE_VIEWING_ALL_COMPANY')
		},
		AddMessageListener({ state }, cb) {
			// 添加监听websocket消息的方法
			const temp = () => {
				setTimeout(() => {
					if (state.ws) state.ws.addEventListener('message', cb)
					else {
						temp()
					}
				}, 300)
			}
			temp()
		},
		RemoveMessageListener({ state }, cb) {
			state.ws.removeEventListener('message', cb) // 移除监听websocket消息的方法
		},
		CloseWebSocket({ commit }) {
			commit('CLOSE_WEBSOCKET')
		}
	},
	getters: {
		isECGSyncing: state => syncId => {
			return state.dataSet.has(syncId)
		},
		getWsConn: state => state.ws,
		getMobileData: state => (syncId, value) => {
			state.manualSyncLog.push(syncId)
			state.dataSet.add(syncId)
			state.ws.send(JSON.stringify(value))
		},
		allPatients: state => {
			return Object.values(state.patients).sort(
				(a, b) => isOnline(a) - isOnline(b)
			)
		},
		equipTypeCount: (state, getters) => {
			const list = getters.allPatients
			const length = list.length
			const result = {
				单导联设备: [0, 0],
				七导联设备: [0, 0],
				八导联设备: [0, 0],
				十二导联设备: [0, 0],
				十五导联设备: [0, 0],
				十八导联设备: [0, 0],
				'十八导联(9电极)设备': [0, 0],
				未知设备: [0, 0]
			}
			for (let i = 0; i < length; i++) {
				const patient = list[i]
				const key = EquipeTypes[patient.module] || '未知设备'
				result[key][0]++
				if (patient.online) result[key][1]++
			}
			return result
		},
		onlinePatients: (state, getters) => {
			const patients = getters.allPatients
			return patients.filter(item => item.online)
		},
		currentCompanys: (state, getters) => {
			const result = new Set()
			const list = getters.allPatients
			for (let i = 0, length = list.length; i < length; i++) {
				result.add(list[i].company)
			}
			return Array.from(result)
		},
		patientList: (state, getters) => {
			const patients = getters.allPatients
			state.allPatientCount = patients.length
			const onlineList = getters.onlinePatients
			state.onlineCount = onlineList.length
			return state.viewAll ? patients : onlineList
		},
		getPatientInfos: state => pid =>
			(state.patients ||
				JSON.parse(sessionStorage.getItem('patients') || '{}'))[pid],
		allPatientsCount: state => state.allPatientCount,
		onlineCount: state => state.onlineCount
	}
}

export default ecg
