<template>
	<div class="function-bar">
		<ul>
			<li
				v-for="(item, i) in list"
				:key="'func_' + i"
				:title="$t(item.title)"
				:disable="item.disable"
				@click="item.action"
				@mouseover="show = i"
				@mouseleave="show = -1"
			>
				<i
					v-if="item.class"
					:class="item.class"
				/>
				<span
					v-if="item.label"
					v-t="item.label"
				/>
				<i
					v-if="item.contents"
					class="el-icon-arrow-down iconStyle"
				/>
				<transition name="fade">
					<div
						v-if="item.contents && show === i"
						class="dropdown"
						@mouseover="show = i"
						@mouseleave="show = -1"
					>
						<div
							v-for="(item1, index) in item.contents"
							:key="'child_func_' + index"
							:title="item1.title"
							:disable="item1.disable"
							@click="item1.action"
						>
							<i
								v-if="item1.class"
								:class="item1.class"
							/>
							<span v-if="item1.label">{{ item1.label }}</span>
						</div>
					</div>
				</transition>
			</li>
			<li class="language-switch">
				<i class="iconfont lang-icon icon-language" />
				<select
					v-model="lang"
					class="lang lang-sel"
					:title="$t('router.selectLang')"
					:placeholder="$t('router.selectLang')"
					@change="switchLang"
				>
					<option
						v-for="item in langs"
						:key="item"
						:label="getLangLabel(item)"
						:value="item"
					/>
				</select>
			</li>
			<li
				class="full-screen"
				:title="$t('navigator.fullScreen')"
				@click="fullScreen"
			>
				<i class="el-icon-full-screen" />
			</li>
		</ul>
	</div>
</template>

<script>
import Languages from '@/i18n/languages'
export default {
	name: 'FunctionalBar',
	data() {
		return {
			show: -1,
			lang: 'zh_CN'
		}
	},
	computed: {
		list() {
			return this.$store.getters.navBarBtns.map((item) =>
				Object.assign(
					{
						action: () => {
							console.log('click')
						}
					},
					item
				)
			)
		},
		langs() {
			return this.$i18n.availableLocales || []
		}
	},
	mounted() {
		this.lang = this.$store.getters.lang
	},
	methods: {
		getLangLabel(lang) {
			return Languages[lang]
		},
		fullScreen() {
			const el = document.documentElement
			const isFullScreen =
				document.fullScreen ||
				document.mozFullScreen ||
				document.webkitIsFullScreen
			if (!isFullScreen) {
				// 进入全屏,多重短路表达式
				(el.requestFullscreen && el.requestFullscreen()) ||
					(el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
					(el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
					(el.msRequestFullscreen && el.msRequestFullscreen())
			} else {
				// 退出全屏,三目运算符
				document.exitFullscreen
					? document.exitFullscreen()
					: document.mozCancelFullScreen
						? document.mozCancelFullScreen()
						: document.webkitExitFullscreen
							? document.webkitExitFullscreen()
							: ''
			}
		},
		switchLang() {
			this.$store.dispatch('setLanguage', this.lang)
			this._i18n.locale = this.lang
		}
	}
}
</script>

<style lang='scss' scoped>
.yasun-ecg-client
	.main-navigation
	.nav-bar
	.function-bar
	> ul
	> li.language-switch::before {
	content: '';
	height: 22px;
	padding-right: 10px;
	margin-left: 11px;
	border-left: 2px solid $border-2;
}

.lang {
	cursor: pointer;
}
.iconfont.icon-language {
	font-size: 20px;
}
.lang-sel {
	width: 120px;
	height: 26px;

	option {
		min-height: 1.5em;
		padding: 10px 0;
	}
}

.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-leave-to {
	opacity: 0;
}

.iconStyle {
	padding-left: 3px;
}
.dropdown {
	position: absolute;
	top: 35px;
	border: 1px solid rgb(197, 197, 197);
	background-color: #000;
	border-radius: 3px;
	color: #fff;
	div {
		padding: 10px;
	}
	div:hover {
		color: #f8d820;
		background-color: #fff4;
	}
}
.dropdown::before,
.dropdown::after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	pointer-events: none;
}
.dropdown::before {
	border-bottom-color: #fff;
	border-width: 6.5px;
	left: 9%;
	bottom: 100%;
}
.dropdown::after {
	border-bottom-color: #000;
	border-width: 5px;
	left: 10%;
	bottom: 100%;
}
</style>
