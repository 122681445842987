export default { // 检测项目选择相关

	selector_title: '检测项目选择',

	input_order_placeholder: '请输入项目编号',
	input_name_placeholder: '请输入项目名称',

	totalCost: '项目收费总额',
	moneyLabel: '￥',

	sign_scan_tip: '请使用手机扫描二维码完成签名',
	sign_confirm_tip: '请确定该签名为本人签名后再点击确认按钮',
	sign_resign: '重新签名',

	table_orderNo: '项目编号',
	table_item: '检测项目',
	table_item_description: '检测项目说明',
	table_company_level: '机构级别',
	table_price: '单价',
	table_amount: '数量',
	table_unit: '单位',
	table_cost_source: '收费价目出处',
	table_total_cost: '金额'
}
