import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import Qs from 'qs'

// 创建axios实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // api的base_url
	timeout: 60 * 1000 // 请求超时时间
})

function getTimezoneOffset() {
	var now = new Date()
	var offset = now.getTimezoneOffset()
	var hour = Math.floor(Math.abs(offset / 60))
	var minute = Math.abs(offset % 60)
	return (offset < 0 ? '-' : '+') + hour + ':' + minute
}

// request拦截器
service.interceptors.request.use(
	config => {
		config.headers['lang'] = store.getters.lang || 'zh-CN'
		config.headers['tz'] = getTimezoneOffset()
		if (store.getters.token) {
			config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
		}
		if (config.method === 'get') {
			// 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2,不转换会显示为arr[]=1&arr[]=2
			config.paramsSerializer = function(params) {
				return Qs.stringify(params, { arrayFormat: 'repeat' })
			}
		}
		return config
	},
	error => {
		Promise.reject(error)
	}
)

// response拦截器
service.interceptors.response.use(
	response => {
		const res = response.data
		const headers = response.headers
		if (headers['content-type'].match('application/json')) {
			const code = parseInt(res.code)
			if (code !== 200) {
				// 508:非法的token; 512:其他客户端登录了;  102:Token 过期了;
				if (code === 401 || code === 508 || code === 512 || code === 105) {
					MessageBox(
						{
							message: '你已被登出，可以取消继续留在该页面，或者重新登录',
							title: '登录过期',
							confirmButtonText: '重新登录',
							cancelButtonText: '取消',
							closeOnClickModal: false,
							closeOnPressEscape: false,
							type: 'warning'
						}
					).then(() => {
						store.dispatch('FedLogOut').then(() => {
							location.reload() // 为了重新实例化vue-router对象 避免bug
						})
					})
				} else if (code === 350) {
					Message({
						showClose: true,
						message: res.msg,
						type: 'warning',
						duration: 5 * 1000
					})
				} else {
					Message({
						showClose: true,
						message: res.msg,
						type: 'error',
						duration: 5000
					})
				}
				return Promise.reject({ msg: 'code error', response })
			}
			return response.data
		} else {
			return response
		}
	},
	error => {
		const code = error.response.status
		if (code === 429) {
			Message({
				showClose: true,
				message: '您的操作太快了, 请稍后再试',
				type: 'error',
				duration: 3000
			})
		} else {
			Message({
				showClose: true,
				message: error.message,
				type: 'error',
				duration: 5 * 1000
			})
		}
		return Promise.reject(error)
	}
)

export default service
