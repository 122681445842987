
export default { // 特征点定义相关

	ecgDefinitionTitle: '心电定义全览图',
	retreat: '返回',

	definitionTitle: '定义图',

	/** definition mapper */

	key_averageRR: '平均RR间期',
	text_averageRR: '{divFront}平均RR间期：指所有QRS波群被检测后，通过检测到最早到最晚心搏之间的时间间距除以心搏数来计算。公式：平均RR间期=(最早到最晚QRS的时间间距)/(QRS个数 - 1)，单位：ms。</div>',

	key_Qrs: 'QRS间期',
	text_Qrs: '{divFront}QRS间期: 指{spanFront}QRS波群起点{spanRear}到终点（{spanFront}QRS复合波终点{spanRear}）之间的时间，单位：ms。</div>',

	key_Qt: 'QT间期',
	text_QT: '{divFront}QT间期：指{spanFront}QRS波群起点{spanRear}到{spanFront}Te{spanRear}(T复合波终点)之间的时间，单位：ms。</div>',

	key_Qrs_group_start: 'QRS波群起点',
	text_Qrs_group_start: '{divFront}QRS波群起点: 指QRS复合波的起点，用该点与中位值波形模板原点的时间距离来表示，单位：2ms。</div>',

	key_Qrs_group_end: 'QRS复合波终点',
	text_Qrs_group_end: '{divFront}QRS复合波终点: 指QRS复合波的终点（即J点），用该点与中位值波形模板原点的时间距离来表示，单位：2ms</div>',

	key_J: 'J',
	text_J: '{divFront}J点: 指QRS复合波的终点（即J点），用该点与中位值波形模板原点的时间距离来表示，单位：2ms</div>',

	key_Qi: 'Qi',
	text_Qi: '{divFront}Q波起点:指Q波的起点(即Qi)，用该点与中位值波形模板原点的时间距离表示，单位：2ms。</div>',

	key_Q: 'Q',
	text_Q: '{divFront}Q点:指Q波的最大振幅点</div>',

	key_Qe: 'Qe',
	text_Qe: '{divFront}Qe:指Q波的终点，用该点与中位值波形模板原点的时间距离表示，单位：2ms。</div>',

	key_Ti: 'Ti',
	text_Ti: '{divFront}T复合波起点: 指T复合波的起点，指距{spanFront}QRS复合波终点{spanRear}加上{spanFront}平均RR间期{spanRear}值用该点到中位值波形模板原点的时间距离来表示，单位：2ms。</div>',

	key_Te: 'Te',
	text_Te: '{divFront}T复合波终点: 指T复合波的终点(即Te)，用该点到中位值波形模板原点的时间距离，单位：2ms。</div>',

	key_Pr: 'PR间期',
	text_Pr: '{divFront}PR间期：指{spanFront}Pi{spanRear}(P复合波起点)到{spanFront}QRS波群起点{spanRear}之间的时间，单位：ms。</div>',

	key_Pi: 'Pi',
	text_Pi: '{divFront}P波起点(Pi)：即P复合波的起点（P波斜率开始变化的点），用该点与中位值波形模板原点的时间距离来表示，单位：2ms。</div>',

	key_Pe: 'Pe',
	text_Pe: '{divFront}P波终点(Pe)：即P复合波的终点，用该点与中位值波形模板点的时间距离来表示，单位：2ms。</div>',

	key_Si: 'Si',
	text_Si: '{divFront}S波起点(Si)：即S复合波的起点，用该点与中位值波形模板原点的时间距离来表示，单位：2ms。</div>',

	key_S: 'S',
	text_S: '{divFront}S波峰值(S)：{spanFront}QRS波群{spanRear}中第一个正向波后出现的第一个负向波（即S波）最大振幅的点。</div>',

	key_St_volt: 'ST段电压',
	text_St_volt: '{divFront}ST段电压: 指在{spanFront}QRS复合波终点{spanRear}（即J点）位置的ST段相对于{spanFront}QRS波群起点{spanRear}的电压水平，单位：mV。</div>',

	key_RR_range: 'RR(n)间期',
	text_RR_range: '{divFront}RR间期: 指当前{spanFront}R{spanRear}波距离上一个心拍{spanFront}R{spanRear}波的时间间隔，单位：mV。</div>',

	key_RR_Plus_range: 'RR(n+1)间期',
	text_RR_Plus_range: '{divFront}RR(n+1)间期: 指当前{spanFront}R{spanRear}波距离下一个心拍{spanFront}R{spanRear}波的时间间隔，单位：mV。</div>',

	key_R: 'R',
	text_R: '{divFront}R点:指{spanFront}QRS波群{spanRear}中第一个正向波（R波）最高振幅的点。</div>',

	key_QRS_range: 'QRS波群',
	text_QRS_range: `{divFront}QRS波群：指{spanFront}QRS波群起点{spanRear}到{spanFront}QRS复合波终点{spanRear}范围的波群。</div>`,

	key_PP_range: 'PP(n)间期',
	text_PP_range: `{divFront}PP间期: 指当前{spanFront}Pp{spanRear}(P波峰值)距离上一个心拍{spanFront}Pp{spanRear}(P波峰值)的时间间隔，单位：mV。</div>`,

	key_Pp: 'Pp',
	text_Pp: `{divFront}Pp: 指P复合波第一时相的最高振幅的点。</div>`,

	key_St_slope: 'ST段斜率',
	text_St_slope: `{divFront}ST段斜率: 指{spanFront}QRS复合波终点{spanRear}(即J点)到{spanFront}Ti{spanRear}(T复合波起点)振幅幅值的斜率。</div>`,

	key_P_form: 'P波形态',
	text_P_form: `{divFront}P波形态: 指{spanFront}Pi{spanRear}到{spanFront}Pe{spanRear}波形形态。</div>`,

	key_T_form: 'T波形态',
	text_T_form: `{divFront}T波形态: 指{spanFront}Ti{spanRear}到{spanFront}Te{spanRear}波形形态。</div>`,

	/** canvas */
	canvas_qrs_start: 'QRS起点',
	canvas_q_start: 'Q波起点',
	canvas_q_end: 'Q波终点',
	canvas_t_start: 'T波起点',
	canvas_t_end: 'T波终点',
	canvas_p_start: 'P波起点',
	canvas_p_end: 'P波终点',
	canvas_pr_range: 'PR间期',
	canvas_st_range: 'ST间期',
	canvas_r_peak: 'R波峰值'

}

