<template>
	<div
		v-show="!isMin"
		class="ys-dialog"
		:style="dialogStyle"
		:movable="movable"
		:resizable="resizable"
		:max="!isMax"
		tabindex="1"
		@focus="toTop"
		@touchstart.stop="resizeSwitch($event, true)"
		@touchmove.stop="multiTouch"
		@touchend.stop="resizeSwitch($event, false)"
	>
		<div
			class="tl"
			@mousedown="manualResize"
		/>
		<div
			class="tm"
			@mousedown="manualResize"
		/>
		<div
			class="tr"
			@mousedown="manualResize"
		/>
		<div
			class="lm"
			@mousedown="manualResize"
		/>
		<div
			v-show="isResizing"
			class="main-panel cover"
		/>
		<div
			v-show="!isResizing"
			class="main-panel"
		>
			<div class="header">
				<div
					class="title"
					@mousedown.stop="moveHandle"
					@touchstart.stop="touchStart"
					@touchmove.stop="touchMove"
					@touchend.stop="touchEnd"
				>
					{{ `${$t(dName)} ${vTitle}` }}
				</div>
				<div class="btn-group">
					<span
						class="btn minimize"
						@click="toggleDialog"
					>
						<i class="iconfont icon-minimize_window" />
					</span>
					<span
						v-if="resizable"
						class="btn maximize"
						@click="toggleMaximize"
					>
						<i
							class="iconfont"
							:class="isMaximized"
						/>
					</span>
					<span
						class="btn close"
						@click="closeDialog"
					>
						<i class="iconfont icon-Close" />
					</span>
				</div>
			</div>
			<div
				ref="body"
				class="dialog-body"
				:class="{ moving: isMoving }"
			>
				<slot :on-resize="resizeHandle" />
			</div>
		</div>
		<div
			class="rm"
			@mousedown="manualResize"
		/>
		<div
			class="bl"
			@mousedown="manualResize"
		/>
		<div
			class="bm"
			@mousedown="manualResize"
		/>
		<div
			class="br"
			@mousedown="manualResize"
		/>
	</div>
</template>

<script>
export default {
	name: 'YsDialog',
	props: {
		id: {
			type: String,
			required: true
		},
		dName: {
			type: String,
			required: true
		},
		title: {
			type: Object || String,
			default: () => {

			}
		},
		position: {
			type: Object,
			required: true
		},
		resizable: {
			type: Boolean,
			default: true
		},
		movable: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			dialogPosition: {
				top: 0,
				left: 0,
				width: 500,
				height: 500
			},
			dialogMovement: {
				x: 0,
				y: 0
			},
			touchingPoints: [],
			isMoving: false,
			mouseDownPosition: { x: 0, y: 0 },
			isMax: true,
			isResizing: false,
			resizeListener: new Set()
		}
	},
	computed: {
		// 从store中获取当前组件对应的窗口是否已经最小化
		isMin() {
			return this.$store.getters.isMinimized(this.id)
		},
		dialogParams() {
			return this.$store.getters.dialogState[this.id]
		},
		/**
		 * Check type of title and generate the international title by using i18n.
		 * if props.title is an object, it will be used as the i18n key.
		 */
		vTitle() {
			if (typeof this.title === 'object') {
				return this.$t(this.title.key, this.title.params)
			}
			return this.title || ''
		},
		// 控制最大化按钮图标
		isMaximized() {
			if (this.isMax) {
				return { 'icon-window-maximize': true }
			} else {
				return { 'icon-maximize': true }
			}
		},
		// 动态计算当前DOM位置样式
		dialogStyle() {
			const { top, left, width, height } = this.dialogPosition
			const { x, y } = this.dialogMovement
			const zIndex = this.$store.getters.dialogList.indexOf(this.id) + 100
			if (this.isMax) {
				return {
					top: `${top + y}px`,
					left: `${left + x}px`,
					width: `${width}px`,
					height: `${height}px`,
					zIndex
				}
			}
			return {
				top: '0',
				left: '0',
				width: '100%',
				height: 'calc(100% - 32px)',
				zIndex
			}
		}
	},
	watch: {
		isMax(val) {
			if (val) this.toTop()
		},
		// 如果用户手动最大化后还原窗口， 触发resize方法
		title() {
			const params = this.dialogPosition
			this.$store.dispatch('updateDialogState', { instance: this, params })
		}
	},
	mounted() {
		this.dialogPosition.bodyElement = this.$refs.body
		this.dialogPosition.min = false
		this.$store.dispatch('updateDialogState', {
			key: this.id,
			position: this.dialogPosition
		})
		this.initDialogSize()
	},
	beforeDestroy() {
		this.$store.dispatch('DestroyDialog', this.id)
	},
	methods: {
		toggleDialog() {
			this.$store.dispatch('ToggleMinimizeDialog', this.id)
		},
		/**
		 * 窗口获取焦点时的处理方法
		 */
		async toTop() {
			return await this.$store.dispatch('ToTop', this.id)
		},
		/**
		 * 关闭窗口
		 */
		closeDialog() {
			this.$store.dispatch('DestroyDialog', this.id)
		},
		diagonal(x, y) {
			return Math.sqrt(x * x + y * y)
		},
		/**
		 * 激活或结束窗口大小调整
		 */
		resizeSwitch(event, state) {
			const { touches } = event
			const fingers = touches.length
			if (fingers === 2 && state) { // 开始
				this.isResizing = true
				const [t1, t2] = touches
				this.touchingPoints = [{ x: t1.clientX, y: t1.clientY }, { x: t2.clientX, y: t2.clientY }, { w: this.dialogPosition.width, h: this.dialogPosition.height, t: this.dialogPosition.top, l: this.dialogPosition.left }]
			} else { // 结束
				this.isResizing = false
				this.touchingPoints = []
				this.resize()
			}
		},
		/**
		 * 多指触控处理方法
		 * @param {TouchEvent} event
		 */
		multiTouch(event) {
			const { touches } = event
			const fingers = touches.length
			switch (fingers) {
				case 2: {
					if (this.isResizing && this.resizable) {
						this.resizeBy2Fingers(touches)
					}
					break
				}
				default: {
					return
				}
			}
		},
		resizeBy2Fingers(touches) {
			const [touch1, touch2] = touches
			const { clientX: x1, clientY: y1 } = touch1
			const { clientX: x2, clientY: y2 } = touch2
			const [position1, position2, size] = this.touchingPoints
			const { x: ox1, y: oy1 } = position1
			const { x: ox2, y: oy2 } = position2

			const { w, h, t, l } = size
			const dX = ox1 - ox2 - x1 + x2
			const dY = oy1 - oy2 - y1 + y2
			const newWidth = Math.round(w + dX)
			const newHeight = Math.round(h + dY)
			const newX = Math.round(t - dX / 2)
			const newY = Math.round(l - dY / 2)

			this.dialogPosition = {
				top: newY,
				left: newX,
				width: newWidth,
				height: newHeight
			}
			return
		},
		/**
		 * 手动调整窗口大小:
		 * 监听鼠标按下的事件, 实现根据className识别用户需要调整方向而按需调整窗口大小的功能
		 *
		 * @param { MouseEvent } event 鼠标按下事件
		 * @returns { void }
		 */
		manualResize(event) {
			const { target } = event
			if (!target) return
			const { className } = target
			this.isResizing = true
			const ox = event.screenX
			const oy = event.screenY
			const axis = [
				className.match('l'),
				className.match('r'),
				className.match('t'),
				className.match('b')
			]
			const { top, left, width, height } = this.dialogPosition
			document.onmousemove = (evt) => {
				const { screenX, screenY } = evt
				const x = screenX - ox
				const y = screenY - oy

				if (axis[0] || axis[1]) {
					const newWidth = width + (!axis[1] ? -x : x)
					if (newWidth < 150) {
						this.dialogPosition.width = 150
						return
					}
					this.dialogPosition.width = newWidth
					if (axis[0]) this.dialogPosition.left = left + x
				}
				if (axis[2] || axis[3]) {
					const newHeight = height + (!axis[3] ? -y : y)
					if (newHeight < 60) {
						this.dialogPosition.height = 60
						return
					}
					this.dialogPosition.height = newHeight
					if (axis[2]) this.dialogPosition.top = top + y
				}
			}
			document.onmouseup = () => {
				this.isResizing = false
				this.resize()
				document.onmousemove = null
				document.onmouseup = null
			}
		},
		/**
		 * 切换窗口处于默认状态还是最大化状态
		 */
		toggleMaximize() {
			if (!this.resizable) return
			this.isMax = !this.isMax
			this.resize()
		},
		/**
		 * 重新渲染内部组件尺寸
		 */
		resizeHandle(fn) {
			this.resizeListener.add(fn)
		},
		resize() {
			this.$nextTick().then(() => {
				this.resizeListener.forEach((fn) => {
					if (fn) fn(this.dialogPosition)
				})
			})
		},
		/**
		 * 获取窗口尺寸
		 */
		getDocumentSize() {
			const { offsetWidth, offsetHeight } = document.body
			return { docWidth: offsetWidth, docHeight: offsetHeight }
		},
		/**
		 * 初始化窗口尺寸和位置
		 */
		initDialogSize() {
			const { docWidth, docHeight } = this.getDocumentSize()
			const centerX = docWidth / 2
			const centerY = docHeight / 2

			const { offsetWidth, offsetHeight } = this.$el
			const offsetX = Math.floor(centerX - offsetWidth / 2)
			const offsetY = Math.floor(centerY - offsetHeight / 2)
			this.dialogPosition.top = offsetY
			this.dialogPosition.left = offsetX

			const position = this.position
			if (position) {
				const { top, left, width, height, max } = position
				this.isMax = !max
				if (width) this.dialogPosition.width = this.formatter(width, false)
				else {
					const offsetX = Math.floor(centerX - offsetWidth / 2)
					this.dialogPosition.left = offsetX
				}
				if (height) this.dialogPosition.height = this.formatter(height)
				else {
					const offsetY = Math.floor(centerY - offsetHeight / 2)
					this.dialogPosition.top = offsetY
				}
				if (top) this.dialogPosition.top = this.formatter(top)
				if (left) this.dialogPosition.left = this.formatter(left, false)
			}
		},
		formatter(val, isVertical = true) {
			let result = parseInt(val, 10)
			if (typeof val === 'string') {
				if (val.indexOf('%') > -1) {
					const { docWidth, docHeight } = this.getDocumentSize()
					result =
						Math.round(
							(result / 100) * (isVertical ? docHeight - 4 : docWidth - 4) * 100
						) / 100
				}
			}
			return result
		},
		/**
		 * 拖拽窗口处理方法：
		 * 当鼠标点击标题时对文档添加鼠标移动事件监听， 并在鼠标抬起后清除
		 *
		 * @param { MouseEvent } event 鼠标按下事件
		 * @returns { void }
		 */
		moveHandle(event) {
			if (!this.isMax || !this.movable || event.touches) return
			this.isMoving = true
			const { screenX, screenY } = event
			this.mouseDownPosition = {
				x: screenX,
				y: screenY
			}
			window.addEventListener('mousemove', this.mouseMove)
			window.onmouseup = (evt) => {
				evt.preventDefault()
				const { screenX, screenY } = evt
				const { X, Y } = this.limitedPosition(screenX, screenY)
				const { x, y } = this.mouseDownPosition

				this.dialogPosition.left += X - x
				this.dialogPosition.top += Y - y
				this.dialogMovement.x = 0
				this.dialogMovement.y = 0

				window.removeEventListener('mousemove', this.mouseMove)
				window.onmouseup = null
				this.isMoving = false
			}
		},
		/**
		 * 窗口移动事件处理方法
		 * @param { MouseEvent } evt 鼠标移动事件
		 * @returns { void }
		 */
		mouseMove(evt) {
			evt.preventDefault()
			const { screenX, screenY } = evt
			const { X, Y } = this.limitedPosition(screenX, screenY)
			const { x, y } = this.mouseDownPosition

			this.dialogMovement.x = X - x
			this.dialogMovement.y = Y - y
		},
		/**
		 * 拖拽窗口处理方法：
		 * 当触摸标题时对文档添加触摸移动事件监听， 并在触摸结束后清除
		 *
		 * @param { TouchEvent } event 触摸事件
		 * @returns { void }
		 */
		touchStart(event) {
			if (!this.isMax || !this.movable || !event.touches) return
			this.isMoving = true
			const { screenX, screenY } = event.touches[0]
			this.mouseDownPosition = {
				x: screenX,
				y: screenY
			}
		},
		/**
		 * 触摸移动事件处理方法
		 * @param { TouchEvent } evt 触摸移动事件
		 * @returns { void }
		 */
		touchMove(evt) {
			const { screenX, screenY } = evt.touches[0]
			const { X, Y } = this.limitedPosition(screenX, screenY)
			const { x, y } = this.mouseDownPosition

			this.dialogMovement.x = X - x
			this.dialogMovement.y = Y - y
			this.touchingPosition = { screenX, screenY }
		},
		/**
		 * 拖拽结束处理方法
		 */
		touchEnd() {
			const { screenX, screenY } = this.touchingPosition || {
				screenX: 0,
				screenY: 0
			}
			const { X, Y } = this.limitedPosition(screenX, screenY)
			const { x, y } = this.mouseDownPosition

			this.dialogPosition.left += X - x
			this.dialogPosition.top += Y - y
			this.dialogMovement.x = 0
			this.dialogMovement.y = 0
			this.isMoving = false
		},
		/**
		 * 限制窗口位置完全超出屏幕范围
		 * @param { number} pageX 鼠标横轴位置
		 * @param { number} pageY 鼠标纵轴位置
		 * @return { X: number, Y: number } X: 限制后的横坐标， Y: 限制后的纵坐标
		 */
		limitedPosition(pageX, pageY) {
			const { x, y } = this.mouseDownPosition
			const { top, left, width } = this.dialogPosition
			const { docWidth, docHeight } = this.getDocumentSize()

			let mX = pageX - x
			let mY = pageY - y

			if (top + mY < 5) {
				mY = -top
			} else if (top + 35 + mY > docHeight) {
				mY = docHeight - top - 40
			}
			if (left + mX < 60 - width) {
				mX = -left
			} else if (left + 35 + mX > docWidth) {
				mX = docWidth - left - 40
			}
			return { X: mX + x, Y: mY + y }
		}
	}
}
</script>

<style lang="scss" scoped>
.ys-dialog[movable='true']:not([max='true']) {
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	.main-panel > .header {
		cursor: move;
		.btn-group {
			cursor: default;
		}
	}
}

.ys-dialog[resizable='true']:not([max='true']) {
	& > .tl {
		cursor: se-resize;
	}
	& > .tm {
		cursor: s-resize;
	}
	& > .tr {
		cursor: sw-resize;
	}
	& > .lm {
		cursor: w-resize;
	}
	& > .rm {
		cursor: e-resize;
	}
	& > .bl {
		cursor: ne-resize;
	}
	& > .bm {
		cursor: n-resize;
	}
	& > .br {
		cursor: nw-resize;
	}
}

.ys-dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 500px;
	height: 500px;

	display: -ms-grid;
	display: grid;

	// outline: 1px solid #58f;

	grid-template-rows: 6px calc(100% - 12px) 6px;
	grid-template-columns: 6px calc(100% - 12px) 6px;

	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;

	&:focus {
		outline: none;
	}
	.cover {
		border: 3px double #fff4;
		background: #0009;
		pointer-events: none;
	}

	.main-panel {
		position: relative;
		width: calc(100% + 6px);
		height: calc(100% + 6px);
		margin: -3px;

		background: #051229bb;
		color: #000;
		border: 1px solid #45515e;
		box-shadow: 1px 1px 3px 2px #000;

		overflow: hidden;

		& > .dialog-body {
			position: relative;
			width: 100%;
			height: calc(100% - 32px);
			overflow: hidden;
		}

		& > .dialog-body.moving {
			pointer-events: none;
		}

		& > .header {
			position: relative;
			width: 100%;
			height: 32px;
			line-height: 32px;

			padding: 0 0 0 5px;

			display: flex;
			justify-content: space-between;
			align-items: center;

			background: #324c70d3;
			color: #efefef;

			.title {
				display: flex;
				flex-grow: 1;
				height: 100%;
				align-items: center;
				overflow: hidden;

				margin: -2px 0 0 5px;

				line-height: 23px;
				font-size: 14px;
			}

			.btn-group {
				display: flex;
				flex-direction: row;
				font-size: 16px;

				.btn.close:hover {
					background: #f66;
				}

				.btn {
					display: block;
					transition: all 0.2s ease-in-out;
					.iconfont {
						font-size: 12px;
					}
					&:hover {
						background: #fff3;
					}
				}

				.btn {
					width: 2em;
					height: 1.75em;
					line-height: 1.75em;
					text-align: center;
					cursor: pointer;

					&:hover {
						filter: brightness(1.2);
					}

					&:active {
						filter: brightness(0.5);
					}
				}

				.btn + .btn {
					margin-left: 2px;
				}
			}
		}
	}
}
</style>
