export default { // 一些公共部分的静态文字 如增益走速等等

	incr: '增益',
	speed: '走速',

	sampleCompiler: '心电样本管理',

	detailEcg: '心电详情图',

	featureCompiler: '特征点编辑',

	templateCompiler: '模板编辑',

	measureRuler: '手动测量',

	lastBeat: '上一个心拍',
	nextBeat: '下一个心拍',

	vector: '查看心向量分析',

	calibrate_signal: '定标信号',

	calibrate_signal_title: '定标信号',

	leadsSelection: '导联选择',

	allSelection: '全选',

	scale_reduce: '缩小',
	scale_add: '放大',
	scale_reset: '还原',

	showDate: '日期显示',
	measuring: '测量中',
	chooseTimePlaceholder: '请选择时间',
	dateShortCutting: '快捷键: alt+A',

	reset: '重置',
	confirm: '确定',
	cancel: '取消',
	save: '保存',
	save_succeed: '保存成功',
	add: '添加',

	delete_succeed: '删除成功',
	delete_fail: '删除失败',
	delete_cancel: '放弃删除',
	tip: '提示',

	noData: '暂无数据',
	loading_text: '拼命加载中',
	setting: '设置',
	time: '时间',
	date: '日期',
	apply: '应用',
	value: '值',
	choosePlaceHolder: '请选择',
	none: '无',
	corporation: '操作',
	remark: '备注',
	edit: '编辑',
	detail: '详情',
	total: '总计',
	hours: '小时',
	delete: '删除',
	lastPage: '上一页',
	nextPage: '下一页',

	secrecy: '保密',
	age: '年龄',

	male: '男',
	female: '女',
	gender: '性别',
	msgContent: '消息内容',
	register: '挂号单号',
	patientId: '病历号',
	name: '姓名',
	message_type: '事件类型',

	onlineErrorText: '设备网络异常！',
	pacing: '起搏信号',

	viewOnly: '仅供预览',
	export: '导出',
	sendCodeTip: p => `验证码已发送到您的${p.named('way')}:${p.named('text')}`,
	resend: p => `（${p.named('time')}s）后重发`,
	unAnalyzed: '不分析片段模式',
	normal: '常规模式',
	unAnalyzed_table: '不分析片段表格',
	logsSelection: '消息选择'
}
