/* eslint-disable */
import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'

import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import router from '@/router/index'

import Vue from 'vue'

import { sm3 } from 'hash-wasm'

// 与身份相关的信息
const user = {
  state: () => ({
    token: getToken(),
    name: undefined,
    avatar: '',
    companyId: '', // 登陆者所在机构的ID
    roles: undefined,
    menuList: [],
    userDetailForm: {
      userType: '0801'
    },
    expire: {
      time: 0,
      app: 0,
      web: 0,
    }
  }),
  getters: {
    getRoles: state => {
      if (!state.roles) {
        const roles = localStorage.getItem('roles') || '[]'
        state.roles = JSON.stringify(roles)
      }
      return state.roles || []
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_NAME: (state, name) => { // state是本模块内的state
      state.name = name
      if (name) localStorage.setItem('name', name)
      else localStorage.removeItem('name')
    },
    SET_COMPANY_ID: (state, id) => {
      state.companyId = id
      if (id) localStorage.setItem('companyId', id)
      else localStorage.removeItem('companyId')
    },
    SET_COMPANY_NAME: (state, name) => {
      Vue.set(state, 'companyName', name)
      if (name) localStorage.setItem('companyName', name)
      else localStorage.removeItem('companyName')
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
      if (roles) localStorage.setItem('roles', JSON.stringify(roles))
      else localStorage.removeItem('roles')
    },
    SET_USER_ID: (state, id) => {
      state.userId = id
      if (id) {
      sessionStorage.setItem('userId', id)
      localStorage.setItem('userId', id)
      } else {
        sessionStorage.removeItem('userId')
        localStorage.removeItem('userId')
      }
    },
    EXPIRE_USER: (state, params) => {
      if (params.time) Vue.set(state, 'expire', params)
    }
  },
  getters: {
    expireParams: state => state.expire
  },
  actions: {
    // 账号密码登录
    Login({ commit, dispatch }, options) {
      const { loginForm, onSuccess, onError } = options
      const username = loginForm.username.trim()
      sm3(loginForm.password).then(result => {
        login(username, result).then(response => {
          const { data } = response
          commit('SET_NAME', username)
          dispatch('CompleteLogin', data)
          onSuccess(username)
        }).catch(error => {
          onError(error)
        })
      })
    },
    CompleteLogin({ commit, dispatch }, serverResponse) {
      const { token } = serverResponse
      commit('SET_TOKEN', token)
      // dispatch('GetInfo').then(response => {
      //   // console.log(response)
      // })
      sessionStorage.removeItem('qr')
      router.push('/batchWard').catch(failure => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
          // 向用户显示一个小通知
          console.log(failure, NavigationFailureType)
        }
      })
    },
    // 获取用户信息set_roles权限set_avatar头像
    GetLocalName({ commit }) {
      const username = localStorage.getItem('name')
      commit('SET_NAME', username)
    },
    // 获取用户信息set_roles权限set_avatar头像
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const { data } = response
          if (!data) return
          const { userName, name, avatar, companyId, companyName, tenantId, logOutTime, loginCountApp, loginCountWeb } = data
          if (tenantId) { // 验证功能权限
            const role = tenantId.indexOf('01_0000') >= 0
            commit('SET_ROLES', role ? ['guest'] : ['user'])
          } else {
            reject('getInfo: roles must be a non-null array !')
          }
          commit('SET_NAME', userName || name)
          commit('SET_AVATAR', avatar)
          commit('SET_COMPANY_ID', companyId)
          commit('SET_COMPANY_NAME', companyName)

          if (logOutTime) {
            commit('EXPIRE_USER', { time: logOutTime, app: loginCountApp, web: loginCountWeb })
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      const func = () => {
        Message({
          showClose: true,
          message: '正在登出系统',
          type: 'warning',
          duration: 3_000
        })
        commit('SET_NAME',)
        commit('SET_ROLES',)
        commit('SET_AVATAR',)
        commit('SET_COMPANY_ID',)
        commit('SET_COMPANY_NAME',)
        commit('SET_USER_ID',)
        commit('CLOSE_WEBSOCKET')
        sessionStorage.removeItem('qr')
        removeToken()
      }
      return new Promise((resolve, reject) => {
        logout(state.userId).then(() => {
          func()
          resolve()
        }).catch(error => {
          func()
          reject(error)
        })
      })
    },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
