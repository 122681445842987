export default { // 心电全览图相关
	title: p => `name: ${p.named('name')} - gender:${p.named('gender')} - age:${p.named('age') || 'protected'} | register：${p.named('register')}`,
	ecgReviewer: 'ECG Reviewer',
	now: 'now',
	time: 'time',
	total: 'total',

	viewReport: 'preview report',

	viewTemplate: 'view ECG Modules',

	viewHrv: 'View HRV',
	viewLorenz: 'View Lorenz',
	viewFreq: 'View Frequency',

	viewQt: 'QT Analysis',

	viewSt: 'ST Analysis',

	viewRemix: 'Remix Analysis',

	autoLoading: 'Auto Loading',

	syncTitle: 'Show Manual Sync Record',

	jumpTimePlaceholder: 'GoTo',

	page_main: 'Front',
	page_last: 'Prev',
	page_next: 'Next',
	page_end: 'End'
}
