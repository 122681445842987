export default {
	// ST分析相关
	empty: '暂无数据',
	conclusionText: leads => {
		return ''
	},
	order: '序号',
	time: '发生时间',
	continueTime: '持续时间',
	HR5minPrev: '前五分钟心率',
	onTimeHR: '发作心率',
	maxStHR: '最大ST改变时心率',
	maxHR: '最大心率',
	st: 'ST段',
	stSlope: 'ST段斜率',
	ischemiaLoad: '缺血总负荷',
	updateTip: '您编辑的结论与最新版本的结论不一致, 是否更新?',
	conclusion: '{lead}导联：最大值：{max}mv,最小值：{min}mv，中位数: {median}mv，标准差: {sd}。\n',
	update: '更新'
}
