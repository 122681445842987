export default { // 日志浏览相关

	lastDay: 'prev day',
	nextDay: 'next day',
	expandTable: 'expand',

	dataUnit: 'unit/page',

	sample_add_title: 'add as a sample',
	sample_add_fail: 'add sample failed!',
	sample_add_succeed: 'sample added!',

	sample_exist: 'sample already exists!',

	date_tip: 'latest date!',

	date_selection_placeholder: 'select date',

	date_range_separator: 'to',

	date_start_placeholder: 'start',
	date_end_placeholder: 'end',
	date_range_placeholder: 'range',
	subtitle: '| name: {name} - pid: {pid} ',
	logsSelectionMapper: {
		ventricular_rhythm: '室性节律',
		atrial_rhythm: '房性节律',
		junctional_rhythm: '交界性节律',
		other_rhythm: '其它节律',
		sinus: '窦性',
		ventricular: '室性',
		supraventricular: '室上性',
		atrial: '房性',
		junctional: '交界性',

		other: '其它',
		power: '电源',
		store: '存储',
		communication: '通信'

	},
	logsPhyMapper: {
		房性早搏: 'A',
		室性早搏: 'V',
		交界早搏: 'J',
		左束支阻滞: 'L',
		右束支阻滞: 'R',
		房性逸博: 'e',
		室性逸博: 'E',
		交界性逸博: 'j',
		融合心搏: 'F',
		窦性心律不齐: 'SAR',
		窦性心动过速: 'STA',
		窦性心动过缓: 'SBR',
		窦性停搏: 'SP',
		室上性早搏: 'S',
		一度房室传导: 'BI',
		二度房室传导: 'BII',
		典型预激综合征: 'TPS',
		Mahaim预激综合征: 'MPS',
		正常QRS综合征: 'LGL',
		房颤: 'AFIB',
		房扑: 'AFL',
		'室扑&室颤': 'VFL',
		室性心律过速: 'VT',
		室性成对: 'VD',
		二联律室性早搏: 'B',
		三联律室性早搏: 'T',
		四联律室性早搏: 'VQU',
		二联律交界性早搏: 'JB',
		三联律交界性早搏: 'JT',
		室性短阵: 'VS',
		室上性心动过速: 'SVTA',
		房性短阵: 'AS',
		房性成对: 'AD',
		二联律房性早搏: 'AB',
		三联律房性早搏: 'AT',
		四联律房性早搏: 'AQu',
		室性逸搏心律: 'IVR',
		交界性逸搏心律: 'NOD',
		心肌缺血: 'MYI',
		心肌梗死: 'MII',
		心室肥大: 'VH',
		心房肥大: 'AH',
		睡眠呼吸暂停综合征: 'SAS'
	},
	logsEquipMapper: {
		设备开机: 'D_0',
		设备自动关机: 'D_1',
		设备主动关机: 'D_2',
		设备TF卡异常: 'D_3_0',
		设备TF卡正常: 'D_3_1',
		设备导联脱落: 'D_4',
		TF卡异常: 'D_5_1',
		创建当前文件失败: 'D_5_2',
		打开历史文件失败: 'D_5_3',
		关闭当前文件失败: 'D_5_4',
		关闭历史文件失败: 'D_5_5',
		创建心电文件头时移动文件指针失败: 'D_5_6',
		保存心电数据时移动文件指针失败: 'D_5_7',
		补发当前文件时移动文件指针失败: 'D_5_8',
		补发历史文件时移动文件指针失败: 'D_5_9',
		补发当前文件时读心电数据文件失败: 'D_5_10',
		补发历史文件时读心电数据文件失败: 'D_5_11',
		创建心电文件头时写文件失败: 'D_5_12',
		写入当前心电数据时写文件失败: 'D_5_13',
		创建心电文件头时同步文件失败: 'D_5_14',
		补发当前文件时同步文件失败: 'D_5_15',
		文件写入太慢: 'D_5_16',
		文件已关闭: 'D_5_17',
		读文件列表失败: 'D_5_18',
		补发数据位置超出文件长度: 'D_5_30',
		分配内存失败或内存溢出: 'D_5_31',
		操作系统内核访问出错: 'D_5_32',
		向队列放入准备写TF卡的数据时失败: 'D_5_33',
		从队列提取准备写TF卡的数据时失败: 'D_5_34',
		应用程序逻辑错误: 'D_5_40',

		蓝牙已链接: 'B_6',
		蓝牙自动断开: 'B_7',
		蓝牙主动断开: 'B_8',
		App低电量停止监护: 'A_9',
		紧急求救: 'A_10',
		当前记录开始补发段落: 'A_11_0',
		当前记录补发段落已完成: 'A_11_1',
		当前记录补发丢包数据: 'A_11_3',
		手动补发数据: 'A_11_4'
	}
}
