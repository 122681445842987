export default { // 血氧相关
	spoReport: '血氧诊断报告',
	subtitle: '| 姓名: {name} - 性别: {gender} - 年龄: {age} | 病历号: {pid}',

	// -------- 血氧诊断报告
	reportTitle: '动态血氧饱和度监测报告',
	spoAbstract: '血氧摘要',
	name: '姓名',
	gender: '性别',
	beginTime: '开始时间',
	endTime: '结束时间',
	department: '科室/部门',
	bedNo: '床号/编号',
	dataSource: '数据源',
	timeLong: '检测时长',

	obstruction: '阻塞性',
	central: '中枢性',
	mixed: '混合性',
	hypopnea: '低通气',

	avgSpoTitle: '平均血氧',
	avgSpo: '平均血氧',
	basicSpo: '基础血氧',
	lowerSpo: '最低血氧',
	oxygenDecStat: '氧减统计',
	oxygenDecStatus: '氧减状态',
	oxygenDecCount: '氧减量',

	spoLevelTitle: '血氧饱和度水平',
	spoLevel: '血氧饱和度水平',
	lower: '低于',
	timeUnit: '分钟(时：分：秒)',

	hrStat: '心率统计',
	avgHr: '平均心率',
	maxHr: '最快心率',
	minHr: '最慢心率',

	summary: '总结',
	docSign: '医生签字',
	reportTime: '报告时间',

	trend: '{name}趋势图',

	conclusion_normal: '血氧浓度正常',
	conclusion_spo_low: '血氧浓度偏低',
	conclusion_low: '轻微缺氧',
	conclusion_middle: '中度缺氧',
	conclusion_high: '重度缺氧',

	conclusion_time: '开始时间为{begin} - {end}，持续时间为{value}秒；',
	report_view: '预览',
	report_create: '生成',
	data_lack_tip: '暂无数据'
}
