export default { // Some static text of public parts,such as gain/walking speed, etc.

	incr: 'Gain',
	speed: 'Speed',

	sampleCompiler: 'ECG Sample Management',

	detailEcg: 'ECG Details',

	featureCompiler: 'Feature Edit',

	templateCompiler: 'Template Edit',

	measureRuler: 'Manual Measurement',

	lastBeat: 'Last Beat',
	nextBeat: 'Next Beat',

	vector: 'View Heart Vector Analysis',

	calibrate_signal: 'calibrate',

	calibrate_signal_title: 'calibrating signal',

	leadsSelection: 'Select Leads',

	allSelection: 'All',

	scale_reduce: 'zoom out',
	scale_add: 'zoom in',
	scale_reset: 'Reset',

	showDate: 'Date Display',
	measuring: 'Measuring',
	chooseTimePlaceholder: 'Please select the time',
	dateShortCutting: 'Shortcut Key: alt+A',

	reset: 'Reset',
	confirm: 'Confirm',
	cancel: 'Cancel',
	save: 'Save',
	save_succeed: 'Save Successful',
	add: 'Add',

	delete_succeed: 'Delete Successful',
	delete_fail: 'Delete Failed',
	delete_cancel: 'Cancel Delete',
	tip: 'Tips',

	noData: 'No Data',
	loading_text: 'Trying to Load',
	setting: 'Setting',
	time: 'Time',
	date: 'Date',
	apply: 'Apply',
	value: 'Value',
	choosePlaceHolder: 'Please Select',
	none: 'None',
	corporation: 'Operating',
	remark: 'Remark',
	edit: 'Edit',
	detail: 'Detail',
	total: 'Total',
	hours: 'Hours',
	delete: 'Delete',
	lastPage: 'Last Page',
	nextPage: 'Next Page',

	secrecy: 'secrecy',
	age: 'age',

	male: 'Male',
	female: 'Female',
	gender: 'Gender',
	msgContent: 'message',
	register: 'Register No.',
	patientId: 'Patient Id',
	name: 'Name',
	message_type: 'Event Type',
	onlineErrorText: 'network error！',
	pacing: 'Pacing signals',

	viewOnly: 'View only',
	export: 'export',
	sendCodeTip: p => `Verification code has been sent to ${p.named('way')}:${p.named('text')}`,
	resend: p => `After（${p.named('time')}s）it will be resent`,
	unAnalyzed: 'Un-analyzed phase mode',
	normal: 'Normal mode',
	unAnalyzed_table: 'Un-analyzed phase table',
	logsSelection: 'Message selection'
}
