import { render, staticRenderFns } from "./BreadcrumbBar.vue?vue&type=template&id=77da5cf7&scoped=true&"
import script from "./BreadcrumbBar.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@6.7.1_vue-template-compiler@2.6.14_webpack@5.72.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77da5cf7",
  null
  
)

export default component.exports