export default {
	batch: '集群监护',
	single: '个人监护',
	logout: '退出登录',
	fullScreen: '全屏浏览',

	logsViewLabel: '日志浏览', // 日志浏览

	logsViewTitle: '浏览全部患者事件日志', // 日志浏览title解释
	measure: '手动测量',
	measureLabel: '手动测量',
	measureTitle: '手动测量',

	reportLabel: '预览诊断报告',
	reportTitle: '预览诊断报告',

	analysisLabel: '综合分析',
	analysisTitle: '综合分析',

	recordsLabel: '心电全览图',
	recordsTitle: '心电全览图',

	reportListLabel: '历史诊断报告',
	reportListTitle: '历史诊断报告',

	remaining: '剩余体验: ',
	remainingTime: '本次登录的体验时间剩余',
	remainingToday: '今日剩余体验次数',
	cloud: '云平台',
	registPlz: '需要更多功能，请完成注册',
	reg: '注册'
}
