
// function findItem(state, value) {
// 	const length = state.eventsType.length
// 	for (let i = 0; i < length; i++) {
// 		const option = state.eventsType[i]
// 		if (value === option.disease) {
// 			return option.voice
// 		}
// 	}
// 	return null
// }

const alarm = {
	state: () => ({
		events: [],
		alarmTypes: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
		levelOne: {
			stickTop: false,
			notification: false,
			carousel: false,
			floodlight: false,
			floodlightColor: '#F83A40',
			alarmVolume: 0,
			alarmType: '1',
			mute: true
		},
		levelTwo: {
			stickTop: false,
			notification: false,
			carousel: false,
			floodlight: false,
			alarmVolume: 0,
			alarmType: '1',
			floodlightColor: '#F83A40',
			mute: true
		},
		levelThree: {
			stickTop: false,
			notification: false,
			carousel: false,
			floodlight: false,
			alarmVolume: 0,
			alarmType: '2',
			floodlightColor: '#F83A40',
			mute: true
		},
		levelFour: {
			stickTop: false,
			notification: false,
			carousel: false,
			floodlight: false,
			alarmVolume: 0,
			floodlightColor: '#F83A40',
			alarmType: '2',
			mute: true
		},
		voiceSettings: {
			volume: 50,
			tone: 50,
			speed: 20,
			isBroadcasting: true,
			mute: true
		},
		eventsType: [{
			code: '3000',
			disease: 'V',
			type: '室性早搏',
			voice: false,
			lv: '4'

		},
		{	code: '2000',
			disease: 'A',
			type: '室上性早搏',
			voice: false,
			lv: '3'

		},
		{	code: '4000',
			disease: 'J',
			type: '交界性早搏鉴',
			voice: false,
			lv: '3'

		},
		{	code: '6000', // 6001 6002 6003
			disease: 'L',
			type: '左束支阻滞',
			voice: false,
			lv: '3'
		},
		{	code: '7000',
			disease: 'R',
			type: '右束支阻滞',
			voice: false,
			lv: '3'
		},
		{	code: '8100',
			disease: 'e',
			type: '房性逸博',
			voice: false,
			lv: '3'
		},
		{	code: '8200',
			disease: 'E',
			type: '室性逸博',
			voice: false,
			lv: '3'
		},
		{	code: '8300',
			disease: 'j',
			type: '交界性逸博',
			voice: false,
			lv: '3'
		},
		// {	disease: 'AH',
		// 	type: '心房肥大',
		// 	voice: false,
		// 	lv: '3'
		// }
		// {	disease: 'VH',
		// 	type: '心室肥大',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'MYI',
		// 	type: '心肌缺血',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'MII',
		// 	type: '心肌梗死',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'PREX',
		// 	type: '预激综合征',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'AFIB',
		// 	type: '房颤',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'AFL',
		// 	type: '房扑',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'VFL',
		// 	type: '室扑&室颤',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'Pause',
		// 	type: '停搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		{	disease: 'SBR',
			type: '窦性心律过缓',
			voice: false,
			lv: '3'
		},
		{	disease: 'STA',
			type: '窦性心动过速',
			voice: false,
			lv: '3'
		},
		{	disease: 'SAR',
			type: '窦性心律不齐',
			voice: false,
			lv: '3'
		},
		// {	disease: 'SVTA',
		// 	type: '室上性心律过速',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'AB',
		// 	type: '二联律房性早搏 ',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'AT',
		// 	type: '三联律房性早搏 ',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'AQu',
		// 	type: '四联律房性早搏 ',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'AD',
		// 	type: '房性成对 ',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'VT',
		// 	type: '室性心律过速 ',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'VER',
		// 	type: '室性逸搏心律 ',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'B',
		// 	type: '二联律室性早搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'T',
		// 	type: '三联律室性早搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'VQU',
		// 	type: '四联律室性早搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'VD',
		// 	type: '室性成对',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'JB',
		// 	type: '二联律交界性早搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'JT',
		// 	type: '三联律交界性早搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'Jqu',
		// 	type: '四联律交界性早搏',
		// 	voice: false,
		// 	lv: '3'
		// },
		// {	disease: 'JER',
		// 	type: '交界性逸搏心律',
		// 	voice: false,
		// 	lv: '3'
		// },
		{	disease: 'SP',
			type: '窦性停搏',
			voice: false,
			lv: '3'
		},
		{	disease: 'BI',
			type: '一度房室传导',
			voice: false,
			lv: '3'
		},
		{	disease: 'BII',
			type: '二度房室传导',
			voice: false,
			lv: '3'
		},
		{	disease: 'TPS',
			type: '典型预激综合征',
			voice: false,
			lv: '3'
		},
		{	disease: 'MPS',
			type: 'Mahaim预激综合征',
			voice: false,
			lv: '3'
		},
		{	disease: 'LGL',
			type: '正常QRS综合征',
			voice: false,
			lv: '3'
		},
		{	disease: '/',
			type: '起搏心搏',
			voice: false,
			lv: '3'
		},
		{	disease: '&',
			type: '未识别心搏',
			voice: false,
			lv: '3'
		},
		{	disease: '?',
			type: '质量差的心拍',
			voice: false,
			lv: '3'
		},
		{	disease: 'F',
			type: '融合心搏',
			voice: false,
			lv: '3'
		}
			// {	disease: 'AS',
			// 	type: '房性短阵',
			// 	voice: false,
			// 	lv: '3'
			// }

		]

	}),
	mutations: {
		SET_LEVEL_STICK_TOP(state, payload) {
			state[payload.type].stickTop = payload.data
		},
		SET_LEVEL_NOTIFICATION(state, payload) {
			state[payload.type].notification = payload.data
		},
		SET_LEVEL_CAROUSEL(state, payload) {
			state[payload.type].carousel = payload.data
		},
		SET_LEVEL_FLOODLIGHT(state, payload) {
			state[payload.type].floodlight = payload.data
		},
		SET_LEVEL_LIGHT_COLOR(state, payload) {
			state[payload.type].floodlightColor = payload.data
		},
		SET_LEVEL_ALARM_VOLUME(state, payload) {
			state[payload.type].alarmVolume = payload.data
		},
		SET_LEVEL_ALARMTYPE(state, payload) {
			state[payload.type].alarmType = payload.data
		},
		SET_LEVEL_ALARM_VOLUME_MUTE(state, payload) {
			state[payload.type].mute = payload.data
		},
		SET_VOICE_VOLUME(state, payload) {
			state.voiceSettings.volume = payload
		},
		SET_VOICE_TONE(state, payload) {
			state.voiceSettings.tone = payload
		},
		SET_VOICE_SPEED(state, payload) {
			state.voiceSettings.speed = payload
		},
		SET_VOICE_BROADCASTING(state, payload) {
			state.voiceSettings.isBroadcasting = payload
		},
		SET_VOICE_MUTE(state, payload) {
			state.voiceSettings.mute = payload
		},
		SET_EVENTS_TYPE(state, payload) {
			const length = state.eventsType.length
			const operation = payload.operation
			for (let i = 0; i < length; i++) {
				const option = state.eventsType[i]
				if (option.disease === payload.disease) {
					option[operation] = payload[operation]
					break
				}
			}
		},
		UPDATE_EVENTS(state, { type, bool }) {
			if (bool) {
				if (state.events.includes(type)) return
				state.events.push(type)
			} else {
				if (state.events.includes(type)) {
					const index = state.events.indexOf(type)
					state.events.splice(index, 1)
				}
			}
		}
	},
	actions: {
		setLevelStickTop({ commit }, { type, data }) {
			// 接口处理数据 返回处理commit 暂时空置
			commit('SET_LEVEL_STICK_TOP', { type, data })
		},
		setLevelNotification({ commit }, { type, data }) {
			// 异步请求 暂时空置
			commit('SET_LEVEL_NOTIFICATION', { type, data })
		},
		setLevelCarousel({ commit }, { type, data }) {
			// 异步请求 暂时空置
			commit('SET_LEVEL_CAROUSEL', { type, data })
		},
		setLevelFloodlight({ commit }, { type, data }) {
			// 异步请求 暂时空置
			commit('SET_LEVEL_FLOODLIGHT', { type, data })
		},
		setLevelLightColor({ commit }, { type, data }) {
			// 异步请求 暂时空置
			commit('SET_LEVEL_LIGHT_COLOR', { type, data })
		},
		setLevelAlarmVolume({ commit }, { type, data }) {
			// 异步请求 暂时空置
			commit('SET_LEVEL_ALARM_VOLUME', { type, data })
		},
		setLevelAlarmVolumeMute({ commit }, { type, data }) {
			commit('SET_LEVEL_ALARM_VOLUME_MUTE', { type, data })
		},
		setLevelAlarmType({ commit }, { type, data }) {
			// 异步请求 暂时空置
			commit('SET_LEVEL_ALARMTYPE', { type, data })
		},
		setVoiceVolume({ commit }, data) {
			commit('SET_VOICE_VOLUME', data)
		},
		setVoiceTone({ commit }, data) {
			commit('SET_VOICE_TONE', data)
		},
		setVoiceSpeed({ commit }, data) {
			commit('SET_VOICE_SPEED', data)
		},
		setVoiceBroadcasting({ commit }, data) {
			commit('SET_VOICE_BROADCASTING', data)
		},
		setVoiceMute({ commit }, data) {
			commit('SET_VOICE_MUTE', data)
		},
		setEventsType({ commit }, data) {
			commit('SET_EVENTS_TYPE', data)
		}
	},
	getters: {
		getEvents: (state) => state.events,
		getLevelSettings: state => level => state[level],
		getVoiceSettings: state => state.voiceSettings,
		getVoiceMute: state => state.voiceSettings.mute,
		getVoiceVolume: state => state.voiceSettings.volume,
		getVoiceSpeed: state => state.voiceSettings.speed,
		getVoiceTone: state => state.voiceSettings.tone,
		getVoiceBroadcasting: state => state.voiceSettings.isBroadcasting,
		getAlarmTypes: state => state.alarmTypes,
		getEventsType: state => state.eventsType,
		getSpecificEventByName: state => name => {
			const length = state.eventsType.length
			for (let i = 0; i < length; i++) {
				const option = state.eventsType[i]
				if (name === option.type) {
					return option
				}
			}
			return null
		},
		getSpecificEvent: state => disease => {
			const length = state.eventsType.length
			for (let i = 0; i < length; i++) {
				const option = state.eventsType[i]
				if (disease === option.disease) {
					return option
				}
			}
			return null
		} // 以下是目前实现了的事件 暂时写入以下几类
		// getEventA: state => {
		// 	return findItem(state, 'A')
		// },
		// getEventV: state => {
		// 	return findItem(state, 'V')
		// },
		// getEventJ: state => {
		// 	return findItem(state, 'J')
		// },
		// getEventL: state => {
		// 	return findItem(state, 'L')
		// },
		// getEventR: state => {
		// 	return findItem(state, 'R')
		// },
		// getEvente: state => {
		// 	return findItem(state, 'e')
		// },
		// getEventE: state => {
		// 	return findItem(state, 'E')
		// },
		// getEventj: state => {
		// 	return findItem(state, 'j')
		// }
	}
}

export default alarm
