export default {
	zh_CN: '简体中文(中国)',
	zh_TW: '繁體中文(台灣地區)',
	zh_HK: '繁體中文(香港地區)',
	en_HK: 'English(HK)',
	en_US: 'English(US)',
	en_GB: 'English(UK)',
	en_WW: 'English(Global)',
	en_CA: 'English(CA)',
	en_AU: 'English(AU)',
	en_IE: 'English(IE)',
	en_FI: 'English(FI)',
	fi_FI: 'Suomi',
	en_DK: 'English(DK)',
	da_DK: 'Dansk',
	en_IL: 'English(IL)',
	he_IL: 'עברית',
	en_ZA: 'English(ZA)',
	en_IN: 'English(IN)',
	en_NO: 'English(NO)',
	en_SG: 'English(SG)',
	en_NZ: 'English(NZ)',
	en_ID: 'English(ID)',
	en_PH: 'English(PH)',
	en_TH: 'English(TH)',
	en_MY: 'English(MY)',
	en_XA: 'English(XA)',
	ko_KR: '한국어',
	ja_JP: '日本',
	nl_NL: 'Nederland(NL)',
	nl_BE: 'Nederland(BE)',
	pt_PT: 'Português(PT)',
	pt_BR: 'Português(BR)',
	fr_FR: 'Français(FR)',
	fr_LU: 'Français(LU)',
	fr_CH: 'Français(CH)',
	fr_BE: 'Français(BE)',
	fr_CA: 'Français(CA)',
	es_LA: 'Español(LA)',
	es_ES: 'Español(ES)',
	es_AR: 'Español(AR)',
	es_US: 'Español(US)',
	es_MX: 'Español(MX)',
	es_CO: 'Español(CO)',
	es_PR: 'Español(PR)',
	de_DE: 'Deutsch(DE)',
	de_AT: 'Deutsch(AT)',
	de_CH: 'Deutsch(CH)',
	ru_RU: 'Русский(RU)',
	it_IT: 'Italiano(IT)',
	el_GR: 'Ελληνικά(GR)',
	no_NO: 'Norsk(NO)',
	hu_HU: 'Magyar(HU)',
	tr_TR: 'Türkçe(TR)',
	cs_CZ: 'Čeština(CZ)',
	sl_SL: 'Slovenščina',
	pl_PL: 'Polski(PL)',
	sv_SE: 'Svenska(SE)',
	es_CL: 'Español(CL)'
}
