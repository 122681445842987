<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	computed: {
		language() {
			return this.$store.getters.lang
		}
	},
	watch: {
		language() {
			const currentRoute = this.$route
			if (currentRoute.meta && currentRoute.meta.title) { window.document.title = this.$t(currentRoute.meta.title) }
		}
	}
}
</script>
