const getters = {
	token: state => state.user.token, // 获取token
	avatar: state => state.user.avatar, //
	name: state => state.user.name || localStorage.getItem('name'),
	roles: state => state.user.roles,
	companyId: state => state.user.companyId || localStorage.getItem('companyId'),
	companyName: state => state.user.companyName || localStorage.getItem('companyName'),
	diagStyles: state => state.ecg.diagStyles,
	userId: state => {
		const userId = state.user.userId || sessionStorage.getItem('userId')
		if (!userId) {
			return localStorage.getItem('userId')
		}
		return userId
	},
	getHR: state => pid => state.ecg.hr[pid] || {},
	userDetailForm: state => state.user.userDetailForm
}
export default getters
