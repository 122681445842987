<template>
	<div class="yasun-ecg-client">
		<Navigation />
		<div class="main-container">
			<AppMain />
		</div>
		<AlarmPanel>
			<DialogList />
		</AlarmPanel>
	</div>
</template>

<script>
import Navigation from './components/Navigation'
import AlarmPanel from './components/AlarmPanel'
import AppMain from './components/AppMain'
import DialogList from './components/DialogList'

// import ApiWorker from './shared2.worker.js'
// import { getToken } from '@/utils/auth'

export default {
	name: 'Layout',
	components: {
		AppMain,
		AlarmPanel,
		Navigation,
		DialogList
	},
	computed: {
		language() {
			return this._i18n.locale
		}
	},
	watch: {
		language(val) {
			this.$store.dispatch('setLanguage', val)
			this.$store.dispatch('GetPatientList')
		}
	},
	mounted() {
		this.$i18n.locale = this.$store.getters.lang
		this.$store.dispatch('GetInfo')
		this.$store.dispatch('GetPatientList')
	},
	beforeDestroy() {
		this.$store.dispatch('CloseWebSocket')
	}
}
</script>
