import request from '@/utils/request'
// import md5 from 'js-md5'

// 通过手机注册
export function registByMobile(username, mobile, code) {
	return request({
		url: '/user/saveUser',
		method: 'post',
		data: {
			loginId: username,
			mobile: mobile,
			mobileCode: code
		}
	})
}

// 通过手机重置密码
export function resetPwdByMobile(mobile, code) {
	return request({
		url: '/user/updatePwdByMobile',
		method: 'post',
		data: {
			mobile,
			mobileCode: code
		}
	})
}

/**
 * 获取用于登陆的二维码ID
 * @param { void }
 * @returns { AxiosPromise<AxiosResponse<{ qrcode: string }>> } 返回的二维码ID
 */
export function getQrCode() {
	return request({
		url: '/userCenter/qrcode',
		method: 'post'
	})
}
/**
 * 获取二维码扫码状态
 * @param { Object<{ qrCodeId: string }> } data 二维码ID
 * @return { AxiosPromise<AxiosResponse<{ token: string }>> } 返回Promise, 扫码成功的话可以获取token, 扫码失败的话返回空, 需要继续调用
 */
export function getQrStates(data) {
	return request({
		url: '/userCenter/getQrStates',
		method: 'post',
		data
	})
}

// 获取短信验证码 （临时）
export function getSMSCode(mobile) {
	return request({
		url: '/user/getMobileCode',
		method: 'post',
		data: {
			mobile
		}
	})
}

// 短信登录
export function smsLogin(mobile, code) {
	return request({
		url: '/user/userLoginByMobile',
		method: 'post',
		data: {
			mobile,
			mobileCode: code
		}
	})
}

/**
 * 使用帐号密码登陆
 * @param { login: string } 登陆帐号
 * @param { pwd: string } 帐号密码
 * @returns { AxiosPromise<AxiosResponse<Res<{ token?: string }>>> } 登陆成功则返回token, 登陆失败在msg中返回问题
 */
export function login(login, pwd) {
	return request({
		url: '/userCenter/loginByPwd',
		method: 'post',
		data: {
			code: 200,
			login,
			pwd,
			userType: 1
		}
	})
}

/**
 * 获取登陆用户的信息
 * @param { void }
 * @returns { AxiosPromise<AxiosResponse<ServRes<any>>> } 返回的用户信息
 */
export function getInfo() {
	return request({
		url: '/userCenter/getLoginUser',
		method: 'post'
	})
}

/**
 * 登出
 * @param { void }
 * @returns { AxiosPromise<AxiosResponse<ServRes<any>>> }
 */
export function logout() {
	return request({
		url: '/userCenter/logOut',
		method: 'post'
	})
}

/**
 * 注册申请
 * @param { form: Object<{
 * 	name: String,
 *  country: String,
 * 	mobile?: String,
 *  email?: String,
 * 	emailCheck: Boolean,
 * 	company?: String,
 *  gender?: 0 | 1,
 *  address?: String,
 *  gateway: String,
 *  reason: String,
 * 	notes?: String,
 * 	companyPosition?: String
 * }> }
 * @returns { AxiosPromise<AxiosResponse<any>> }
 */
export function registApply(form) {
	return request({
		url: '/userCenter/experience/register',
		method: 'post',
		data: form
	})
}

// 修改密码-账号验证
export function getAccountCode(login) {
	return request({
		url: '/userCenter/sendSystemAccountCode',
		method: 'post',
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		},
		data: login
	})
}

// 修改密码-验证码验证
export function checkAccountCode(data) {
	return request({
		url: '/userCenter/checkSystemAccountCode',
		method: 'post',
		data
	})
}

// 修改密码-验证码验证
export function resetPsw(data) {
	return request({
		url: '/userCenter/editSystemAccountPassword',
		method: 'post',
		data
	})
}
