export default {
	lang: 'language',
	ward: '监护',
	batch: '集群监护',
	single: '个人监护',
	yasun: '岩尚科技',
	login: '岩尚科技-登录',
	register: '岩尚科技-注册',
	reset: '岩尚科技-重置密码',
	selectLang: 'switch language'
}
