<template>
	<section class="app-main">
		<transition
			name="fade"
			mode="out-in"
		>
			<router-view class="main-view" />
		</transition>
		<ys-dialog
			v-for="(params, key) in list"
			:id="key"
			:key="key"
			:position="params.position"
			:resizable="params.resizable"
			:movable="params.movable"
			:title="params.title"
			:d-name="params.name"
		>
			<component
				:is="params.component"
				slot-scope="{ onResize }"
				:infos="params.infos"
				:on-resize="onResize"
			/>
		</ys-dialog>
	</section>
</template>

<script>
import YsDialog from './YsDialog.vue'
export default {
	components: {
		YsDialog
	},
	computed: {
		list() {
			return this.$store.getters.dialogs
		},
		dialogList() {
			return this.$store.getters.dialogList
		}
	}
}
</script>
