export default { // 集群监护相关

	query: { /** 搜索栏 */
		// 机构选择
		companyTitle: '按医疗机构进行筛选', // 搜索栏title提示
		companyPlaceholder: '请选择机构',

		// 过滤条件选择

		filterTitle: '选择过滤条件',
		filterPlaceholder: '请输入',

		filterPatientId: '病历号',
		filterName: '姓名',
		filterOrderNo: '编号/床号',

		filterKeywordTitle: '患者过滤条件值',

		reset: '重置',

		patientCounts: '患者数',

		patientsShowTitle: '点击显示离线患者',
		patientsHiddenTitle: '点击隐藏离线患者',

		showAllPatients: '全部显示',
		showAllCompany: '全部机构',

		allCompanyShowTitle: '点击显示全部机构患者',
		onlyCompanyShowTitle: '点击只显示该机构患者',

		/** 导联 */
		/** 导联 */
		lead_single: '单导联',
		lead_seven: '七导联',
		lead_eight: '八导联',
		lead_twelve: '十二导联',
		lead_fifteen: '十五导联',
		lead_eighteen: '十八导联',
		lead_eighteen_night: '十八导联(9电极)',
		lead_unknown: '未知',
		register: '挂号单'
	},
	patientCard: { /** 用户卡片相关 */

		orderNo: '编号/床号',
		name: '姓名',
		gender: '性别',
		company: '医疗机构',
		dept: '科室/部门',

		offline: '设备离线',

		msgPanelTitle: '生理和技术警报信息', // 警报提示

		reportTitle: '预览诊断报告',
		recordTitle: '查看心电记录',

		hr: '心率',
		nibp: '血压',
		bp_s: '收缩压',
		bp_d: '舒张压',
		bp_m: '平均压',
		time: '时间',

		resp: '呼吸频率',
		temp: '体温',
		spo: '血氧饱和度',

		bs: '血糖',

		male: '男',
		female: '女',
		secrecy: '保密',

		reportError: '诊断报告预览失败',

		realEcg: '动态心电图',

		happen: '发生了',

		checkMore: '点击查看',

		/** 集群监护 动态心电相关 */

		incrTitle: '心电增益',
		incrShortcutTitle: 'CTRL+滚轮调整增益',

		currentTimeTitle: '当前心电数据时间',
		/** 设备 */
		wifi: 'wifi信号',
		blueTooth: '蓝牙信号',
		mobilePower: '手机电量',
		equipmentPower: '设备电量',
		offlineTip: '双击进入个人监护页面'

	}

}
