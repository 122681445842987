export default { // 血压报告相关
	nibpReport: '血压诊断报告',
	title: '动态血压报告单',

	name: '姓名',
	birth: '出生日期',
	recordNo: '病历号',
	age: '年龄',
	gender: '性别',
	diagnostician: '诊断医师',
	telephone: '电话',
	treatmentDate: '就诊日期',
	department: '科室/床号',
	remark: '备注',

	ABPMSummary: 'ABPM总结',
	period1: '周期',
	gross1: '总量',
	day1: '白天',
	night1: '夜间',
	morning1: '早晨',
	timeRange1: '时间范围',
	sbpUpperLimit1: 'SBP/DBP上限',
	sbpWA1: 'SBP/DBP加权平均',
	pulseWA1: '脉搏加权平均',
	sbpMbpDayNight1: '收缩压/舒张压 白天/夜间 指数%',
	successRate: '成功率',
	morningSurge1: '早晨 surge',

	highPressureSummary: '高压总结',
	MapWA: 'MAP加权平均',
	ppWA: '脉搏加权平均',
	sbpLoad: 'SBP/DBP 负荷 mmHg',
	sbpMbpDayNightAvgDev: '收缩压/舒张压 白天/夜间 平均差',
	period2: '周期',
	gross2: '总量',
	day2: '白天',
	night2: '夜间',
	morning2: '早晨',

	timeRange2: '时间范围',
	sbpUpperLimit2: 'SBP/DBP上限',
	sbpWA2: 'SBP/DBP加权平均',
	pulseWA2: '脉搏加权平均',
	sbpMbpDayNight2: '收缩压/舒张压 白天/夜间 指数%',
	morningSurge2: '早晨 surge',

	nibpChart: '血压趋势图',

	reportTime: '报告时间',
	docSign: '医生签字',
	conclusion: '结论'
}
