export default { // Alarm Information

	alarmListTitle: 'Alert Messages',
	alarmList: 'Alarm List',
	alarm_item_content: '{name}{gender}Happen{msg}',

	ascending: 'Sort by time in ascending',
	descending: 'Sort by time in descending',

	checkMore: 'view more',
	readAll: 'mark read',

	voiceSetting: 'Voice setting',

	alarmSetting: 'Alarm setting',

	male: 'Male',
	female: 'Female',

	alarmListCount: 'Alert Messages:{counter}pcs', // Don't delete this Counter, it's the incoming number

	happen: 'happen/happen',

	voice_volume: 'Voice Volume',
	voice_tone: 'Voice Tone',
	voice_speed: 'Voice Speed',
	broadCasting_switch: 'Voice Switch',
	small: 'Small',
	big: 'Big',
	low: 'Low',
	high: 'High',
	slow: 'Slow',
	fast: 'Fast',

	level_one: 'Level 1',
	level_two: 'Level 2',
	level_three: 'Level 3',
	level_four: 'Level 4',

	notification: 'Notification',
	floodLight: 'Alarm Light',

	alarm_voice_volume: 'Alarm Voice Volume',
	alarm_voice_type: 'Alarm Voice Type',

	eventSetting: 'Event Setting',
	level_adjustment: 'Event Level Setting',

	eventType: 'Event Type',

	voice: 'voice',
	alarmLevel: 'Alarm Level'
}
