<template>
	<div class="dialog-list">
		<div
			v-for="(params, key) in list"
			:key="key"
			class="item"
			:class="{ onTop: isDialogOnTop(key) }"
			:title="`${$t(params.name)} ${vTitle(params.title)}`"
			@click="toggleDialog(key)"
			@contextmenu.prevent="showMenu(key, $event)"
		>
			<span>{{ `${$t(params.name)} ${vTitle(params.title)}` }}</span>
		</div>
		<div
			v-if="visible"
			class="menu menu-context"
			:style="menuStyle"
		>
			<span
				class="menu menu-item"
				@click="closeThis"
			>关闭</span>
			<span
				class="menu menu-item"
				@click="closeOthers"
			>关闭其他</span>
			<span
				class="menu menu-item"
				@click="closeAll"
			>全部关闭</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DialogList',
	data() {
		return {
			visible: false,
			menuStyle: {},
			currKey: null
		}
	},
	computed: {
		list() {
			return this.$store.getters.dialogs
		},
		dialogList() {
			return this.$store.getters.dialogList
		}
	},
	methods: {
		closeThis() {
			this.$store.dispatch('DestroyDialog', this.currKey)
			this.visible = false
		},
		closeOthers() {
			for (const key in this.list) {
				if (key !== this.currKey) {
					this.$store.dispatch('DestroyDialog', key)
				}
			}
			this.visible = false
		},
		closeAll() {
			for (const key in this.list) {
				this.$store.dispatch('DestroyDialog', key)
			}
			this.visible = false
		},
		showMenu(key, event) {
			this.visible = true
			const { offsetWidth, offsetHeight } = document.body
			const { clientX, clientY } = event
			this.$nextTick().then(() => {
				const menu = this.$el.querySelector('.menu')
				const { offsetWidth: menuWidth, offsetHeight: menuHeight } = menu
				this.currKey = key
				const x =
					(clientX + menuWidth > offsetWidth) ? offsetWidth - menuWidth : clientX
				const y =
					(clientY + menuHeight > offsetHeight) ? clientY - menuHeight : clientY
				this.$set(this, 'menuStyle', {
					top: `${y}px`,
					left: `${x}px`
				})
				const hideMenu = (evt) => {
					if (evt.target.classList.contains('menu')) return
					this.visible = false
					document.removeEventListener('click', hideMenu)
				}
				document.addEventListener('click', hideMenu)
			})
		},
		vTitle(title) {
			if (typeof title === 'object') {
				return this.$t(title.key, title.params)
			}
			return title || ''
		},
		toggleDialog(key) {
			const min = this.$store.getters.isMinimized(key)
			if (!this.isDialogOnTop(key) && !min) {
				this.$store.dispatch('ToTop', key)
			} else this.$store.dispatch('ToggleMinimizeDialog', key)
		},
		isDialogOnTop(key) {
			const lastIndex = Object.keys(this.dialogList).length - 1
			return !(this.dialogList.indexOf(key) < lastIndex)
		}
	}
}
</script>
<style lang="scss" scoped>
.dialog-list > .item.onTop {
	background: #324c70d3;
	filter: brightness(1.1);
}
.dialog-list {
	display: flex;
	flex-grow: 1;
	justify-content: flex-start;
	margin: 0 1em;

	font-size: 12px;
	overflow: hidden;

	.item {
		flex-grow: 1;
		max-width: 12em;
		height: 1.5em;
		line-height: 1.5em;
		padding: 0 10px;

		text-align: center;

		background: #0003;
		border: 1px solid #fff4;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		user-select: none;

		clip-path: polygon(
			0 0,
			0 100%,
			calc(100% - 5px) 100%,
			100% calc(100% - 5px),
			100% 0
		);

		cursor: pointer;

		&:hover {
			filter: brightness(1.5);
		}

		&:active {
			filter: brightness(0.8);
		}
	}
	.item + .item {
		margin-left: 4px;
	}
	.selected {
		background: #fff3;
	}

	.menu-context {
		position: fixed;
		width: 120px;
		min-height: 1.5em;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		padding: 5px;

		border: 1px solid #fff9;
		background: #222e;

		border-radius: 3px;

		.menu-item {
			width: 100%;
			padding: 4px;

			user-select: none;
			&:hover {
				background-color: #555e;
			}
		}
	}
}
</style>
