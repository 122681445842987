import i18n from '../../index'

export default { // 检测记录相关
	recordTitle: 'Checkup Records',
	pid: 'RID',
	lastMonth: 'prev month',
	nextMonth: 'next month',
	today: 'today',
	yesterday: 'yesterday',

	aWeekAgo: 'Previous Week',

	mon: 'Mon',
	tue: 'Tue',
	wed: 'Wed',
	thur: 'Thu',
	fri: 'Fri',
	sat: 'Sat',
	sun: 'Sun',

	ecgRecordTitle: 'ECG Records',
	asc: 'ascending',
	desc: 'descending',

	orderNoPlaceholder: 'Please enter the order number',

	toReviewer: 'ECG Review',
	analysis: 'Analysis',
	checkReport: 'Preview Report',

	registration: 'register',
	startTime: 'begin',
	entTime: 'end',
	duration: 'duration',

	monitoringRecord: 'Checkups',

	reviewer: 'Reviewer',
	logs: 'Logs',
	subtitle: p => `| Name:${p.named('name')} - Gender:${i18n.t(p.named('gender'))} - Age:${p.named('age') || i18n.t('common.secrecy')} - Register: ${p.named('register')}`
}
