export default {
	noise: '质量差的心拍',
	unknown: '未识别心拍',
	normal: '窦性正常心拍',
	A: '室上性早搏(房早)',
	V: '室性早搏',
	J: '交界性早搏',
	VFStart: '室颤的开始',
	VFEnd: '室颤的结束',
	L: '左束支传导阻滞',
	R: '右束支传导阻滞',
	e: '房性逸博',
	E: '室性逸博',
	j: '交界性逸博',
	F: '房室融合心搏',
	pacing: '起搏心搏',
	SAR: '窦性心律不齐',
	STA: '窦性心动过速',
	SBR: '窦性心动过缓',
	SP: '窦性停搏',
	S: '室上性早搏',
	BI: '一度房室传导阻滞',
	BII: '二度房室传导阻滞',
	TPS: '典型预激综合征',
	MPS: 'Mahaim预激综合征',
	LGL: '正常QRS综合征'
}
