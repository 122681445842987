<template>
	<div
		class="floodlight flood"
		:style="lightStyle"
	/>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#F83A40'
		}
	},
	computed: {
		lightStyle() {
			return `box-shadow: inset 0 0 15px 30px ${this.color};`
		}
	}
}
</script>

<style scoped lang="scss">
.floodlight{
    height: calc(100vh - 26px);
    width: 100vw;
}
@keyframes floodAlarm {
0% {
    opacity: 0.5;
  }
  85% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}
.flood {
  animation: floodAlarm 1s infinite;
}

</style>
