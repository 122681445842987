<template>
	<div class="alarm-list-item">
		<span class="time"><slot name="time" /> &nbsp;</span>
		<span class="id"><slot name="id" /> &nbsp;&nbsp;</span>
		<span class="content"> <slot name="content" /></span>
	</div>
</template>

<script>
export default {
	name: 'AlarmListItem'

}
</script>

<style scoped lang="scss">

.alarm-list-item{
    cursor: pointer;
    padding:6px 2px;
    color:white;
    font-size:12px;
    text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
    .id{
        margin-left:5px;
    }
    .content{
        margin-left:5px;
    }

}

.alarm-list-item:hover{
     background-color: rgba(78, 144, 202, .5);
}
</style>
