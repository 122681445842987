import request from '@/utils/request'

// 读取实时心电数据
export function getRealtimeECG(data) {
	return request({
		url: '/cloud/ecg/getPatientEcg',
		method: 'post',
		// onDownloadProgress: e => {
		//   console.log(e)
		// },//和onUploadProgress一样，获取下载的进度
		data
	})
}

// 请求患者信息
export function getPatientsList() {
	return request({
		url: '/cloud/cloudUser/getMobileUsers',
		method: 'post'
	})
}

// 获取心电诊断记录
export function getECGRecords(data) {
	return request({
		url: '/cloud/ecgRecord/getEcgRecords',
		method: 'post',
		data
	})
}

// 获取患者订单号日期分组列表
export function getPatientsCalendar(pid, begin, end) {
	return request({
		url: '/cloud/patientOrder/getPatientOrderCount',
		method: 'post',
		data: {
			patientId: pid,
			beginDate: begin,
			endDate: end
		}
	})
}

// 获取患者订单号日期分组列表
export function getLogsUserList(begin, end, type) {
	return request({
		url: '/cloud/patientOrder/getPatientOrderCount',
		method: 'post',
		data: {
			beginDate: begin,
			endDate: end,
			type
		}
	})
}

// 按分钟获取完整心电数据
export function getMinECGData(data) {
	return request({
		url: '/cloud/ecgRecord/getEcgData',
		method: 'post',
		data
	})
}

// 获取诊断报告数据
export function getPrRecordPdf(data) {
	return request({
		url: '/cloud/dynamicReport/addPreviewRecordPdf',
		method: 'post',
		data
	})
}

// 获取诊断报告数据
export function getRecordPdf(data) {
	return request({
		url: '/cloud/dynamicReport/addRecordPdf',
		method: 'post',
		data
	})
}

// 获取诊断报告(后端直接传回pdf文件的地址跟文件名再获取pdf文件流)
export function getFile(data) {
	return request({
		url: '/common/fileData/getFile',
		method: 'get',
		data
	})
}

// 个人监护-获取开启诊断报告数据
export function getOpenDynamicReportRecords(data) {
	return request({
		url: '/cloud/dynamicReportRecord/getOpenDynamicReportRecords',
		method: 'post',
		data
	})
}

// 综合分析-获取诊断报告数据
export function getRecordPdfData(data) {
	return request({
		url: '/cloud/dynamicReport/getRecordPdfData',
		method: 'post',
		data
	})
}

// 个人监护-获取诊断报告收费项目列表(选择检测项目列表)
export function getDynamicExpenseItem(data) {
	return request({
		url: '/cloud/expenseItem/getDynamicExpenseItem',
		method: 'post',
		data
	})
}

// 个人监护-获取用户患者的onlyId 获取最近一次检测记录数据
export function getMaxEcgRecords(data) {
	return request({
		url: '/cloud/ecgRecord/getMaxEcgRecord',
		method: 'post',
		data
	})
}

// 个人监护-获取历史诊断报告列表
export function getPageDynamicReportRecords(data) {
	return request({
		url: '/cloud/dynamicReportRecord/getPageDynamicReportRecords',
		method: 'post',
		data
	})
}

// 个人监护-获取心率趋势图的心率数据
export function getHr(data) {
	return request({
		url: '/cloud/ecgRecord/getHr',
		method: 'post',
		data
	})
}

// 个人监护-获取st面板的心率数据
export function getST(data) {
	return request({
		url: '/cloud/ecgRecord/getST',
		method: 'post',
		data
	})
}

// 个人监护-获取qt面板的心率数据
export function getQT(data) {
	return request({
		url: '/cloud/ecgRecord/getQT',
		method: 'post',
		data
	})
}

// 个人监护-获取数据
export function getBeatForm(data) {
	return request({
		url: '/cloud/ecgRecord/getBeatData',
		method: 'post',
		data
	})
}

// st记录-获取表格数据
export function getSTRecordData(data) {
	return request({
		url: '/cloud/dynamicReport/getSTRecordData',
		method: 'post',
		data
	})
}

/* 事件浏览相关API  根据筛选条件获取对应苏朋友病人信息将其以全部展示出来
对应接口为 获取心电消息分页列表
*/
export function getPageMessage(data) {
	return request({
		url: '/cloud/ecgMsg/getPageCollectMessages',
		method: 'post',
		data
	})
}

export function getPageMsg(data) {
	return request({
		url: '/common/collectMessage/getCollectMessages',
		method: 'post',
		data
	})
}

/*
 * 获取阶段分析数据
 * @param {
 *  onlyId<String>: 诊断ID
 *  patientId<String>: 患者ID
 *  version<String>: 版本用于实现增量更新
 * }
 * @return {
 *  patientId: 患者ID
 *  onlyId: 诊断ID
 *  diagnoseCode<String>: 诊断结果编码
 *  version<String>
 *  startTime<String>: 开始时间
 *  endTime<String>: 结束时间
 * }
 */
export function getStageAnalysis(data) {
	return request({
		url: '/cloud/ecgStageAnalysis/getEcgStageAnalysis',
		method: 'post',
		data
	})
}
// 编辑心拍特征点、诊断结果
export function addFeatures(data) {
	return request({
		url: '/cloud/features/addFeatures',
		method: 'post',
		data
	})
}

// 获取决策树
export function getAllAlgorithmTree(data) {
	return request({
		url: '/cloud/ecgRecord/getAllAlgorithmTree',
		method: 'post',
		data
	})
}

/*
 * 获取生理多参数数据（血压、血糖、体温）
 * @param {
 *  onlyId<String>: 诊断ID
 *  patientId<String>: 患者ID
 *  register<String>: 挂号单号
 *  type<Number>: 1血糖 2血压 3体温 4血氧
 * }
 * @return {

 * }
 */
export function getPhyDatas(data) {
	return request({
		url: '/cloud/Physiological/getPhysiologicalDatas',
		method: 'post',
		data
	})
}

export function getPhyRecords(data) {
	return request({
		url: '/cloud/Physiological/getPhysiologicalRecords',
		method: 'post',
		data
	})
}

export function getPhyRecordsCount(data) {
	return request({
		url: '/cloud/Physiological/getPhysiologicalRecordsCount',
		method: 'post',
		data
	})
}

export function getSpoDatas(data) {
	return request({
		url: '/cloud/Physiological/getPhysiologicalBloodOxygenDatas',
		method: 'post',
		data
	})
}

export function getSignature(data) {
	return request({
		url: '/cloud/dynamicReport/checkSigning',
		method: 'post',
		data
	})
}

// 临时接口 模板算法参数
export function getTemplateParam(data) {
	return request({
		url: '/cloud/beatTemplate/getBeatTemplate',
		method: 'post',
		data
	})
}

// 导出心电数据 格式为dcm
export function exportEcgDcm(data) {
	return request({
		url: '/cloud/ecgRecord/uploadEcgFile',
		method: 'post',
		responseType: 'blob',
		data
	})
}
