export default { // 血氧相关
	spoReport: 'SPO Report',
	subtitle: '| Name: {name} - Gender: {gender} - Age: {age} | PID: {pid}',

	// -------- 血氧诊断报告
	reportTitle: 'Ambulatory SPO Report',
	name: 'Name',
	gender: 'Gender',
	beginTime: 'Start',
	endTime: 'End',
	department: 'Dept',
	bedNo: 'Bed/Order',
	dataSource: 'Source',
	timeLong: 'Duration',

	spoAbstract: 'SPO Summary',
	obstruction: 'Obstructive',
	central: 'Central',
	mixed: 'Mixed',
	hypopnea: 'Low Aeration',

	avgSpoTitle: 'AVG SPO',
	avgSpo: 'AVG SPO',
	basicSpo: 'Basic SPO',
	lowerSpo: 'Lowest SPO',

	oxygenDecStat: 'Oxygen minus Stat',
	oxygenDecStatus: 'Oxygen minus status',
	oxygenDecCount: 'reduction',

	spoLevelTitle: 'SPO Lv.',
	lower: 'Lower',
	spoLevel: 'SPO Lv.',
	timeUnit: 'mins(H：M：S)',

	hrStat: 'HR Stat',
	avgHr: 'Avg HR',
	maxHr: 'Max HR',
	minHr: 'Min HR',

	summary: 'Summary',
	docSign: 'Signature',
	reportTime: 'Report Time',
	trend: '{name} Trend',

	conclusion_normal: 'Normal',
	conclusion_spo_low: 'low SPO',
	conclusion_low: 'Mild hypoxia',
	conclusion_middle: 'Moderate hypoxia',
	conclusion_high: 'Severe hypoxia',

	conclusion_time: 'conclusion time between {begin} - {end}, {value}s;',
	report_view: 'view',
	report_create: 'create',
	data_lack_tip: 'no data'

}
