import i18n from '../../index'
export default { // 诊断报告相关
	pdfTitle: 'Report',
	historyTitle: 'report history',
	analysisTitle: 'ECG Analysis',
	checkItemTitle: 'project selection',
	subtitle: p => `| name: ${p.named('name')} - gender:${i18n.t(p.named('gender'))} - age:${p.named('age') || i18n.t('common.secrecy')} - register: ${p.named('register')}`,

	// 历史诊断报告列表相关

	beginDate: 'begin date',
	endDate: 'end date',
	to: 'to',
	openReportTitle: 'Double click to view the diagnostic report',

	table_order: 'order',
	table_time_create: 'create time',
	table_time_begin: 'begin time',
	table_time_end: 'end time',
	table_time_span: 'span',

	lastWeek: 'Prev week',
	lastMonth: 'Prev month',
	lastThreeMonths: 'Last 3 months',

	time_span_dhms: '{d}d{h}h{m}m{s}s',
	time_span_hms: '{h}h{m}m{s}s',
	time_span_ms: '{m}m{s}s',
	time_span_s: '{s}s'
}
