export default {
	// 登录界面
	account: '账号登录', // 账号登陆
	qrCode: '扫码登录 / 免费体验', // 二维码
	login: '登录', // 登录按钮
	switchLang: 'Switch Language', // 重置密码

	versionTip: '点击查看详情',
	versionTitle: '版本详情',

	accountTip: '请输入账号,手机号或电子邮件', // 账号输入提示
	passwordTip: '请输入您的密码', // 密码输入提示

	accountWarnTip: '用户名不能小于5位!',
	passwordWarnTip: '密码不能小于5位!',

	loginAccountErrorTip: '请输入正确账号!', // 输错账号的提示
	loginPasswordErrorTip: { // 输错密码的提示 需要根据输错次数动态合并
		front: '密码输入错误，还有',
		rear: '机会尝试'
	},

	qrCodeTip: '请使用客户端进行扫码',
	qRCodeErrorTip: '二维码逾期，点击获取新的二维码', // 二维码 过期提示

	useTerms: '使用条款', // 使用条款

	service: '服务协议', // 服务协议

	policy: 'cookie政策',

	statement: '隐私权声明', // 隐私权声明

	yasun: '岩尚科技',
	information: '企业信息',
	culture: '企业文化',

	contact: '联系我们',
	email: '邮箱: cs@yasun.net.cn',
	tel: '客服: 400-1882836',

	follow: '下载应用',

	company: '深圳市岩尚科技有限公司',
	preparation: '粤ICP备16050664号',

	success: '登录成功, 欢迎回来 ',
	background: '@/assets/picture/loginBg.jpg'
}
