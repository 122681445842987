import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user'
import ecg from '@/store/modules/ecg'
import ctrl from '@/store/modules/ctrl'
import alarm from '@/store/modules/alarm'
import dialog from '@/store/modules/dialog'
import getters from '@/store/getters'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		breadListState: [] // 面包屑列表数据
	},
	mutations: {
		breadListMutations(getters, list) {
			getters.breadListState = list
		}
	},
	actions: {
	},
	modules: {
		user,
		ecg,
		ctrl,
		alarm,
		dialog
	},
	getters
})
