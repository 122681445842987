export default {
	count: 'count',
	value: 'QT Value',
	histogram: 'QT Histogram',
	hrCorrelation: 'QT-HR Correlation',
	slope: 'slope',
	intercept: 'intercept',
	trendChart: 'QT TrendChart',
	percentage: 'QT Percentage',
	time: 'time',
	total: 'total',
	avg: 'Average',
	std: 'Standard Deviation',
	mid: 'Median',
	min: 'Min',
	max: 'Max',
	date: 'Date',
	hr: 'HR',
	discreteDegree: 'QT Discrete Degree'
}
