import i18n from '../../index'
export default { // 综合分析相关
	ecgAnalysis: 'ECG Analysis',
	basicInfos: 'Basic Infos',

	name: 'Name',
	patientId: 'PID.',
	age: 'Age',
	dept: 'Dept.',
	bed: 'Bed No.',
	gender: 'Gender',
	phone: 'Mobile',
	equip: 'Equip',
	operator: 'Operator',
	diagnostician: 'Diagnostician',
	pacer: 'Pacer',
	recordTime: 'Visit Time',
	conclusion: 'Conclusion',

	overview: 'Overview',
	start: 'Start time',
	end: 'End time',
	timeSpan: 'Time span',
	heartBeats: 'Heart beats',
	normalSinusHBs: 'Normal sinus HBs',
	Ventricular: 'Ventricular',
	Supraventricular: 'Supraventricular',
	BBB: 'BBB',
	Abnormal: 'Abnormal',
	AFPercentage: 'AF time ratio',
	pacing: 'Pacing',

	HR: 'HR',
	avgHR: 'Average HR (bpm)',
	longRR: 'LongRR Int.(>1200ms)(次)',
	shortRR: 'ShortRR Int.(<500ms)(次)',
	slowestHR: 'Slowest HR(bpm)',
	fastestHR: 'Fastest HR(bpm)',
	slowestMinAvgHR: 'Slowest Min Avg HR(bpm)',
	fastestMinAvgHR: 'Fastest Min Avg HR(bpm)',
	sinusTachycardia: 'Sinus Tachycardia(>120bpm)',
	sinusBradycardia: 'Sinus bradycardia(<60bpm)',

	HRV: 'HRV',
	SDNN: 'SDNN(ms)',
	SDNNI: 'SDNN INDEX(ms)',
	SDANN: 'SDANN(ms)',
	RMSSD: 'RMSSD(ms)',
	PNN50: 'PNN50(ts)',
	triAngularIndex: 'Tri-Angular Index',
	FreqHRV: 'Freq.HRV',
	TP: 'TP',
	ULF: 'ULF',
	VLF: 'VLF',
	LF: 'LF',
	HF: 'HF',
	LF_HF: 'LF:HF',

	VRH: 'VRH',
	single: 'Single',
	double: 'Double',
	bigeminy: 'Bigeminy',
	trigeminy: 'Trigeminy',
	keepingVPBs: 'Keeping VPBs(ts)',
	longestVPBs: 'Longest VPBs(ts)',
	fastestKeepVPB: 'Fastest Keep VPB(ts)',
	slowestKeepVPB: 'Slowest Keep VPB(ts)',

	SVR: 'SVR',
	keepingSVT: 'Keeping SVT(ts)',
	longestSVT: 'Longest SVT(ts)',
	fastestSVT: 'Fastest SVT(ts)',
	AF: 'AF(ts)',

	QT: 'QT',
	maxQT: 'Max QT(ms)',
	maxQTc: 'Max QTc(ms)',
	avgQT: 'Avg QT(ms)',
	avgQTc: 'Avg QTc(ms)',

	Arrest: 'Arrest',
	ArrestOver2s: 'Arrest > 2s(ts)',
	longestArrest: 'Longest arrest(ms)',

	totalChangedMins: 'Total Mins Changed',
	maxIncrease: 'Max Increase',
	maxDecrease: 'Max Decrease',

	genReport: 'Generate Report',
	specimensManagement: 'Specimens Management',

	ECGEvents: 'ECG Events',
	ECGModules: 'ECG Templates',
	// HRV: 'HRV',
	Lorenz: 'Lorenz',
	Remix: 'Remix',
	VLP: 'VLP',
	ALP: 'ALP',
	STAnalysis: 'ST Analysis',
	QTAnalysis: 'QT Analysis',

	placeHolder: 'Please input here',

	time: 'Time(HH:MM)',
	HBCounts: 'Heart Beat(ts)',
	V: 'V(ts)',
	S: 'S(ts)',
	ArrestCounts: 'Arrest(ts)',
	Tachycardia: 'Tachycardia',
	escape: 'Escape',
	avg: 'AVG.',
	max: 'MAX',
	min: 'MIN',
	total: 'TOTAL',
	percent: '%',
	SVT: 'SVT',
	IVT: 'IVT',
	keepingTimes: 'duration',
	subtitle: p => `| Name:${p.named('name')} - Gender:${i18n.t(p.named('gender'))} - Age:${p.named('age') || i18n.t('common.secrecy')} - Register: ${p.named('register')} - Equip Model: ${p.named('equipModel')}`,
	Spectrum: 'Spectrum analysis'
}
