export default { // 个人监护相关

	measuring: '测量中',
	equipOffline: '设备离线',

	/** bsChart */

	bsChartTitle: '血液葡萄糖浓度(mmol/L)',
	expandTemp: '展开体温图',

	expandBs: '展开血糖图',
	expandResp: '展开呼吸频率图',

	expandSpo: '展开血氧浓度图',

	spoPdf: '诊断报告',

	expandEcg: '展开心电图',

	/** single->panel */

	viewDetail: '查看详情',
	date: '日期',
	time: '时间',

	ecgTemplate: '心电模板',

	interval: '间期',

	axis: '心电轴分析',
	axisTitle: '点击显示心电轴分析',

	axisDeviateRight: '电轴右偏',
	axisDeviateNormal: '电轴正常',
	axisDeviateLeft: '电轴左偏',

	hrTrend: '心率趋势图',
	realEcgTime: '实时心电时间',
	ecgTime: '心电时间',
	getDataTime: '获取数据时间',

	equipType: '设备型号',

	age: '年龄',

	pid: '用户编号',
	leads: '检测项目',

	respChart: '呼吸曲线图',

	back2Batch: '返回至集群监护页面'
}
