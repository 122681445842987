export default { // 个人监护相关

	measuring: 'measuring',
	equipOffline: 'offline',

	/** bsChart */

	bsChartTitle: 'Blood Glucose Levels(mmol/L)',
	expandTemp: 'Expand temperature chart',

	expandBs: 'Expanded blood glucose chart',
	expandResp: 'Expanded respiratory rate chart',

	expandSpo: 'Expand blood oxygen concentration chart',

	spoPdf: 'Report',

	expandEcg: 'Expand ECG',

	/** single->panel */

	viewDetail: 'Detail',
	date: 'Date',
	time: 'Time',

	ecgTemplate: 'ECG Template',

	interval: 'Interval',

	axis: 'ECG axis',
	axisTitle: 'click to view ECG axis analysis',

	axisDeviateRight: 'axis right',
	axisDeviateNormal: 'axis normal',
	axisDeviateLeft: 'axis left',

	hrTrend: 'HR trend',
	realEcgTime: 'Real-time ECG',
	ecgTime: 'ECG time',
	getDataTime: 'Data time',

	equipType: 'Equip Type',

	age: 'Age',

	pid: 'PID',
	leads: 'Project',

	respChart: 'Breathing Chart',
	back2Batch: 'Back to public ward page'
}
