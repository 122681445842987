export default { // 检测项目选择相关

	selector_title: 'project selection',

	input_order_placeholder: 'Please enter the order',
	input_name_placeholder: 'Please enter the proj name',

	totalCost: 'Total project fees',
	moneyLabel: '￥',

	sign_scan_tip: 'Please use mobile scan the QR code to sign',
	sign_confirm_tip: 'Please make sure the signature',
	sign_resign: 'Re-sign',

	table_orderNo: 'Project No.',
	table_item: 'Project',
	table_item_description: 'Project description',
	table_company_level: 'Level',
	table_price: 'Unit price',
	table_amount: 'Quantity',
	table_unit: 'unit',
	table_cost_source: 'Price source',
	table_total_cost: 'Amount'
}
