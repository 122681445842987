
export default { // 特征点编辑相关

	tForm: 'T波形态',
	pForm: 'P波形态',
	diag: '诊断结果',

	addBeat: '添加心拍',
	deleteBeat: '删除心拍',

	editFeature: '编辑特征点',
	nextBeat: '下一个心拍',
	lastBeat: '上一个心拍',

	addFeature: '添加特征点',

	removeBeatTip: '确定是否删除时间为{time}的心拍?',

	edit_succeed: '编辑成功',
	add_succeed: '添加成功',

	saveNewBeatTip: '请先保存当前新添加心拍',

	currentBeatIsFirst: '该心拍已为第一条心拍',
	currentBeatIsLast: '该心拍已为最后一条心拍',

	currentLead: '当前导联',

	/** propertyTable**/

	table_time: '心拍时间',

	table_hr: '当前心率',

	table_average_hr: '平均心率(min)',
	table_pp_range: 'PP(n)间期',
	table_pp_1_range: 'PP(n+1)间期',

	table_rr_range: 'RR(n)间期',
	table_rr_1_range: 'RR(n+1)间期',

	table_pr_range: 'PR间期',

	table_qrs_range: 'QRS间期',

	table_qt_range: 'QT间期',

	table_st_volt: 'ST段电压',

	table_st_form: 'ST段形态',

	table_st_slope: 'ST段斜率',
	table_left_ventricular_voltage: '左室高电压',

	table_equipotential_voltage: '等位线电压',

	noBeat: '该时间点无心拍!',
	noData: '该时间点无数据',

	selection_positive: '正向',
	selection_negative: '负向',
	selection_null: '无',

	selection_p_positive: '正向P波',
	selection_p_negative: '负向P波',

	selection_t_double_top: '双向T波(上往下)',
	selection_t_double_bottom: '双向T波(下往上)',
	selection_t_positive: '正向T波',
	selection_t_negative: '负向T波',

	selection_t_double_top_a: '双向(上往下)',
	selection_t_double_bottom_a: '双向(下往上)',
	selection_t_positive_a: '正向',
	selection_t_negative_a: '负向',
	// subtitle: p => ` |病历号: ${p.named('pid')} - 诊断号：${p.named('onlyId')}`,
	subtitle: '|病历号: {pid} - 诊断号: {onlyId}'
}

