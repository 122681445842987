export default {
	title: '重置密码',

	userLabel: '用户名',
	userPlaceholder: '请输入登录用户名',

	codeLabel: '验证码',
	codePlaceholder: '请输入验证码',
	getCode: '获取验证码',
	resend: 's后重新发送',
	verify: '验证',
	codePicTip: '向右滑获取验证码',

	newPassword: '新密码',
	againPassword: '确认密码',
	passwordPlaceholder: '请输入密码',
	langPlaceholder: '切换语言',

	cancel: '取消',
	sure: '确认',

	m: '手机',
	e: '邮箱',

	editSuccess: '编辑成功',
	editFail: '编辑失败',

	inputUsernameTip: '请输入用户名',
	errorUsernameTip: '请输入5-32位由英文字符和数字组成的用户名',

	inputPswTip: '请输入密码',
	errorPswTip: '请输入6-32位由英文字符和数字组成的密码',

	inputAgPswTip: '请再次输入密码',
	errorAgPswTip: '两次输入密码不一致!',

	inputCodeTip: '请输入验证码',
	verSuccess: '验证成功'
}
