// import Vue from 'vue'
import Cookies from 'js-cookie'
const defaultLang = () => Cookies.get('lang') || 'zh_CN'
const ctrl = {
	state: () => ({
		navBtns: [],
		dialogs: [],
		zIndex: 100,
		lang: defaultLang(),
		definition: false // definition窗口 是否已经开启
	}),
	mutations: {
		REG_DIALOG(state, id) {
			state.dialogs.push(id)
			state.zIndex++
		},
		REMOVE_DIALOG(state, id) {
			const i = state.dialogs.indexOf(id)
			if (i > -1) {
				state.dialogs.splice(i, 1)
				state.zIndex--
			}
		},
		UPDATE_NAV_BTNS(state, data) {
			state.navBtns = data
		},
		Z_INCREASE(state) {
			state.zIndex++
		},
		Z_DESCREASE(state) {
			state.zIndex--
		},
		SET_DEFINITION(state, payload) {
			state.definition = payload
		},
		SET_LANGUAGE(state, lang) {
			Cookies.set('lang', lang)
			state.lang = lang
		}
	},
	actions: {
		AddDialog({ commit }, id) {
			commit('REG_DIALOG', id)
		},
		RemoveDialog({ commit }, id) {
			commit('REMOVE_DIALOG', id)
		},
		SetNavBtns({ commit }, btnList) {
			commit('UPDATE_NAV_BTNS', btnList)
		},
		ZIncrease({ commit }) {
			commit('Z_INCREASE')
		},
		ZDecrease({ commit }) {
			commit('Z_DESCREASE')
		},
		setDefinition({ commit }, payload) {
			commit('SET_DEFINITION', payload)
		},
		setLanguage({ commit }, lang) {
			commit('SET_LANGUAGE', lang)
		}
	},
	getters: {
		navBarBtns: state => state.navBtns,
		lang: state => state.lang,
		z: state => state.zIndex,
		definition: state => state.definition
	}
}

export default ctrl
