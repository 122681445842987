export default {
	// 登录界面
	account: 'Account', // 账号登陆
	qrCode: 'QRCode / TestFree', // 二维码
	login: 'Sign up', // 登录按钮
	switchLang: '切换语言', // 重置密码

	versionTip: 'Click here to view details',
	versionTitle: 'Version detail',

	accountTip: 'Please input account, mobile or email', // 账号输入提示
	passwordTip: 'Please input password', // 密码输入提示

	accountWarnTip: 'Account cannot be less than 5 characters!',
	passwordWarnTip: 'Password cannot be less than 5 characters!',

	loginAccountErrorTip: 'Please input an accurate account!', // 输错账号的提示
	loginPasswordErrorTip: { // 输错密码的提示 需要根据输错次数动态合并
		front: 'Wrong password!You have',
		rear: 'times more to try'
	},

	qrCodeTip: 'Please use app to scan this QRCode',
	qRCodeErrorTip: 'Invalid QRCode! click here to gain a new one', // 二维码 过期提示

	useTerms: 'use terms', // 使用条款

	service: 'service protocol', // 服务协议

	policy: 'cookie policy',

	statement: 'privacy statement', // 隐私权声明

	yasun: 'Yasun technology',
	information: 'company information',
	culture: 'company culture',

	contact: 'Contact us',
	email: 'email: cs@yasun.net.cn',
	tel: 'tel: 400-1882836',

	follow: 'Get App',

	company: 'Shenzhen Yasun Technology Co., Ltd',
	preparation: 'Guangdong ICP Preparation No. 16050664',

	success: 'Login successfully! Welcome back, ',
	background: '@/assets/picture/loginBg.jpg'
}
