import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const langFields = require.context('./lang', false, /\.js$/)

const regExp = /\.\/([^\.\/]+)\.([^\.]+)$/ // 正则用于匹配 ./en.js中的'en'

const messages = {} // 声明一个数据模型，对应i18n中的message属性

langFields.keys().forEach(key => {
	const prop = regExp.exec(key)[1] // 正则匹配en|zh这样的值
	// messages[prop]相当于 messages['en'] = {table:{...}}
	messages[prop] = langFields(key).default
})

// const locale = ((navigator.language ? navigator.language : (navigator).userLanguage) || "zh"
// ).toLowerCase();
const locale = localStorage.getItem('i18n') || 'zh_CN'

const _VueI18n = new VueI18n({
	locale,
	messages
})

// ElementLocale.i18n((key, value) => _VueI18n.t(key, value))

export default _VueI18n
