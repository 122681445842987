export default {
	count: '数量',
	value: 'QT值',
	histogram: 'QT直方图',
	hrCorrelation: 'QT心率相关系数',
	slope: '斜率',
	intercept: '截距',
	trendChart: 'QT趋势图',
	percentage: 'QT百分比',
	time: '时间',
	total: '总数',
	avg: '均值',
	std: '标准差',
	mid: '中位数',
	min: '最小值',
	max: '最大值',
	date: '日期',
	hr: '心率',
	discreteDegree: 'QT间期离散度'
}
