export default { // 异常事件相关

	N: '正常心拍',
	A: '房性早搏',
	V: '室性早搏',
	J: '交界性早搏',
	L: '左束支阻滞',
	R: '右束支阻滞',
	e: '房性逸博',
	E: '室性逸博',
	j: '交界性逸博',
	F: '融合心搏',
	'/': '起搏心搏',
	'&': '未识别心搏',
	'?': '质量差的心拍',
	SAR: '窦性心律不齐',
	STA: '窦性心动过速',
	SBR: '窦性心动过缓',
	SP: '窦性停搏',
	S: '室上性早搏',
	BI: '一度房室传导',
	BII: '二度房室传导',
	TPS: '典型预激综合征',
	MPS: 'Mahaim预激综合征',
	LGL: '正常QRS综合征',

	// '1': '导联脱落',
	// '2': 'TF卡异常',
	// '3': '设备已连接',
	// '4': '主动断开设备',
	// '5': '设备超出连接范围',
	// '6': '设备主动关机',
	// '7': '设备低电量自动关机',
	// '8': '手机低电量关机',
	AFIB: '房颤',
	AFL: '房扑',
	VFL: '室扑&室颤',
	X: '被删除的心拍',
	// Pause:"停搏",
	// MYI:"心肌缺血",
	// MII:"心肌梗死",
	// /**
	//  * 二级警报
	//  */
	// E:"室性逸博",
	// L:"左束支阻滞",
	// R:"右束支阻滞",
	// A:"室上性早搏",
	// V:"室性早搏",
	// J:"交界性早搏鉴",
	VT: '室性心律过速',
	// VER:"室性逸搏心律",
	// JER:"交界性逸搏心律",
	VD: '室性成对',
	B: '二联律室性早搏',
	T: '三联律室性早搏',
	VQU: '四联律室性早搏',
	JB: '二联律交界性早搏',
	JT: '三联律交界性早搏',
	VS: '室性短阵',
	// Jqu:"四联律交界性早搏",
	// S:"室上性早搏",
	// SP:"窦性停搏",
	// /**
	//  * 三级警报
	//  */
	// j:"交界性逸博",
	// e:"房性逸博",
	// SBR:"窦性心律过缓",
	// STA:"窦性心动过速",
	// SAR:"窦性心律不齐",
	SVTA: '室上性心动过速',
	AS: '房性短阵',
	AD: '房性成对',
	AB: '二联律房性早搏',
	AT: '三联律房性早搏',
	AQu: '四联律房性早搏',
	IVR: '室性逸搏心律',
	NOD: '交界性逸搏心律',

	// /**
	//  * 四级警报
	//  */
	// normol:"正常",
	// 脱落相关
	leadOff: '导联脱落',
	MYI: '心肌缺血',
	MII: '心肌梗死',
	VH: '心室肥大',
	AH: '心房肥大',
	SAS: '睡眠呼吸暂停综合征',

	// 设备日志相关

	D_0: '设备开机',
	D_1: '设备自动关机',
	D_2: '设备主动关机',
	D_3_0: '设备TF卡异常',
	D_3_1: '设备TF卡正常',
	D_4: '设备导联脱落',
	D_5_1: 'TF卡异常',
	D_5_2: '创建当前文件失败',
	D_5_3: '打开历史文件失败',
	D_5_4: '关闭当前文件失败',
	D_5_5: '关闭历史文件失败',
	D_5_6: '创建心电文件头时移动文件指针失败',
	D_5_7: '保存心电数据时移动文件指针失败',
	D_5_8: '补发当前文件时移动文件指针失败',
	D_5_9: '补发历史文件时移动文件指针失败',
	D_5_10: '补发当前文件时读心电数据文件失败',
	D_5_11: '补发历史文件时读心电数据文件失败',
	D_5_12: '创建心电文件头时写文件失败',
	D_5_13: '写入当前心电数据时写文件失败',
	D_5_14: '创建心电文件头时同步文件失败',
	D_5_15: '补发当前文件时同步文件失败',
	D_5_16: '文件写入太慢',
	D_5_17: '文件已关闭',
	D_5_18: '读文件列表失败',
	D_5_30: '补发数据位置超出文件长度',
	D_5_31: '分配内存失败或内存溢出',
	D_5_32: '操作系统内核访问出错',
	D_5_33: '向队列放入准备写TF卡的数据时失败（可能由于队列满或其它原因）',
	D_5_34: '从队列提取准备写TF卡的数据时失败',
	D_5_40: '应用程序逻辑错误',
	D_12: '设备低电量自动关机',
	D_13: '设备剩余电量',
	B_6: '蓝牙已链接',
	B_7: '蓝牙自动断开',
	B_8: '蓝牙主动断开',
	A_9: 'App低电量停止监护',
	A_10: '紧急求救',
	A_11_0: '当前记录开始补发段落',
	A_11_1: '当前记录补发段落已完成',
	A_11_2: '当前记录补发丢包数据',
	A_11_3: '手动补发数据',
	A_11_4: '补发中断'
}
