import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout/main/Main.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		return err
	})
}

const routes = [
	// 权限验证白名单页面 在permission.js 的 whiteList 中管理
	{
		path: '/login', meta: { title: 'router.login' },
		component: () => import('@/views/passport/login/Index'),
		hidden: true
	},
	{
		path: '/passport/register', meta: { title: 'router.register' },
		component: () => import('@/views/passport/register'),
		hidden: true
	},
	{
		path: '/passport/reset', meta: { title: 'router.reset' },
		component: () => import('@/views/passport/resetPassword'),
		hidden: true
	},
	{
		path: '/404',
		component: () => import('@/views/error/404'),
		hidden: true
	},
	// 有权限验证
	{
		path: '/',
		component: Layout,
		redirect: '/batchWard',
		children: [
			{
				path: 'batchWard',
				name: 'BatchWard',
				meta: { title: 'router.batch', icon: 'batchWard', noCache: true },
				component: () => import('@/views/ward/batch/Index')
			},
			{
				path: 'singleWard',
				name: 'SingleWard',
				meta: { title: 'router.single', icon: 'singleWard', noCache: true },
				component: () => import('@/views/ward/single/Index')
			}
		],
		hidden: true
	},
	{
		path: '*',
		redirect: '/404',
		hidden: true
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
