// import Cookies from 'js-cookie'
// import store from '@/store'
import { sm3 } from 'hash-wasm'
// import { login, getInfo } from '@/api/login'

const TokenKey = 'YasunTechnology'
const key = encode(TokenKey)
export function encode(value) {
	return window.btoa(window.encodeURIComponent(value))
}

export function decode(value) {
	return window.decodeURIComponent(window.atob(value))
}
class CookieUtil {
	get() {
		const value = localStorage.getItem(key) || ''
		return decode(value)
	}
	set(token) {
		const value = encode(token)
		return localStorage.setItem(key, value)
	}
	remove() {
		try {
			localStorage.removeItem(key)
			return true
		} catch (e) {
			return false
		}
	}
}
const Cookies = new CookieUtil()

export function getToken() {
	return Cookies.get()
}

export function setToken(token) {
	return Cookies.set(token)
}

export function removeToken() {
	return Cookies.remove()
}

export function generateToken() {
	const timestamp = new Date().getTime()
	const random = Math.random() * timestamp
	const key = 'YasunTechnology' + random
	return sm3(key)
}

// export async function tryLogin(userInfo) {
// 	const { commit } = store
// 	const username = userInfo.username.trim()
// 	const pwd = await sm3(userInfo.password)
// 	const { data } = await login(username, pwd)
// 	const { token, userType } = data
// 	setToken(token)
// 	commit('SET_TOKEN', token)
// 	commit('SET_NAME', data.loginId || username)
// 	getUserInfo()
// 	return userType
// }

// export async function getUserInfo() {
// 	const { commit } = store
// 	const { data } = await getInfo()
// 	const { userName, avatar, companyId, companyName, tenantId } = data
// 	if (tenantId) { // 验证功能权限
// 		const role = tenantId.indexOf('01_0000') >= 0
// 		commit('SET_ROLES', role ? ['guest'] : ['user'])
// 	}
// 	commit('SET_NAME', userName)
// 	commit('SET_AVATAR', avatar)
// 	commit('SET_COMPANY_ID', companyId)
// 	commit('SET_COMPANY_NAME', companyName)
// 	return
// }
