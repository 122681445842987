const getters = {
	mute: 'getVoiceMute',
	volume: 'getVoiceVolume',
	speed: 'getVoiceSpeed',
	tone: 'getVoiceTone',
	isBroadcasting: 'getVoiceBroadcasting'
}
// eventA: 'getEventA',
// eventV: 'getEventV',
// eventJ: 'getEventJ',
// eventL: 'getEventL',
// eventR: 'getEventR',
// evente: 'getEvente',
// eventE: 'getEventE',
// eventj: 'getEventj'
const levelMap = {
	'1': 'levelOne',
	'2': 'levelTwo',
	'3': 'levelThree',
	'4': 'levelFour'
}

export { getters, levelMap }
