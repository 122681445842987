export default {
	selectByMouse: '使用鼠标圈选数据',
	detail: '放大图',
	normal: '还原图',
	zoomIn: '放大',
	zoomOut: '缩小',
	reset: '重置',
	filter: '筛选',
	dragOn: '开启拖拽',
	dragOff: '关闭拖拽',
	switchLeads: '切换导联',
	gain: '增益',
	viewAllHeartBeats: '点击查看全部心拍',
	selectAll: '全选',
	dblClickViewDetail: '双击查看详情',
	time: '时间',
	total: '总计',
	detailTitle: '时间： {start} - {end} | 总计： {total}小时'
}
