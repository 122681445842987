import { sm3 } from 'hash-wasm'
import Vue from 'vue'

async function genKey(params) {
	const { title, name } = params
	const json = JSON.stringify(title) + name
	const key = await sm3(json)
	return key
}
const Dialog = {
	state: () => ({
		dialogs: {},
		dialogList: [],
		dialogState: {},
		minimizedList: []
	}),
	mutations: {},
	actions: {
		// 添加窗口
		async createDialog({ state, dispatch }, options) {
			let key = options.__uid__
			if (!key || key === '') {
				key = await genKey(options)
				options.__uid__ = key
			} else if (typeof key !== 'string') {
				throw new Error('__uid__ must be a string')
			}
			const dialog = state.dialogs[key]
			if (!dialog) {
				state.dialogList.push(key)
				Vue.set(state.dialogs, key, options)
			} else {
				const idx = state.minimizedList.indexOf(key)
				state.minimizedList.splice(idx, 1)
				dispatch('ToTop', key)
			}
			return key
		},
		updateDialogState({ state }, status) {
			const { key, position } = status
			Vue.set(state.dialogState, key, position)
		},
		// 销毁窗口
		DestroyDialog({ state }, key) {
			const index = state.dialogList.indexOf(key)
			if (index < 0) return
			Vue.delete(state.dialogs, key)
			state.dialogList.splice(index, 1)
		},
		// 最小化/还原窗口
		ToggleMinimizeDialog({ state, dispatch }, key) {
			const params = state.dialogState[key]
			if (!params) return
			const idx = state.minimizedList.indexOf(key)
			if (idx < 0) {
				const index = state.dialogList.indexOf(key)
				state.dialogList.splice(index, 1)
				state.dialogList.unshift(key)
				state.minimizedList.push(key)
			} else {
				state.minimizedList.splice(idx, 1)
				dispatch('ToTop', key)
			}
		},
		ToTop({ state }, key) {
			const index = state.dialogList.indexOf(key)
			if (index < 0) return
			state.dialogList.splice(index, 1)
			state.dialogList.push(key)
		}
	},
	getters: {
		dialogs: state => state.dialogs,
		dialogList: state => state.dialogList,
		dialogState: state => state.dialogState,
		isMinimized: state => key => {
			return state.minimizedList.indexOf(key) > -1
		}
	}
}

export default Dialog
