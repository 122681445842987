import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import { getToken } from '@/utils/auth' // 验权

const whiteList = ['/login', '/passport/register', '/passport/reset', '/404'] // 不重定向白名单
router.beforeEach((to, from, next) => {
	NProgress.start()
	// to.meta && to.meta.title && (document.title = to.meta.title)
	if (getToken()) {
		if (store.getters.name === '') {
			store.dispatch('GetLocalName')
		}
		if (to.path === '/login') {
			next({ path: '/' })
			NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
		} else {
			next()
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			next()
		} else {
			next('/login')
			NProgress.done()
		}
	}
})
router.afterEach((to, from, next) => {
	NProgress.done() // 结束Progress
	setTimeout(() => {
		if (to && to.meta && to.meta.title) {
			window.document.title = router.app.$t(to.meta.title)
		}
	}, 0)
})
