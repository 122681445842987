export default { // 心电向量分析相关

	vector_title: 'VCG',
	subtitle: ' |register： {pid} ',
	unsupportedWebGL: 'Your browser currently does not support WebGL, please change your browser or try to turn off/enable hardware acceleration and then refresh the current page.',

	surface_frontal: 'frontal-surface(X-Y)',

	surface_transverse_plane: 'transverse plane(X-Z)',

	surface_right: 'right side(Z-Y)'
}
