export default {
	// 心率变异性分析相关

	time_start: 'Start Time',
	time_end: 'End Time',
	time_range: 'Time Span',
	time_stage: 'Time Stage',
	time_to: 'to',
	// timeDomain

	timeDomain_averageRR: 'Average RR',
	timeDomain_averageHr: 'Average HR',
	timeDomain_minHr: 'Min HR',
	timeDomain_maxHr: 'Max HR',

	timeDomain_tri_index: 'RR Tri-Index',

	nnTrend_chartTitle: 'NN TrendChart',

	nnHis_chartTitle: 'NN Histogram',
	nnHis_yTitle: 'HeartBeats',

	dfa_chartTitle: 'DFA TrendChart',

	freq_xTitle: 'Frequency',
	freq_chartTitle: 'NN Int.FrequencyChart',

	scatter_chartTitle: 'NN Int.1stQuadrantChart',

	scatter_difference_chartTitle: 'NN Int.DifferenceScatterChart',

	/** timeSetting */
	selectPlaceholder: 'Please select',
	time_stage_list: 'Time Stage List',
	time_content: 'Content',

	/** freq-domain-table 频域分析*/

	freq_table_title: 'Freq.Domain Results',

	freq_table_label_param: 'Parameters',

	freq_table_label_unit: 'Unit',

	freq_peak: 'Maximum Freq.',
	freq_power: 'Power',
	freq_totalPower: 'Total Power',

	/** nonlinear-table 非线性分析 */

	nonlinear_table_title: 'Nonlinear results',

	nonlinear_table_lorenz: 'LorenzScatterPlot',

	nonlinear_table_ap_entropy: 'ApEn',

	nonlinear_table_samp_entropy: 'SampEn',

	nonlinear_table_dfa: 'DeTrended fluctuation',

	nonlinear_table_short_difference: 'ShortTerm Difference',
	nonlinear_table_long_difference: 'LongTerm Difference',
	nonlinear_table_relative: 'Correlation coefficient',

	/**
     * time-domain-table 时域分析结果
     */

	time_table_title: 'TimeDomain Results',

	time_setting_title: 'Time Settings',
	time_setting_subtitle: ' | PID: {pid} ',
	sleep_table_title: '睡眠呼吸暂停综合征分析结果',
	sleep_table_day: '白天',
	sleep_table_night: '夜晚',
	sleep_table_time: '时域指标',
	sleep_table_freq: '频域指标',
	sleep_sdnn_index: 'SDNN指数',
	sleep_table_threshold: '阈值',
	sleep_table_danger: '危险',
	sleep_table_other: '其他参数',
	sleep_table_variation: '睡眠心率变化大于15bpm'
}
