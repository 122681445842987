export default {
	// ST分析相关
	empty: 'No Data',
	conclusionText: ctx => {
		// console.log(ctx, ctx.named('lead'))
		const text = ''
		// ctx.forEach(p => {
		//   text += `${p.named('lead')}导联：最大值：${p.named('max')}mV, 最小值：${p.named('min')}mV, 中位数：${p.named('mid')}mV, 标准差：${p.named('std')}mV`
		// })
		return text
	},
	order: 'Order',
	time: 'Time',
	continueTime: 'Duration',
	HR5minPrev: 'Prev 5m HR',
	onTimeHR: 'happening HR',
	maxStHR: 'HR on Maximum ST',
	maxHR: 'Max HR',
	st: 'ST Segment',
	stSlope: 'ST Slope',
	ischemiaLoad: 'Ischemia Load',
	updateTip: 'Your edited conclusion is different from the newest conclusion. Do you want to update it?',
	conclusion: 'Lead {lead}：Maximum：{max}mv,Minimum：{min}mv，Median: {median}mv，Standard deviation: {sd}.\n',
	update: 'update'
}
