export default { // 血压报告相关
	nibpReport: 'Blood pressure report',
	// title: '动态血压报告单',
	title: 'nibpPdf',

	name: 'Name',
	birth: 'Birth',
	recordNo: 'PID',
	age: 'Age',
	gender: 'Gender',
	diagnostician: 'Diagnostic Physicians',
	telephone: 'Phone',
	treatmentDate: 'visit time',
	department: 'Dept/Bed',
	remark: 'Note',

	ABPMSummary: 'ABPM Summary',
	period1: 'cyclical',
	gross1: 'Total',
	day1: 'day',
	night1: 'night',
	morning1: 'morning',
	timeRange1: 'time range',
	sbpUpperLimit1: 'SBP/DBP Upper limit',
	sbpWA1: 'SBP/DBP Weighted AVG',
	pulseWA1: 'Pulse-weighted AVG',
	sbpMbpDayNight1: 'Systolic/diastolic daytime/nighttime index%',
	successRate: 'Success rate',
	morningSurge1: 'morning surge',

	highPressureSummary: 'High Voltage Summary',
	MapWA: 'MAP Weighted AVG',
	ppWA: 'PP Weighted AVG',
	sbpLoad: 'SBP/DBP load mmHg',
	sbpMbpDayNightAvgDev: 'Systolic/diastolic pressure Daytime/nighttime Mean difference',
	period2: 'cyclical',
	gross2: 'Total',
	day2: 'day',
	night2: 'night',
	morning2: 'morning',
	timeRange2: 'time range',
	sbpUpperLimit2: 'SBP/DBP upper limit',
	sbpWA2: 'SBP/DBP  Weighted AVG',
	pulseWA2: 'Pulse-weighted AVG',
	sbpMbpDayNight2: 'Systolic/diastolic daytime/nighttime index%',
	morningSurge2: 'morning surge',

	nibpChart: 'Blood pressure trend graph',

	reportTime: 'Report time',
	docSign: 'Doctor\'s signature',
	conclusion: 'conclusion'
}
