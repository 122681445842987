<template>
	<div class="volume-setting">
		<div class="layout">
			<span class="label">{{ $t('alarmList.voice_volume') }}:<i
				class="myIcon-voice iconfont"
				:class="mute ? 'icon-volume-mute' : 'icon-volume-high'"
				@click.stop="muteVoice"
			/></span>
			<el-slider
				v-model="volumeC"
				class="my-slider"
				:show-tooltip="false"
				@change="handleVolume"
			/>
			<span class="value">{{ volume }}</span>
		</div>
		<div class="layout">
			<span class="tone-text label">{{ $t('alarmList.voice_tone') }}:</span>
			<el-slider
				v-model="toneC"
				class="my-slider"
				:show-tooltip="false"
				@change="handleTone"
			/>
			<span class="value">{{ tone }}</span>
		</div>
		<div class="layout">
			<span class="label">{{ $t('alarmList.voice_speed') }}:</span>
			<el-slider
				v-model="speedC"
				class="my-slider"
				:show-tooltip="false"
				@change="handleSpeed"
			/>
			<span class="value">{{ speed }}</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			volumeC: 0,
			speedC: 0,
			toneC: 0
		}
	},
	computed: {
		...mapGetters({
			mute: 'getVoiceMute',
			volume: 'getVoiceVolume',
			speed: 'getVoiceSpeed',
			tone: 'getVoiceTone'
		})
	},
	watch: {
		volume(newVal) {
			this.volumeC = newVal
		},
		speed(newVal) {
			this.speedC = newVal
		},
		tone(newVal) {
			this.toneC = newVal
		}
	},
	mounted() {
		this.volumeC = this.volume
		this.speedC = this.speed
		this.toneC = this.tone
	},
	beforeDestroy() {},
	methods: {
		muteVoice() {
			this.$store.dispatch('setVoiceMute', !this.mute)
		},
		handleVolume(value) {
			this.$store.dispatch('setVoiceVolume', value)
			if (!value) {
				this.$store.dispatch('setVoiceMute', true)
			} else {
				this.$store.dispatch('setVoiceMute', false)
			}
		},
		handleTone(value) {
			this.$store.dispatch('setVoiceTone', value)
		},
		handleSpeed(value) {
			this.$store.dispatch('setVoiceSpeed', value)
		}
	}
}
</script>

<style lang="scss" scoped>
div,
span {
	display: flex;
	align-items: center;
}
.volume-setting {
	display: flex;
	position: absolute;
	flex-direction: column;

	padding: 0.5em 1em;
	box-sizing: border-box;

	width: auto;
	height: 135px;
	top: -140px;
	right: 5px;

	background: #222;
	border: 2px outset #999b;
	border-radius: 4px;

	.layout {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		font-size: 14px;

		.label {
			position: relative;
			flex-grow: 1;
			justify-content: flex-start;
			margin-right: 0.6em;
			text-align: left;
		}

		.value {
			padding: 4px;
			border: 1px solid #999b;
			text-align: center;
		}

		.my-slider {
			width: 10em;
			margin: 0 1em;
		}

		.myIcon-voice {
			position: absolute;
			right: -1.3em;
		}
	}
}
</style>
