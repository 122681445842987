export default {
	empty: 'No template data',
	msgNoData: 'No data in this time period',
	msgFirstBeat: 'This is the first beat',
	msgLastBeat: 'This is the last beat',
	beatsCount: 'Beats count',
	selectAll: 'Select all',
	save: 'Save current changes',
	lead: 'Lead',
	manualEditTime: 'Manual edit time',

	HBTime: 'time',
	currHr: 'Current HR',
	diag: 'Diagnosis',
	avgRR: 'Average RR',

	PpPrevInt: 'PP(n-1) interval',
	PpCurrInt: 'PP(n) interval',
	RrPrevInt: 'RR(n-1) interval',
	RrCurrInt: 'RR(n) interval',
	RRIntDiff: 'RR间期差',
	RRInt: 'RRInt.',
	PPInt: 'PPInt.',
	PMorph: 'P wave morphology',
	PTypeIs: 'P波类型为',
	PWaveInt: 'P波时限',
	PWaveIdx: 'P波指数',
	PPeak: 'P波峰值',
	RPeak: 'R波峰值',
	RInt: 'R波时限',
	PrInt: 'PR interval',
	PjInt: 'Pj间期',
	QInt: 'Q波时限',
	QqInt: 'QQ间期',
	QRSInt: 'QRS interval',
	QTInt: 'QT interval',
	SInt: 'S波时限',
	StVolt: 'ST segment voltage',
	STAmplitude: 'ST幅值',
	RAmplitude: 'R点幅值',
	SAmplitude: 'S点幅值',
	StMorph: 'ST segment morphology',
	StSlope: 'ST segment slope',
	StInt: 'ST间期',
	StType: 'ST类型',
	StSlopeCoAngle: 'ST斜率的余角',
	TMorph: 'T wave morphology',
	TPeak: 'T波峰值',
	Template: 'ECG template',
	TType: 'T波类型',

	noFeatures: 'No features data',
	timeFormatError: 'Please enter formatted time as YYYY-MM-DD HH:mm:ss',

	nodeText: 'The diagnose on Lead:{lead} of this heart beat is {diagName}({diag})',
	main: 'Main',
	similarWithNormal: '和正常窦性心律相似度',
	hasPWave: '导联P波存在',
	measureValueIs: '实测值为',
	hasHb: '导联存在',
	noHB: '导联不存在',
	valOfAlgorithm: '算法测量值',
	measureByAlgorithm: '算法测量',
	normal: '正常',
	normalAvgRR: '正常平均RR间期为',
	sinusNormals: '个正常窦性',

	negative: '倒立',
	positive: '正向',
	exist: '存在',
	notExist: '不存在',
	downUp: '先下后上',
	upDown: '先上后下',
	prevHB: '前拍',
	prevRR: '前个RR间期',
	prevHBisNormal: '前拍是正常窦性心律',
	prevRRisNotNormal: '前个RR间期不是正常窦性心律',
	diagIs: '诊断结果为',
	prevDiagIs: '前拍的诊断结果为',
	is: '为',
	not: '不是',
	RRIntIs: 'RR间期为',
	avgRRIs: '平均RR间期为',
	prevRRIs: '前个RR间期为',

	QValIs: 'Q波幅值为',
	QVal: 'Q波幅值',

	prevIsV: '前拍是室性早搏',
	prevIsNotV: '前拍不是室性早搏',

	prevIsA: '前拍是室上性室早',
	prevIsNotA: '前拍不是室上性室早',
	prevNotAorV: '前拍不是房性早搏或室上性早搏或者RR间期',
	prevNormalRRInt: '前拍正常RR间期',
	dblPWaveInRRInt: 'RR间期内有双P波',
	prevIsEOrSTA: '前拍是房性逸博或窦性心动过缓',
	prevNotEOrSTA: '前拍不是房性逸博或窦性过缓',
	noPrev: '前拍不存在',
	hasPrev: '前拍存在',

	StValLt0_1HasDeltaWave: 'ST压 < 0.1mV的有δ波',

	satisfy: '满足条件',
	dissatisfy: '不满足条件',
	satisfyOfExist: '不成立或存在',
	jumpBeatTip: '当前无心拍存在，自动跳转到最近心拍'
}
