import router from './router/router_en_WW'
import login from './login/login_en_WW'
import resetPassword from './resetPassword/resetPassword_en_WW'
import navigator from './navigator/navigator_en_WW'
import batch from './batch/batch_en_WW'
import single from './single/single_en_WW'
import record from './record/record_en_WW'
import report from './report/report_en_WW'
import common from './common/common_en_WW'
import reviewer from './reviewer/reviewer_en_WW'
import detail from './detail/detail_en_WW'
import feature from './featureCompiler/feature_compiler_en_WW'
import definition from './featureDefinition/feature_definition_en_WW'
import hrv from './hrv/hrv_en_WW'
import remix from './remix/remix_en_WW'
import vector from './vector/vector_en_WW'
import itemSelector from './selector/selector_en_WW'
import alarmList from './alarmList/alarm_list_en_WW'
import sample from './sampleCompiler/sample_compiler_en_WW'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import events from './events/events_en_WW'
import logs from './logs/logs_en_WW'
import nibp from './nibp/nibp_en_WW'
import nibpPdf from './nibpPdf/nibpPdf_en_WW'
import spo from './spo/spo_en_WW'
import analy from './analysis/analysis_en_WW'
import st from './st/st_en_WW'
import qt from './qt/qt_en_WW'
import lorenz from './lorenz/lorenz_en_WW'
import diag from './diag/diag_en_WW'
import tmp from './template/template_en_WW'

export default {
	lang: 'en',
	guest: 'Guest',
	router,
	login,
	navigator,
	resetPassword,
	...elementEnLocale,
	batch,
	single,
	record,
	report,
	common,
	reviewer,
	detail,
	feature,
	definition,
	hrv,
	remix,
	vector,
	itemSelector,
	alarmList,
	sample,
	events,
	logs,
	nibp,
	nibpPdf,
	spo,
	analy,
	st,
	qt,
	lorenz,
	diag,
	tmp
}
