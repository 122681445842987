const YsNotification = {}
YsNotification.install = function(Vue) {
	const setNotifyActions = (notify, actions = {}) => {
		notify.onshow = actions.onShow
		notify.onclick = actions.onClick
		notify.onerror = actions.onError
		notify.onclose = actions.onClose
	}
	Vue.prototype.$YsNotify = (title, content) => {
		let note
		if (!('Notification' in window)) return
		// 检查用户是否同意接受通知
		else if (Notification.permission === 'granted') {
			// If it's okay let's create a notification
			note = new Notification(title, content)
			setNotifyActions(note, content)
		} else if (Notification.permission !== 'denied') { // 否则需要向用户获取权限
			Notification.requestPermission().then(permission => {
				// 如果用户接受权限，我们就可以发起一条消息
				if (permission === 'granted') {
					note = new Notification(title, content)
					setNotifyActions(note, content)
				}
			})
		}
		// 执行到这里，说明用户已经拒绝对相关通知进行授权
	}
}

export default YsNotification
