import i18n from '../../index'
export default { // 综合分析相关
	ecgAnalysis: '心电综合分析',
	basicInfos: '基本信息',

	name: '姓名',
	patientId: '病历号',
	age: '年龄',
	dept: '科室',
	bed: '床号',
	gender: '性别',
	phone: '电话',
	equip: '记录仪',
	operator: '操作医师',
	diagnostician: '诊断医师',
	pacer: '起搏器',
	recordTime: '就诊时间',
	conclusion: '结论',

	overview: '概述',
	start: '开始时间',
	end: '结束时间',
	timeSpan: '检测时长',
	heartBeats: '心搏总数(次)',
	normalSinusHBs: '正常窦性心搏(次)',
	Ventricular: '室性心搏(次)',
	Supraventricular: '室上性心博(次)',
	BBB: '束支传导阻滞(次)',
	Abnormal: '异常心搏(次)',
	AFPercentage: '房颤/房扑占时比',
	pacing: '起搏心搏(次)',

	HR: '心率',
	avgHR: '平均心率(bpm)',
	longRR: '长RR间期(>1200ms)(次)',
	shortRR: '短RR间期(<500ms)(次)',
	slowestHR: '最慢心率(bpm)',
	fastestHR: '最快心率(bpm)',
	slowestMinAvgHR: '分钟平均最慢心率(bpm)',
	fastestMinAvgHR: '分钟平均最快心率(bpm)',
	sinusTachycardia: '窦性心动过速(>120bpm)',
	sinusBradycardia: '窦性心动过缓(<60bpm)',

	HRV: '心率变异',
	SDNN: 'SDNN(ms)',
	SDNNI: 'SDNN INDEX(ms)',
	SDANN: 'SDANN(ms)',
	RMSSD: 'RMSSD(ms)',
	PNN50: 'PNN50(次)',
	triAngularIndex: '三角指数',
	FreqHRV: '频率心率变异',
	TP: 'TP',
	ULF: 'ULF',
	VLF: 'VLF',
	LF: 'LF',
	HF: 'HF',
	LF_HF: 'LF:HF',

	VRH: '室性节律',
	single: '单发(次)',
	double: '成对(次)',
	bigeminy: '二联律(次)',
	trigeminy: '三联律(次)',
	keepingVPBs: '连续室早(次)',
	longestVPBs: '最长连续室早',
	fastestKeepVPB: '最快心率连续室早',
	slowestKeepVPB: '最慢心率连续室早',

	SVR: '室上性节律',
	keepingSVT: '连续室上速(次)',
	longestSVT: '最长室上速',
	fastestSVT: '最快室上速',
	AF: '心房颤动/心房扑动(次)',

	QT: 'QT',
	maxQT: '最大QT(ms)',
	maxQTc: '最大QTc(ms)',
	avgQT: '平均QT(ms)',
	avgQTc: '平均QTc(ms)',

	Arrest: '停搏',
	ArrestOver2s: '停搏大于2秒(次)',
	longestArrest: '最长停搏',

	totalChangedMins: '总改变分钟',
	maxIncrease: '最大抬高',
	maxDecrease: '最大压低',

	genReport: '生成诊断报告',
	specimensManagement: '心电样本管理',

	ECGEvents: '心电事件',
	ECGModules: '心电模板',

	Lorenz: '洛伦兹分析',
	Remix: '反混淆分析',
	VLP: '心室晚电位分析',
	ALP: '心房晚电位分析',
	STAnalysis: 'ST段分析',
	QTAnalysis: 'QT段分析',

	placeHolder: '请输入内容',

	time: '时间(HH:MM)',
	HBCounts: '心搏数(次)',
	V: '室性(次)',
	S: '室上性(次)',
	ArrestCounts: '停搏(次)',
	Tachycardia: '心动过缓',
	escape: '逸博',
	avg: '平均',
	max: '最大',
	min: '最小',
	total: '总数',
	percent: '百分比',
	SVT: '室上速',
	IVT: '室速',
	keepingTimes: '持续时间',
	subtitle: p => `| 姓名:${p.named('name')} - 性别:${i18n.t(p.named('gender'))} - 年龄:${p.named('age') || i18n.t('common.secrecy')} - 挂号单: ${p.named('register')} - 设备类型: ${p.named('equipModel')}`,
	Spectrum: '频谱分析'
}
