<template>
	<div class="main-navigation">
		<div class="logo" />
		<BreadcrumbBar class="breadcrumb" />
		<Timer
			v-if="expire.time"
			:expire="expire"
		/>
		<div class="nav-bar">
			<FunctionalBar>
				<span>?</span>
			</FunctionalBar>
			<div class="dropdown">
				<el-avatar
					id="user-avatar"
					size="small"
					src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
				/>
				<div class="dropdown-content">
					<span class="dropdown-item">
						<el-avatar
							style="margin-left: -6px"
							size="small"
							src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
						/>
						<span
							v-t="username || 'guest'"
							style="margin-left: 4px"
						/>
					</span>
					<span
						v-t="'navigator.logout'"
						class="dropdown-item"
						@click="logout"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FunctionalBar from './FunctionalBar'
import BreadcrumbBar from './BreadcrumbBar'

export default {
	name: 'Navigation',
	components: {
		FunctionalBar,
		BreadcrumbBar,
		Timer: () => import('./Timer')
	},
	computed: {
		username() {
			return this.$store.getters.name
		},
		expire() {
			return this.$store.getters.expireParams || {}
		}
	},
	mounted() {},
	methods: {
		logout() {
			this.$store
				.dispatch('LogOut')
				.then(() => {
					location.reload() // 为了重新实例化vue-router对象 避免bug
				})
				.catch(() => {
					location.reload()
				})
		}
	}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main-navigation {
	position: relative;
	width: 100%;
	.logo {
		flex-grow: 0;

		width: 220px;
		height: 25px;

		margin: 4px -20px 0 -5px;

		background: url('~@/assets/picture/loginLogo.webp') no-repeat center center;
		background-size: cover;

		transform: scale(0.8);
	}
}
/* 容器 <div> - 需要定位下拉内容 */
.dropdown {
	position: relative;
	display: inline-block;
}
/* 下拉内容 (默认隐藏) */
.dropdown-content {
	display: none;
	position: absolute;
	right: 0;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
/* 下拉菜单的链接 */
.dropdown-content>.dropdown-item {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	padding: 12px;
	text-decoration: none;
	/* 鼠标移上去后修改下拉菜单链接颜色 */
	&:hover {
		background-color: #f1f1f1
	}
	&:active {
		background-color: #d1d1d1
	}
}

/* 在鼠标移上去后显示下拉菜单 */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
