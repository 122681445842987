export default { // 详情图相关
	detailTitle: '心电详情图',
	subTitle: '病历号：{pid} - 时间: {start} - {end} | 总计: {total}小时',
	changeTimeTip: '输入时间不得小于心电图开始时间或大于心电图结束时间',

	logsError: '日志内容还未录入',

	idError: 'onlyId或pid不存在',

	samplePrompt: '请输入时间戳{showTime}对应的心电样本备注',
	tip: '提示',

	sampleExistInfo: '该诊断样本已存在',

	sample_add_succeed: '添加成功',
	sample_add_cancel: '取消添加心电样本',

	diag_code: '诊断编码',
	diag_result: '诊断结果',
	diag_time: '诊断时间',

	time_gt_error: '请求时间大于该事件结束时间',
	time_lt_error: '请求时间小于该事件结束时间',

	exceedTimeRange: '超过心电图诊断时间范围',
	click_frequently: '请不要频繁点击',
	unAnalyzed_Title: '不分析片段管理',
	unAnalyzed_subTitle: '挂号单号：{register}'
}

