import router from './router/router_zh_CN'
import login from './login/login_zh_CN'
import resetPassword from './resetPassword/resetPassword_zh_CN'
import navigator from './navigator/navigator_zh_CN'
import batch from './batch/batch_zh_CN'
import single from './single/single_zh_CN'
import record from './record/record_zh_CN'
import report from './report/report_zh_CN'
import common from './common/common_zh_CN'
import reviewer from './reviewer/reviewer_zh_CN'
import detail from './detail/detail_zh_CN'
import feature from './featureCompiler/feature_compiler_zh_CN'
import definition from './featureDefinition/feature_definition_zh_CN'
import hrv from './hrv/hrv_zh_CN'
import remix from './remix/remix_zh_CN'
import vector from './vector/vector_zh_CN'
import itemSelector from './selector/selector_zh_CN'
import alarmList from './alarmList/alarm_list_zh_CN'
import sample from './sampleCompiler/sample_compiler_zh_CN'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import events from './events/events_zh_CN'
import logs from './logs/logs_zh_CN'
import nibp from './nibp/nibp_zh_CN'
import nibpPdf from './nibpPdf/nibpPdf_zh_CN'
import spo from './spo/spo_zh_CN'
import analy from './analysis/analysis_zh_CN'
import st from './st/st_zh_CN'
import qt from './qt/qt_zh_CN'
import lorenz from './lorenz/lorenz_zh_CN'
import diag from './diag/diag_zh_CN'
import tmp from './template/template_zh_CN'

export default {
	lang: 'zh',
	guest: '游客',
	router,
	login,
	resetPassword,
	navigator,
	...elementZhLocale,
	batch,
	single,
	record,
	report,
	common,
	reviewer,
	detail,
	feature,
	definition,
	hrv,
	remix,
	vector,
	itemSelector,
	alarmList,
	sample,
	events,
	logs,
	nibp,
	nibpPdf,
	spo,
	analy,
	st,
	qt,
	lorenz,
	diag,
	tmp
}
