import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'

// import 'element-ui/lib/theme-chalk/index.css'
import '../theme/index.css'
import i18n from './i18n/index' //

import '@/utils/permission' // permission control
import '@/styles/index.scss' // global css

// import VConsole from 'vconsole'
// new VConsole()

Vue.config.productionTip = false
Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value)
})

import SlideVerify from 'vue-monoplasty-slide-verify'
Vue.use(SlideVerify)

// 桌面提示
import YsNotification from '@/plugins/YsNotification.js'
Vue.use(YsNotification)

// 仿Vue3 Teleport组件
import Teleport from '@/plugins/teleport/index.js'
Vue.use(Teleport)

// 国际化
Vue.prototype._i18n = i18n

// 自定义指令 Resize
const observable = new WeakMap()
Vue.directive('resize', { // 指令的名称
	bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
		const resizeObserver = new ResizeObserver(el => {
			clearTimeout(el.__vueSetTimeout__)
			el.__vueSetTimeout__ = setTimeout(() => {
				binding.value(el)
			}, 500)
		})
		observable.set(el, resizeObserver)
		resizeObserver.observe(el)
	},
	unbind(el) {
		const resizeObserver = observable.get(el)
		resizeObserver.unobserve(el)
	}
})

// alt+回车 切换全屏状态
document.addEventListener('keydown', event => {
	if (event.altKey && event.keyCode === 13) {
		const el = document.documentElement
		const isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
		if (!isFullScreen) { // 进入全屏,多重短路表达式
			(el.requestFullscreen && el.requestFullscreen()) ||
				(el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
				(el.webkitRequestFullscreen && el.webkitRequestFullscreen()) || (el.msRequestFullscreen && el.msRequestFullscreen())
		} else { // 退出全屏,三目运算符
			document.exitFullscreen ? document.exitFullscreen()
				: document.mozCancelFullScreen ? document.mozCancelFullScreen()
					: document.webkitExitFullscreen ? document.webkitExitFullscreen() : ''
		}
	}
})

function isMobile() {
	if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
		return true // 移动端
	} else {
		return false // PC端
	}
}

function isHorizontal() {
	return !!((window.orientation / 90) & 1)
}

const prevDirection = isHorizontal()
function resizeHandle() {
	const { innerWidth, innerHeight } = window
	if (innerWidth < 1280 && isHorizontal() && isMobile()) { // 如果是横屏并且是移动端， 则对屏幕尺寸进行调整， 否则则还原屏幕尺寸
		const scale = (innerHeight / 900).toFixed(2)
		const width = Math.round(innerWidth / scale)
		const height = Math.round(innerHeight / scale)
		document.body.style.transform = `scale(${scale})`
		document.body.style.position = 'fixed'

		const offsetWidth = innerWidth * 0.1
		const offsetHeight = innerHeight * 0.1
		document.body.style.left = `${(innerWidth - width + offsetWidth) / 2}px`
		document.body.style.top = `${(innerHeight - height + offsetHeight) / 2}px`
		document.body.style.width = `${width - offsetWidth}px`
		document.body.style.height = `${height - offsetHeight}px`
	} else {
		document.body.style.transform = ''
		document.body.style.position = 'relative'
		document.body.style.left = `0`
		document.body.style.top = `0`
		document.body.style.width = `100vw`
		document.body.style.height = `100vh`
	}
}

window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', function() {
	if (prevDirection !== isHorizontal()) location.reload()
}, false)
resizeHandle()

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')
