<template>
	<div class="main-alarm-panel">
		<span
			class="alarm-message"
			:title="alarmTitle"
			@click="openDialog"
		>
			{{ $t('alarmList.alarmListTitle') }}
			<div v-if="counter > 0">
				<span
					v-if="counter > 0 && counter <= 99"
					class="number"
				>{{
					counter
				}}</span>
				<span
					v-else
					class="number"
				>···</span>
			</div>
		</span>
		<slot />
		<div
			v-if="showDialog"
			v-resize="resize"
			class="alarm-dialogue"
		>
			<div class="dialog-title">
				<span>{{ $t('alarmList.alarmList') }}</span>
				<i
					class="el-icon-close"
					@click="close"
				/>
			</div>
			<div
				ref="listWarp"
				class="alarm-content"
				@scroll="scrollListener"
			>
				<div
					ref="scrollbar"
					class="scrollbar"
				/>
				<div
					ref="list"
					class="list"
				>
					<AlarmListItem
						v-for="(item, index) in showList"
						:key="index"
						@click.native="handleListItem(index)"
					>
						<template #time> {{ item.time | transferTime }} </template>
						<template #id> {{ item.orderNo || item.register }} </template>
						<template #content>
							{{
								$t('alarmList.alarm_item_content', {
									name: item.name || '',
									gender: transferGender(item.gender),
									msg: item.msg,
								})
							}}
						</template>
					</AlarmListItem>
				</div>
			</div>

			<div class="alarm-bottom">
				<div class="sort-btn">
					<div class="ys-btn-group">
						<button
							:title="$t(alarmList.descending)"
							:class="{ ascending: asc }"
							@click="handleAsc"
						>
							<i class="iconfont icon-SortAscending" />
						</button>
						<button
							:title="$t(alarmList.ascending)"
							:class="{ ascending: !asc }"
							@click="handleDesc"
						>
							<i class="iconfont icon-SortDescending" />
						</button>
					</div>
				</div>
				<div class="fn-btn-group">
					<span
						v-t="'alarmList.readAll'"
						class="clear-out btn"
						@click="readAll"
					/>
					<span
						v-t="'alarmList.checkMore'"
						class="view-detail btn"
						@click="toDetail"
					/>
				</div>
			</div>
		</div>

		<span class="alarm-settings">
			<i
				v-if="mute"
				:title="$t('alarmList.voiceSetting')"
				class="iconfont icon-volume-mute volume-control"
				@click="volumeSetting"
			/>
			<i
				v-else
				:title="$t('alarmList.voiceSetting')"
				class="iconfont icon-volume-high volume-control"
				@click="volumeSetting"
			/>
			<i
				:title="$t('alarmList.alarmSetting')"
				class="iconfont icon-shezhi setting-icon"
				@click="handleSetting"
			/>
		</span>

		<ShortcutVoiceSetting
			v-show="showVolume"
			ref="shortcut"
			class="volume-setting"
			@close-shortcut="closeShortcut"
		/>
		<!-- <SettingContent
			v-if="showSetting"
			@handle-dialog-cancel="handleDialogCancel"
		/> -->
		<FloodLight
			v-if="showFloodlight"
			class="flood-light"
			:color="color"
		/>
	</div>
</template>
<script>
import { getters, levelMap } from './alarmPanel/const'
import { mapGetters } from 'vuex'
import AlarmListItem from './alarmPanel/AlarmListItem.vue'
import ShortcutVoiceSetting from './alarmPanel/ShortcutVoiceSetting.vue'
import FloodLight from './alarmPanel/FloodLight.vue'
import { getECGRecords } from '@/api/ward'

export default {
	name: 'AlarmPanel',
	components: {
		AlarmListItem,
		ShortcutVoiceSetting,
		FloodLight
	},
	filters: {
		transferTime(value) {
			const date = new Date(Number(value))
			const hours =
				String(date.getHours()).length > 1
					? date.getHours()
					: '0' + date.getHours()
			const minutes =
				String(date.getMinutes()).length > 1
					? date.getMinutes()
					: '0' + date.getMinutes()
			const seconds =
				String(date.getSeconds()).length > 1
					? date.getSeconds()
					: '0' + date.getSeconds()

			const time = hours + ':' + minutes + ':' + seconds
			return time
		}
	},
	data() {
		return {
			counter: 0, // 警报消息右上角的数字 当0 不显示 当超过99的时候为...
			alarmList: [],
			showSetting: false,
			showDialog: false,
			asc: false,
			tag: false,
			showVolume: false,
			itemHeight: 29, // 每一列的高度
			showNum: 12, // 展示数据的条数
			start: 0, // 滚动过程中的开始索引
			end: 12, // 滚动过程中的结束索引
			voiceLoaded: true,
			showFloodlight: false,
			color: ''
		}
	},
	computed: {
		...mapGetters(getters),
		showList() {
			// 当数据不够时怎么办
			if (this.alarmList.length === 0) {
				return []
			}
			if (this.alarmList.length < this.showNum) {
				return this.alarmList
			}
			return this.alarmList.slice(this.start, this.end)
		},
		length() {
			return this.alarmList.length
		},
		alarmTitle() {
			return this.$t('alarmList.alarmListCount', { counter: this.counter })
		},
		lang() {
			return this._i18n.locale
		}
	},
	watch: {
		showDialog(newVal) {
			if (newVal) {
				this.$nextTick(() => {
					const alarm_content = this.$el.getElementsByClassName('alarm-content')[0]
					this.showNum = Math.ceil(alarm_content.offsetHeight / this.itemHeight)
					this.end = this.start + this.showNum
					if (this.$refs.scrollbar) {
						this.$refs.scrollbar.style.height =
							this.itemHeight * this.alarmList.length + 'px'
					}
				})
			}
		},
		length(newVal) {
			if (this.$refs.scrollbar) { this.$refs.scrollbar.style.height = this.itemHeight * newVal + 'px' }
		},
		volume(newVal) {
			// 音量
			this.synth.cancel()
			this.msg.volume = String(newVal / 100)
		},
		speed(newVal) {
			// 语速
			this.synth.cancel()
			this.msg.rate = newVal / 10
		},
		tone(newVal) {
			// 音高
			this.synth.cancel()
			this.msg.pitch = newVal / 50
		},
		mute(newVal) {
			if (newVal) this.synth.cancel()
		},
		isBroadcasting(newVal) {
			if (!newVal) this.synth.cancel()
		},
		'$store.getters.getEvents.length': {
			handler(newValue, oldValue) {
				if (newValue !== oldValue) {
					this.synth.cancel()
				}
			}
		},
		lang() {
			this.langReload()
		}
	},
	mounted() {
		this.$store.dispatch('AddMessageListener', this.addMessageList)
		window.addEventListener('mousedown', this.handleShortcut)

		this.end = this.showNum
		if (this.$refs.scrollbar) {
			this.$refs.scrollbar.style.height =
				this.itemHeight * this.alarmList.length + 'px'
		}
		this.initTts()
		this.audio = new Audio()
	},
	methods: {
		resize() {
			this.$nextTick(() => {
				if (this.$refs.scrollbar) {
					this.$refs.scrollbar.style.height =
							this.itemHeight * this.alarmList.length + 'px'
				}
				const alarm_content = this.$el.getElementsByClassName('alarm-content')[0]
				this.showNum = Math.ceil(alarm_content.offsetHeight / this.itemHeight)
				this.end = this.start + this.showNum
			})
		},
		langReload() {
			this.msg.lang = this._i18n.locale
		},
		transferGender(gender) {
			if (gender === undefined) {
				return ''
			}
			return gender === 1
				? this.$t('alarmList.male')
				: this.$t('alarmList.female')
		},
		scrollListener() {
			const scrollTop = this.$refs.listWarp.scrollTop

			this.start = Math.floor(scrollTop / this.itemHeight)
			this.end = this.start + this.showNum

			this.$refs.list.style.top = this.start * this.itemHeight + 'px'
		},
		initTts() {
			// 初始化tts数据
			this.synth = window.speechSynthesis
			this.msg = new SpeechSynthesisUtterance()
			this.msg.lang = this._i18n.locale
		},
		handleAsc() {
			// 时间降序排序
			this.asc = true
			this.alarmList.sort((a, b) => {
				return Number(b.time) - Number(a.time)
			})
		},
		handleDesc() {
			// 升序
			this.asc = false
			this.alarmList.sort((a, b) => {
				return Number(a.time) - Number(b.time)
			})
		},
		handleShortcut(event) {
			if (this.showVolume) {
				this.$nextTick(() => {
					const div = document.getElementsByClassName('volume-setting')[0]
					const voice = document.getElementsByClassName('volume-control')[0]
					if (!div.contains(event.target)) {
						this.showVolume = false
						if (voice.contains(event.target)) this.tag = true
					} else {
						this.tag = false
					}
				})
			}
		},
		closeShortcut() {
			this.showVolume = false
		},
		addZero(value) {
			return value.length === 1 ? '0' + value : value
		},
		addMessageList(res) {
			const msgInfo = JSON.parse(res.data)
			const { code, patientId, onlyId } = msgInfo

			if (!code || !patientId) return
			const pid = msgInfo.patientId
			const pInfo = this.$store.getters.getPatientInfos(pid)
			const newPatientInfo = {
				orderNo: pInfo.orderNo,
				msg: this.$t(`events.${msgInfo.code}`),
				time: msgInfo.time,
				pid: msgInfo.patientId,
				name: pInfo.name,
				gender: pInfo.gender,
				register: pInfo.register,
				onlyId
			}
			if (!this.mute && this.isBroadcasting) { this.textToSpeech(newPatientInfo, msgInfo.disease) } // 播报信息

			this.activateAlarm(msgInfo.disease)
			this.activateFloodlight(msgInfo.disease)
			if (this.alarmList.length >= 1000) {
				if (this.asc) this.alarmList.pop()
				else this.alarmList.shift()
				this.counter--
			}
			if (this.asc) this.alarmList.unshift(newPatientInfo)
			// 当是降序排序的时候 最新的消息总是在最上面
			else this.alarmList.push(newPatientInfo)
			this.counter++
		},
		close() {
			this.showDialog = false
		},
		openDialog() {
			this.showDialog = !this.showDialog
		},
		volumeSetting() {
			if (this.tag) {
				this.tag = false
				return
			}
			this.showVolume = true
		},
		handleSetting() {
			const component = () => import('@/plugins/alarmSetting/Index.vue')
			const dialog = {
				component,
				name: 'alarmList.alarmSetting',
				infos: {},
				position: {
					width: '850px',
					height: '480px',
					top: '20%',
					left: '20%'
				},
				resizable: false
			}

			this.$store.dispatch('createDialog', dialog)
		},
		handleDialogCancel() {
			this.showSetting = false
		},
		readAll() {
			// 已读全部会清空所有的列表数据
			this.counter = 0
			this.$refs.scrollbar.style.height = 0 + 'px'
			this.alarmList = []
		},
		modSecToDate(time) {
			const t = new Date(time)
			t.setHours(0, 0, 0, 0)
			return t.getTime()
		},
		handleListItem(index) {
			if (this.start > 0) index = index + this.start
			const param = this.alarmList.splice(index, 1)
			const { pid, name, register, time, onlyId } = param[0]

			const _time = Number(time)
			const component = () => import('@/plugins/logsViewer/Index.vue')
			// const title = ` ${name}: ${pid} :${DayJs(Number(time)).format(
			// 	'YYYY-MM-DD HH:mm:ss'
			// )}`
			const diagKey = 'logsView' + pid
			const params = {
				username: name,
				time: _time,
				endTime: _time + 1000,
				register,
				patientId: pid,
				onlyId,
				showTree: false,
				global: false

			}

			const requestTime = this.modSecToDate(_time)

			getECGRecords({ patientId: pid, beginDate: requestTime }).then((res) => {
				const data = res.data
				for (let i = 0; i < data.length; i++) {
					const item = data[i]
					const { createTime, updateTime } = item
					const _onlyId = item.onlyId
					if (onlyId === _onlyId) {
						params.createTime = createTime
						params.updateTime = updateTime
						const dialog = {
							component,
							name: 'navigator.logsViewLabel',
							title: {
								key: 'logs.subtitle',
								params: {
									name,
									pid
								}
							},
							infos: {
								diagKey,
								argument: {
									params
								},
								pid: pid + time
							},
							position: {
								width: '95%',
								height: '95%',
								top: '2.5%',
								left: '2.5%'
							}
						}
						this.$store.dispatch('createDialog', dialog)
						this.counter--
						break
					}
				}
			})
		},
		toDetail() {
			const component = () => import('@/plugins/logsViewer/Index.vue')
			const diagKey = 'logsView'
			const dialog = {
				component,
				name: 'navigator.logsViewLabel',
				infos: {
					diagKey,
					pid: 'batchward',
					argument: {
						params: {
							global: true, // 判断是否是全局
							showTree: true
						}
					}
				},
				position: {
					width: '95%',
					height: '95%',
					top: '2.5%',
					left: '2.5%'
				}
			}
			this.$store.dispatch('createDialog', dialog)
		},
		activateFloodlight(disease) {
			if (this.showFloodlight) return
			const option = this.$store.getters.getSpecificEvent(disease)
			if (option) {
				const level = option.lv
				if (level === '-') return
				const map = levelMap[level]
				const { floodlight, floodlightColor } =
					this.$store.getters.getLevelSettings(map)
				if (!floodlight) return
				this.color = floodlightColor
				this.showFloodlight = true
				setTimeout(() => {
					// 警报播放时长
					this.showFloodlight = false
				}, 3000)
			}
		},
		activateAlarm(disease) {
			/** 在这里做判断逻辑 */
			if (this.voiceLoaded) {
				const option = this.$store.getters.getSpecificEvent(disease)
				// if (this.audio) this.audio.pause()
				if (option) {
					const level = option.lv
					if (level === '5') return
					const map = levelMap[level]
					const { mute, alarmType, alarmVolume } =
						this.$store.getters.getLevelSettings(map)
					if (mute) return
					this.audio.volume = alarmVolume / 100
					this.audio.src = require(`./alarmPanel/voiceSource/${alarmType}.wav`)

					const playPromise = this.audio.play()
					this.voiceLoaded = false
					if (playPromise !== undefined) {
						playPromise.then((_) => {
							this.voiceLoaded = true
						})
					}
				}
			}
			if (!this.voiceLoaded) return
		},
		textToSpeech(patientInfo, disease) {
			/**
			 *  text – 要合成的文字内容，字符串
			 * lang – 使用的语言，字符串， 例如：“zh-cn”
			 * voiceURI – 指定希望使用的声音和服务，字符串
			 * volume – 声音的音量，区间范围是0到1，默认是1
			 * rate – 语速，数值，默认值是1，范围是0.1到10，表示语速的倍数，例如2表示正常语速的两倍。
			 * pitch – 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1
			 */
			const option = this.$store.getters.getSpecificEvent(disease)
			const { voice } = option
			if (!voice) return
			const text =
				this.$options.filters['transferTime'](patientInfo.time) +
				patientInfo.name +
				this.transferGender(patientInfo.gender) +
				this.$t('alarmList.happen') +
				this.$t(`events.${disease}`) +
				patientInfo.msg
			this.msg.text = text
			this.synth.speak(this.msg)
		}
	}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep .hover {
	background-color: rgba(52, 52, 52, 0);
}

::v-deep .el-slider__button {
	border-radius: 2px !important;
	background-color: #343434;
	width: 8px;
	border: 1px solid rgba(153, 153, 153, 0.73333);
}

.volume-control {
	cursor: pointer;
}
.setting-icon {
	cursor: pointer;
}
.main-alarm-panel {
	align-items: center;
	border-top: 1px solid $border-1;
	padding-right: 40px;
	overflow: visible !important;
	z-index: 99999;

	.alarm-settings {
		position: absolute;
		right: 5px;
		i {
			margin-left: 8px;
			cursor: pointer;
		}
	}
	.alarm-dialogue {
		position: absolute;
		width: 350px;
		height: 50vh;
		left: 5px;
		bottom: 35px;

		background-color: #2229;
		border-radius: 3px;
		border: 1px solid rgba(153, 153, 153, 0.73333);
		overflow: hidden;

		z-index: 10;

		.dialog-title {
			height: 25px;
			border-bottom: 1px solid rgba(153, 153, 153, 0.73333);
			padding: 0 65px 0 5px;
			line-height: 25px;
			font-size: 12px;
			background-color: #15273a;
			.el-icon-close {
				position: absolute;
				right: 5px;
				top: 4px;
				background: #f56c6c;
				font-size: 16px;
				cursor: pointer;
			}
		}
		.alarm-content {
			overflow: auto;
			border: 1px solid $border-1;
			width: 340px;
			margin-top: 15px;
			margin: 0 auto;
			height: calc(50vh - 70px);
			position: relative;
			.list {
				position: absolute;
				left: 0;
				top: 0;
				width: 330px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.alarm-bottom {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-content: center;

			width: 340px;
			height: 35px;

			margin: 3px auto;

			text-align: center;
			border: 1px solid $border-1;
			box-sizing: border-box;

			div,
			span {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				width: auto;
				height: 29px;

				box-sizing: border-box;
			}
			.fn-btn-group,
			.sort-btn {
				margin: 3px;
				.btn {
					padding: 5px 8px;
					background-color: #213958;
					border: 1px solid #fff4;
					border-radius: 3px;
					font-size: 13px;
					cursor: pointer;

					&:hover {
						background-color: #376292;
					}
				}
				.btn + .btn {
					margin-left: 4px;
				}
			}
		}
	}
	.ascending {
		background-color: #297dc2;

		&:hover {
			background-color: rgba(78, 144, 202, 0.5);
		}
	}

	.alarm-message {
		flex-grow: 0;
		position: relative;

		width:auto;
		padding:3px 12px;
		height: 20px;
		margin-left: 3px;

		text-align: center;

		font-size: 10px;
		line-height: 20px;

		background-color: rgb(22, 32, 42);
		border-radius: 3px;

		white-space: nowrap;
		word-break: keep-all;
		cursor: pointer;

		.number {
			position: absolute;
			right:-3px;
			top: -3px;
			background-color: red;
			color: #fff;
			height: 16px;
			padding-right: 1px;
			min-width: 16px;
			line-height: 15px;
			border-radius: 16px;
			text-align: center;
			font-size: 12px;
			transform: scale(0.9);
		}
	}
}
.flood-light {
	position: absolute;
	left: 0;
	top: -96.5vh;
}
</style>
