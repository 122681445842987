export default { // 血压相关
	nibpReport: 'NIBP Report',
	subtitle: '| Name: {name} - Gender: {gender} - Age: {age} | PID: {pid}',

	name: 'Name',
	gender: 'Gender',
	age: 'Age',
	department: 'Organization Department',

	time: 'Time',
	weekAgo: 'A week ago',
	today: 'today',
	yesterday: 'yesterday',

	setting: 'setting',
	createReport: 'Create Report',
	viewReport: 'View Report',
	apply: 'Apply',

	trend: 'Trend Chart',
	rhythm: 'Circadian Rhythm Line Chart',
	pie: 'Pie chart',
	bar: 'Column Chart',
	plot: 'Fitted graph',

	hour: 'Hour',
	pulse: 'Pulse',
	normal: 'Normal',
	abnormal: 'AbNormal',

	sbp: 'SBP',
	mbp: 'MBP',

	conclusion: 'conclusion',
	saveConclusion: 'save',

	allDay: 'Overall',
	day: 'Day',
	night: 'Night',
	morning: 'Morning',

	percent: 'percent',
	rely: 'Dependencies',
	connectIndex: 'Correlation coefficient',
	distribute: 'Distribution Center',
	hr: 'HR',

	summaryPeriod: 'ABPM summary | period',
	TimeRange: 'time range',
	sbpUpperLimit: 'SBP/DBP maximum limit value',
	sbpWA: 'SBP/DBP weighted average',
	pulseWA: 'Pulse weighted average',
	sbpMbpDayNight: 'SBP/DBP D/N index % ',
	successRate: 'success ratio %',
	morningSurge: 'morning surge',

	gross: 'Overall',
	tableMor: 'Morning',
	tableDay: 'Day',
	tableNight: 'Night',

	conclusionPlaceholder: 'Click to enter a conclusion',

	morningRange: 'Morning Time Range',
	nightRange: 'Night Time Range',
	sbpNormalRange: 'SBP Normal Range',
	dbpNormalRange: 'DBP Normal Range',
	hrNormalRange: 'HR Normal Range',

	setHours: '24 Hours',
	setDay: 'Day',
	setNight: 'Night',

	morningStartTime: 'Morning Start Time',
	morningEndTime: 'Morning End Time',
	nightStartTime: 'Night Start Time',
	nightEndTime: 'Night End Time',

	sure: 'sure',
	cancel: 'cancel',

	conclusion_normal: 'Blood pressure monitoring time is {begin} - {end}, the total duration of the test is {timeLong} hours, the average blood pressure was {avgAllSbp}/{avgAllDbp}.',
	conclusion_day: 'Daytime testing hours are {dayTimeSlot}, The duration of the test is {dayLong} hours, the average daytime blood pressure was {wmsaDaySbp}/{wmsaDayDbp}.',
	conclusion_night: 'Night time hours are {nightTimeSlot}, duration {nightLong} hours, he average nighttime blood pressure was {wmsaNightSbp}/{wmsaNightDbp}.'
}
